import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { HydratedForm, MATERIAL_FORM_TABS } from '../types'
import { RootState } from '../../stores'

export type CachedForm = Pick<HydratedForm, 'schema' | 'uiSchema'>

export const GENERAL_CACHE = 'general' // general value to store value if building id is not provided
const MAX_CACHE_BUILDING_AMOUNT = 4 // should be 2 or more ,since we keep general as well

export type FormsState = {
    activeForm: string | null
    formCache: Record<string, Record<string, CachedForm>> // { buildingId: { activeForm: CachedForm } }
    errors: string[]
    activeFormTab: MATERIAL_FORM_TABS
}

export const initialState: FormsState = {
    activeForm: null,
    formCache: {},
    errors: [],
    activeFormTab: MATERIAL_FORM_TABS.GENERAL,
}

export const formsSlice = createSlice({
    name: 'forms',
    initialState,
    reducers: {
        activateForm: (state: FormsState, { payload }: PayloadAction<string>): void => {
            state.activeForm = payload
        },
        cacheForm: (
            state: FormsState,
            {
                payload: { type, form, buildingId },
            }: PayloadAction<{ form: CachedForm; type: string; buildingId: string }>
        ): void => {
            const activeBuildingId = buildingId ?? GENERAL_CACHE
            let newFormCache = state.formCache
            // if there are more than MAX_CACHE_BUILDING_AMOUNT records that means we need to clear cache and leave only general

            if (
                Object.keys(state.formCache).length >= MAX_CACHE_BUILDING_AMOUNT &&
                !state.formCache[activeBuildingId]
            ) {
                newFormCache = {
                    [GENERAL_CACHE]: state.formCache[GENERAL_CACHE] ?? {},
                }
            }
            state.formCache = {
                ...newFormCache,
                [activeBuildingId]: {
                    ...(newFormCache[activeBuildingId] || {}),
                    [type]: { schema: form.schema, uiSchema: form.uiSchema },
                },
            }
        },
        formError: (state: FormsState, { payload }: PayloadAction<string>): void => {
            state.errors = [...state.errors, payload]
        },
        clearFormErrors: (state: FormsState): void => {
            state.errors = []
        },
        clearActiveForm: (state: FormsState): void => {
            state.activeForm = null
        },
        setActiveFormTab: (
            state: FormsState,
            { payload: { activeFormTab } }: PayloadAction<{ activeFormTab: MATERIAL_FORM_TABS }>
        ): void => {
            state.activeFormTab = activeFormTab
        },
    },
})

export const { cacheForm, activateForm, formError, clearFormErrors, clearActiveForm, setActiveFormTab } =
    formsSlice.actions

export const selectFormCache = createSelector(
    (state: RootState) => state.IMUP.forms.formCache,
    (cache) => cache
)

const selectActiveFormFactory = (useGeneralCache?: boolean) =>
    createSelector(
        (state: RootState) => {
            return {
                formCache: state.IMUP.forms.formCache,
                activeForm: state.IMUP.forms.activeForm,
                activeBuildingId: useGeneralCache
                    ? GENERAL_CACHE
                    : state.drawable?.activeFloor?.building_id ?? GENERAL_CACHE,
                activeFormTab: state.IMUP.forms.activeFormTab,
            }
        },
        (
            formCacheAndActiveForm
        ): {
            form: CachedForm | null
            activeForm: string | null
            activeBuildingId: string
            activeFormTab: MATERIAL_FORM_TABS
        } => ({
            form:
                formCacheAndActiveForm.formCache[formCacheAndActiveForm.activeBuildingId]?.[
                    formCacheAndActiveForm.activeForm ?? ''
                ] ?? null,
            activeForm: formCacheAndActiveForm.activeForm,
            activeBuildingId: formCacheAndActiveForm.activeBuildingId,
            activeFormTab: formCacheAndActiveForm.activeFormTab,
        })
    )

export const selectActiveForm = selectActiveFormFactory(false)
export const selectActiveFormUsingGeneralCache = selectActiveFormFactory(true)

export default formsSlice
