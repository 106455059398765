import { createAction } from '@reduxjs/toolkit'
import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects'

import { selectDrawableLocations } from '../effects/deleteTemporaryDrawableGroups'
import { updateOpeningLocation } from '../../../api/takeoff-api'
import IndexableObject from '../../../shared/constants/general-enums/indexableObject'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Workspace } from '../../lib/toolBoxes/2D'
import { setDrawableLocations } from '../../slices/2D'
import { updateToolbarMessage } from '../../slices/common'
import { updateGeometricLocationAdditionalData } from '../../slices/geometry'
import { selectProjectId } from '../../slices/project'
import { IMUP2DDrawableLocation, SAVING_CHANGES_ERROR_MESSAGE, SAVING_MESSAGE } from '../../types'

export const updateDoubleJoistAction = createAction<{ id: number; additionalData: IndexableObject }>(
    'UPDATE_DOUBLE_JOIST'
)

export function* toggleDoubleJoist(
    action: ReturnType<typeof updateDoubleJoistAction>
): Generator<
    StrictEffect | IMUP2DDrawableLocation[],
    void,
    (PaperManager | null) & Workspace & (number | null) & (paper.Item | null) & IMUP2DDrawableLocation[]
> {
    try {
        const paperManager: PaperManager | null = yield call(managers.get2DManager)

        if (!paperManager) return

        yield put(updateToolbarMessage(SAVING_MESSAGE))

        const workspaceTool: Workspace = yield call(paperManager.getTool, Workspace.NAME)

        const projectId: number | null = yield select(selectProjectId)

        if (!projectId) return

        const newLocationAdditionalData = {
            ...action.payload.additionalData,
            is_double_joist: !action.payload.additionalData.is_double_joist,
        }

        const joistLabelPaperItem: paper.Item | null = yield call(
            workspaceTool.getItemWithOpeningLocationId,
            action.payload.id
        )

        if (!joistLabelPaperItem) return

        joistLabelPaperItem.data.additionalData = newLocationAdditionalData

        const drawableLocations: IMUP2DDrawableLocation[] = yield select(selectDrawableLocations)

        const newDrawableLocations: IMUP2DDrawableLocation[] = yield drawableLocations.map((loc) => {
            if (loc.opening_location_id === action.payload.id) {
                return { ...loc, additionalData: newLocationAdditionalData }
            }

            return loc
        })

        yield put(setDrawableLocations(newDrawableLocations))

        yield put(
            updateGeometricLocationAdditionalData({ additionalData: newLocationAdditionalData, id: action.payload.id })
        )

        yield call(updateJoistLabelGroupStyle, joistLabelPaperItem, newLocationAdditionalData, workspaceTool)

        try {
            yield call(updateOpeningLocation, projectId, action.payload.id, newLocationAdditionalData)
            yield put(updateToolbarMessage(null))
        } catch (e) {
            yield put(updateToolbarMessage(SAVING_CHANGES_ERROR_MESSAGE))
            yield put(setDrawableLocations(drawableLocations))

            yield put(
                updateGeometricLocationAdditionalData({
                    additionalData: action.payload.additionalData,
                    id: action.payload.id,
                })
            )

            joistLabelPaperItem[0].data.additionalData = action.payload.additionalData
            yield call(updateJoistLabelGroupStyle, joistLabelPaperItem, action.payload.additionalData, workspaceTool)
            yield put(updateToolbarMessage(null))
        }
    } catch (e) {
        console.error(e)
        yield put(updateToolbarMessage(null))
    }
}

export function updateJoistLabelGroupStyle(
    groupPaperItem: paper.Item,
    newAdditionalData: IndexableObject,
    workspaceTool: Workspace
): void {
    const labelRect = groupPaperItem.children[1]
    const text = groupPaperItem.children[2]

    if (newAdditionalData.is_double_joist) {
        labelRect.fillColor = workspaceTool.doubleJoistLabelBGColor
        text.fillColor = workspaceTool.doubleJoistLabelTextColor
    } else {
        labelRect.fillColor = workspaceTool.defaultLabelBGColor
        text.fillColor = workspaceTool.singleJoistLabelTextColor
    }
}

export function* watchForDoubleJoistToggle() {
    yield takeEvery(updateDoubleJoistAction.type, toggleDoubleJoist)
}
