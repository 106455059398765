import { createAction } from '@reduxjs/toolkit'
import { call, ForkEffect, put, select, takeEvery } from 'redux-saga/effects'

import { fetchProjectFlagsOptions, publishAdminOptions } from '../../../api/projects-api'
import { categoryNamePlaceholder, TaskOptions } from '../../../components/admin/constants'
import {
    AdminOptionToCreate,
    AdminOptionToDelete,
    AdminOptionToUpdate,
    PreparedAdminOptions,
    PreparedCategoryAdminOption,
    PreparedSubCategoryAdminOption,
    PreparedSubCategoryChildrenOption,
    PublishAdminOptions,
} from '../../../models/adminOptions'
import { selectActiveTab, selectOptions, setOptions } from '../../slices/admin'

export const publishAdminOptionsAction = createAction('publishAdminsOption')

export const prepareCategoryOption = (option: PreparedCategoryAdminOption): AdminOptionToUpdate => {
    const mtpReferences = option?.mtp_references || []

    const preparedOption: AdminOptionToUpdate = {
        id: option.id ?? 0,
        name: option.name,
    }

    const newMtpReferences = mtpReferences
        .filter((mtp) => !mtp.isSoftDeleted)
        .map((mtp) => {
            const mptRef = { ...mtp }

            if (mptRef?.isNew) delete mptRef.isNew

            return {
                ...mptRef,
                category_id: preparedOption.id,
            }
        })

    if (newMtpReferences.length > 0) {
        preparedOption.mtp_references = newMtpReferences
    }

    return preparedOption
}

export const prepareSubCategoryOption = (option: PreparedSubCategoryChildrenOption): AdminOptionToUpdate => {
    const mtpReferences = option?.mtp_references || []

    const preparedOption: AdminOptionToUpdate = {
        id: option.id ?? 0,
        name: option.name,
        category_id: option.category_id,
    }

    const newMtpReferences = mtpReferences
        .filter((mtp) => !mtp.isSoftDeleted)
        .map((mtp) => {
            const mptRef = { ...mtp }

            if (mptRef?.isNew) delete mptRef.isNew

            return {
                ...mptRef,
                subcategory_id: preparedOption.id,
            }
        })

    if (newMtpReferences.length > 0) {
        preparedOption.mtp_references = newMtpReferences
    }

    return preparedOption
}

export function* handlePublishAdminOptions() {
    try {
        const activeTab: TaskOptions = yield select(selectActiveTab)
        const options: PreparedAdminOptions = yield select(selectOptions)

        const optionsByTab = options[activeTab]
        const optionsToCreate: AdminOptionToCreate[] = []
        const optionsToDelete: AdminOptionToDelete[] = []
        const optionsToUpdate: AdminOptionToUpdate[] = []

        // if we are pushing subcategories, and there are some new categories
        // updated them before subcategories
        if (activeTab === TaskOptions.SUBCATEGORIES) {
            const tempOptionsByTab: PreparedSubCategoryAdminOption[] = optionsByTab as PreparedSubCategoryAdminOption[]

            tempOptionsByTab
                .filter((option) => option.name !== categoryNamePlaceholder)
                .forEach((option) => {
                    option.children.forEach((child, index) => {
                        const isSoftDeleted = child?.isSoftDeleted
                        const isNew = child?.isNew

                        if (isNew) {
                            const optionToAdd: AdminOptionToCreate = prepareSubCategoryOption(child)

                            optionToAdd.order = index + 1
                            optionsToCreate.push(optionToAdd)
                        } else if (isSoftDeleted) {
                            optionsToDelete.push({ id: child.id! })
                        } else {
                            const optionToUpdate: AdminOptionToUpdate = prepareSubCategoryOption(child)

                            optionToUpdate.order = index + 1
                            optionsToUpdate.push(optionToUpdate)
                        }
                    })
                })
        } else if (activeTab === TaskOptions.CATEGORIES) {
            const tempOptionsByTab: PreparedCategoryAdminOption[] = optionsByTab as PreparedCategoryAdminOption[]

            tempOptionsByTab.forEach((option, index) => {
                const isSoftDeleted = option?.isSoftDeleted
                const isNew = option?.isNew

                if (isNew) {
                    const optionToAdd: AdminOptionToCreate = prepareCategoryOption(option)

                    optionToAdd.order = index + 1
                    optionsToCreate.push(optionToAdd)
                } else if (isSoftDeleted) {
                    optionsToDelete.push({ id: option.id! })
                } else {
                    const optionToUpdate: AdminOptionToUpdate = prepareCategoryOption(option)

                    optionToUpdate.order = index + 1
                    optionsToUpdate.push(optionToUpdate)
                }
            })
        } else {
            optionsByTab.forEach((option, index) => {
                if (option?.isNew) {
                    optionsToCreate.push({ name: option.name, order: index + 1 })

                    return
                }

                if (option?.isSoftDeleted) {
                    optionsToDelete.push({ id: option.id! })

                    return
                }

                optionsToUpdate.push({ id: option.id!, name: option.name, order: index + 1 })
            })
        }

        yield call(publishAdminOptions, {
            key: activeTab,
            toCreate: optionsToCreate,
            toDelete: optionsToDelete,
            toUpdate: optionsToUpdate,
        } as PublishAdminOptions)

        const updatedOptions = yield call(fetchProjectFlagsOptions)

        yield put(setOptions({ options: updatedOptions }))
    } catch (error) {
        yield call(console.error, error)
    }
}

export function* watchForPublishAdminOptions(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(publishAdminOptionsAction.type, handlePublishAdminOptions)
}
