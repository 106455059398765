import { createAction } from '@reduxjs/toolkit'
import { call, delay, put, select, throttle } from 'redux-saga/effects'

import { createHighlightFromLocation } from './markupDocument'
import { updateOpeningLocationCoordinates } from '../../../api/projects-api'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Select, Workspace } from '../../lib/toolBoxes/2D'
import ColorTool from '../../lib/toolBoxes/2D/tools/color/Color.tool'
import HighlightTool from '../../lib/toolBoxes/2D/tools/highlight/Highlight.tool'
import { drawableLocationSelector, updateDrawableLocationCoordinates } from '../../slices/2D'
import { updateToolbarMessage } from '../../slices/common'
import { selectProjectId } from '../../slices/project'
import {
    IMUP2DCoordinatesToUpdate,
    IMUP2DDrawableLocation,
    SAGA_THROTTLE_TIMER,
    SAVING_CHANGES_ERROR_MESSAGE,
    SAVING_MESSAGE,
    TOOLBAR_MESSAGE_TIMER,
} from '../../types'
export type SaveEditToHighlightInput = {
    highlightDrawableId: number
    highlightLocationId: number
    coordsMeasurementsAndCutouts: IMUP2DCoordinatesToUpdate
}

export const saveEditsToHighlight = createAction<SaveEditToHighlightInput>('saveEditsToHighlight')

export function* saveHighlightsChanges({ payload }: ReturnType<typeof saveEditsToHighlight>) {
    yield put(updateToolbarMessage(SAVING_MESSAGE))

    const projectId = yield select(selectProjectId)

    try {
        yield call(
            updateOpeningLocationCoordinates,
            projectId,
            payload.highlightDrawableId,
            payload.highlightLocationId,
            payload.coordsMeasurementsAndCutouts,
            null
        )

        yield put(
            updateDrawableLocationCoordinates({
                opening_location_id: payload.highlightLocationId,
                coordinates: payload.coordsMeasurementsAndCutouts.coordinates,
                quantity: null,
                cutouts: payload.coordsMeasurementsAndCutouts.cutouts,
                region_id: null,
            })
        )

        yield put(updateToolbarMessage(null))
    } catch (e) {
        yield put(updateToolbarMessage(SAVING_CHANGES_ERROR_MESSAGE))

        const manager: PaperManager = yield call(managers.get2DManager)

        const [highlightTool, colorTool, workspaceTool, selectTool]: [HighlightTool, ColorTool, Workspace, Select] =
            yield call(manager.getTools, [HighlightTool.NAME, ColorTool.NAME, Workspace.NAME, Select.NAME])

        const drawableLocations: IMUP2DDrawableLocation[] = yield select(drawableLocationSelector)

        const location: IMUP2DDrawableLocation | undefined = drawableLocations.find(
            (l) => l.drawable_id === payload.highlightDrawableId
        )

        // The highlight doesn't seem to have a location bail
        if (!location) return

        const item: paper.Item | null = yield call(workspaceTool.getItemWithDrawableId, payload.highlightDrawableId)

        // Cant find the item the user is editing, bail
        if (!item) return

        yield call(item.remove.bind(item))

        yield call(selectTool.removeHandles)

        yield call(createHighlightFromLocation, highlightTool, colorTool, location)

        yield delay(TOOLBAR_MESSAGE_TIMER)
        yield put(updateToolbarMessage(null))
    }
}

export function* watchForHighlightEditSave() {
    yield throttle(SAGA_THROTTLE_TIMER, saveEditsToHighlight.type, saveHighlightsChanges)
}
