// Capitalizing the first letter
export const capitalize = (string: string | undefined): string => {
    if (!string) return ''

    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const prepareDrawableGroupTempType = (tempGroupType: string): string => {
    return tempGroupType?.replaceAll('_n_', ' / ')?.replaceAll('_', ' ')
}

// Words that should always be uppercase
const uppercaseWords = ['lsl', 'lvl', 'gl', 'psl', 'lf', 'l/f']

// Capitalizing the first letter of each word
export const capitalizeEachWord = (value: string | undefined): string => {
    if (typeof value === 'string' && value) {
        return value
            .trim()
            .toLowerCase()
            .split(' ')
            .map(function (word) {
                if (uppercaseWords.includes(word.toLowerCase())) {
                    return word.toUpperCase()
                }

                return word && word.length > 0 ? word[0].toUpperCase() + word.substr(1) : ''
            })
            .join(' ')
    }

    return value ?? ''
}

export const highlightsMatchedText = (text, matchText) => {
    return text.replace(new RegExp(matchText, 'gi'), (match) => `<b>${match}</b>`)
}

export const truncate = (text: string, maxLength: number): string => {
    const dots = '...'

    return text.length > maxLength + dots.length ? text.substring(0, maxLength) + dots : text
}

export const convertStringToBool = (value?: string | boolean | null | undefined): boolean => {
    if (typeof value === 'string') {
        return value === 'true'
    }

    return value ?? false
}

// used only for adding data-qa attribute
export const prepareDataQA = (value: string | undefined): string | undefined => {
    if (!value) return undefined

    return value.trim().toLowerCase().replaceAll('_', '-').replaceAll(' ', '-')
}
