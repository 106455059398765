import axios from 'axios'

import { getToken, msalInstance } from '../authConfig'

const hostApi = import.meta.env.VITE_API_URL

let timer: ReturnType<typeof setTimeout> | undefined
const DELAYED_LOGOUT_TIMEOUT = 1000

export const ConfigureInterceptors = () => {
    axios.interceptors.request.use(
        async (config) => {
            // Ensure config is defined
            if (!config) {
                throw new Error('Request configuration is undefined')
            }

            const token = await getToken()

            if (token) {
                // Ensure headers object exists
                config.headers = config.headers || {}
                config.headers.Authorization = `Bearer ${token}`

                // Clear the logout timer if a token is present
                if (timer) {
                    clearTimeout(timer)
                }
            }

            // Update the request URL
            config.url = `${hostApi}/${config.url}`

            return config
        },
        (error) => {
            return Promise.reject(error)
        }
    )

    axios.interceptors.response.use(
        (response) => {
            return response.data
        },
        (error) => {
            if (error.response && error.response.status === 401) {
                if (!timer) {
                    timer = setTimeout(() => {
                        msalInstance.logoutRedirect()
                    }, DELAYED_LOGOUT_TIMEOUT)
                }

                // Introduce a delay of 2000ms before rejecting the promise
                return new Promise((_, reject) => {
                    setTimeout(() => {
                        reject(error)
                    }, 2000)
                })
            }

            return Promise.reject(error)
        }
    )
}
