import { Mesh } from '@babylonjs/core/Meshes/mesh'
import { AdvancedDynamicTexture } from '@babylonjs/gui/2D/advancedDynamicTexture'
import { Control } from '@babylonjs/gui/2D/controls/control'
import { Rectangle } from '@babylonjs/gui/2D/controls/rectangle'
import { TextBlock } from '@babylonjs/gui/2D/controls/textBlock'

import BabylonTool from '../babylonTool/BabylonTool'
import { getJoistBackgroundColor, getJoistTextColor } from '../../../../../../shared/services/label-color/label-color'
import { BabylonToolConfig } from '../../../../../types'

export class Label extends BabylonTool {
    static NAME = 'LABEL'
    private static readonly LABEL_FONT_SIZE = '10px'
    private static readonly LABEL_WIDTH_HEIGHT = '15px'
    private static readonly LABEL_ADVANCED_TEXTURE_NAME = 'Label-UI'

    private guiAdvancedTexture: AdvancedDynamicTexture

    constructor(config: BabylonToolConfig) {
        super(config)
        this.name = Label.NAME
        this.registerActivationFunctions([])
        this.guiAdvancedTexture = AdvancedDynamicTexture.CreateFullscreenUI(Label.LABEL_ADVANCED_TEXTURE_NAME)
    }

    private onLabelClick = (id: Mesh['id']) => {
        this.mediator.mediate('3D', { selectedMeshLabelID: id })
    }

    /**
     * Create a billboard styled text box and attach it to the provided mesh
     * @param labelText the text to put into the text box
     * @param meshToConnectTo the mesh to attach it to
     */
    public create = (labelText: string, meshToConnectTo: Mesh): void => {
        const isDoubleJoist = meshToConnectTo.metadata.additionalData.is_double_joist

        const labelContainer = new Rectangle()

        labelContainer.height = Label.LABEL_WIDTH_HEIGHT
        labelContainer.width = Label.LABEL_WIDTH_HEIGHT
        labelContainer.background = getJoistBackgroundColor(isDoubleJoist)
        this.guiAdvancedTexture.addControl(labelContainer)
        labelContainer.linkWithMesh(meshToConnectTo)

        const labelTxt = new TextBlock()

        labelTxt.text = labelText
        labelTxt.fontSize = Label.LABEL_FONT_SIZE
        labelTxt.color = getJoistTextColor(isDoubleJoist)
        labelTxt.isPointerBlocker = true

        labelContainer.addControl(labelTxt)
        labelContainer.onPointerClickObservable.add(() => this.onLabelClick(meshToConnectTo.id))
    }

    /**
     * Clear all labels that are drawn in the scene
     */
    public clearLabels = () => {
        this.guiAdvancedTexture.dispose()
        this.guiAdvancedTexture = AdvancedDynamicTexture.CreateFullscreenUI(Label.LABEL_ADVANCED_TEXTURE_NAME)
    }

    public findLabelContainerByMesh = (mesh: Mesh): Control | null => {
        const containers = this.guiAdvancedTexture.getChildren()

        return containers.reduce((foundControl: Control | null, container) => {
            if (foundControl) {
                return foundControl
            }

            const control = container.children.find((child) => child.linkedMesh === mesh)

            return control || foundControl
        }, null)
    }
}
