import { Node } from '@babylonjs/core/node'
import isNull from 'lodash/isNull'
import { call, takeLatest } from 'redux-saga/effects'

import managers from '../../lib/managers'
import { BabylonManager } from '../../lib/managers/BabylonManager'
import { Workspace } from '../../lib/toolBoxes/3D'
import { selectDesignOption } from '../../slices/3D'

export function* handleDesignOptionChange(action: ReturnType<typeof selectDesignOption>) {
    const manager: BabylonManager | null = yield call(managers.get3DManager)

    if (isNull(manager)) return

    const workspace: Workspace = yield call(manager.getTool, Workspace.NAME)

    yield call(workspace.toggleVisibilityBasedOnPredicate, (node: Node) => {
        return action.payload === undefined || node.metadata.configuration_id === action.payload
    })
}

export function* watchFroDesignOptionChange() {
    yield takeLatest(selectDesignOption.type, handleDesignOptionChange)
}
