import defaultTo from 'lodash/defaultTo'
import isNull from 'lodash/isNull'
import { call, CallEffect, select, SelectEffect } from 'redux-saga/effects'

import createPathItemFromType, { addLabelJoistAndBeamsToPath, getScaleFactor } from './createPathItemFromType'
import addMetadataToPath from './data-prep/addMetadataToPath'
import { ActiveFloor } from '../../../models/activeFloor'
import { drawableBackgroundAndBorderColor } from '../../../shared/services/drawable-color-services'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Color, Workspace } from '../../lib/toolBoxes/2D'
import addSelectFunctionalityToDrawable from '../../lib/utils/functionality-bindings/addSelectFunctionalityToDrawable'
import { selectDrawableActiveFloor } from '../../slices/documents'
import { IMUP2DDrawableLocation, ITool } from '../../types'

type DrawShapeByTypeYield =
    | CallEffect
    | CallEffect<string | paper.Color | (paper.Path | null) | ITool[] | ActiveFloor>
    | SelectEffect

type DrawShapeByTypeNext = string & paper.Color & paper.Path & managerTools & PaperManager & (ActiveFloor | null)

type managerTools = [Color, Workspace]

export default function* drawShapeByType(
    location: IMUP2DDrawableLocation,
    areaOpacity: number,
    lineOpacity: number,
    regionPaths: paper.Path[]
): Generator<DrawShapeByTypeYield, paper.Path | null, DrawShapeByTypeNext> {
    // get the 2D drawing manager
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (isNull(manager)) return null

    // Get the active floor from the store
    const activeFloor: ActiveFloor = yield select(selectDrawableActiveFloor)

    const { drawing_type, settings, additionalData, ai_suggestion_id } = location

    const [colorTool, workspaceTool]: managerTools = yield call(manager.getTools, [Color.NAME, Workspace.NAME])

    // determine color by location settings and drawing_type
    const colorValueFromConstants: string = yield call(
        drawableBackgroundAndBorderColor,
        defaultTo(drawing_type, ''),
        settings.name,
        settings.selection,
        settings.location,
        additionalData,
        settings.material,
        ai_suggestion_id
    )

    // create color with color tool
    const shapeColor: paper.Color = yield call(colorTool.createColor, colorValueFromConstants)

    const path: paper.Path = yield call(
        createPathItemFromType,
        location,
        location.coordinates,
        areaOpacity,
        lineOpacity,
        shapeColor,
        regionPaths
    )

    const scaleFactor = getScaleFactor(location.coordinates, workspaceTool, activeFloor, regionPaths)

    // add metadata to paths
    yield call(addMetadataToPath, path, scaleFactor, location)
    yield call(addSelectFunctionalityToDrawable, path)

    yield call(addLabelJoistAndBeamsToPath, path, location)

    return path
}
