import '@babylonjs/core/Collisions/collisionCoordinator'
import '@babylonjs/core/Culling/ray'

import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial'
import { Color3 } from '@babylonjs/core/Maths/math.color'
import { Mesh } from '@babylonjs/core/Meshes/mesh'
import { Scene } from '@babylonjs/core/scene'

import { AbstractMaterialGenerator } from './AbstractMaterialGenerator'
import { IMUP_DRAWABLE_COLORS } from '../../../../../types'

export default class DrawableColorsMaterialGenerator extends AbstractMaterialGenerator {
    private static UNSELECTABLE_HEX_COLOR = '#E6E6E6'
    private static LANDSCAPING_HEX_COLOR = '#296F24'
    private static NON_DRAWABLE_MATERIAL_COLORS: string[] = [
        DrawableColorsMaterialGenerator.UNSELECTABLE_HEX_COLOR,
        DrawableColorsMaterialGenerator.LANDSCAPING_HEX_COLOR,
    ]
    // Darken the saturation by 50%
    public static BRIGHTEN_COEFFICIENT = 0.5

    private scene: Scene | null = null
    private materialsOptions: Record<string, StandardMaterial> = {}
    private highlightedMaterialOptions: Record<string, StandardMaterial> = {}

    /**
     * Initialization function of the generator
     * @param scene the scene that the generator will create material in
     */
    public initGenerator(scene: Scene): void {
        this.scene = scene
        this.createMaterialOptions()
    }

    /**
     * Apply the material in the material cache to the entire scene
     * or only to the meshes that are provided
     * @param meshes (optional) apply material only to these meshes
     * @returns
     */
    public applyMaterial(meshes?: Mesh[]): void {
        if (!this.scene) return
        const materialNameAndMeshesRecord: Record<string, Mesh[]> = {}
        const meshesToApplyTo: Mesh[] =
            meshes ?? this.scene.rootNodes.flatMap((root) => root.getChildMeshes().map((mesh) => mesh as Mesh))

        meshesToApplyTo.forEach((mesh) => {
            const concreteMesh = mesh as Mesh
            const meshMetaData = mesh.metadata

            /**
             * The core of tha algorithm below is:
             *
             * 1 -If the mesh is not selectable i.e ids in meta data
             * is of length 0 then give it the unselectable hex color
             *
             * 2-If it has ids and the type is one of the types of
             * drawables that we know of in IMUP_DRAWABLE_COLORS then
             * assign it to that color, otherwise assign it unknown
             * drawable color
             *
             * 3- If it is landscaping then use the landscaping color
             *
             * then push the mesh into the material and mesh cache to
             * color all meshes with the same color correctly and keep
             * them stored in same array in record entries
             */
            let materialOptionType =
                meshMetaData && meshMetaData.isLandscaping
                    ? this.calculateMaterialName(DrawableColorsMaterialGenerator.LANDSCAPING_HEX_COLOR)
                    : this.calculateMaterialName(DrawableColorsMaterialGenerator.UNSELECTABLE_HEX_COLOR)

            if (meshMetaData && meshMetaData.ids.length > 0) {
                const drawableType = meshMetaData.ids[0].type
                const drawableColorData = drawableType && IMUP_DRAWABLE_COLORS[drawableType]

                materialOptionType = drawableColorData
                    ? this.calculateMaterialName(IMUP_DRAWABLE_COLORS[drawableType].hexCode)
                    : this.calculateMaterialName(IMUP_DRAWABLE_COLORS.le_unknown.hexCode)
            }

            !materialNameAndMeshesRecord[materialOptionType]
                ? (materialNameAndMeshesRecord[materialOptionType] = [concreteMesh])
                : materialNameAndMeshesRecord[materialOptionType].push(concreteMesh)
        })

        Object.keys(materialNameAndMeshesRecord).forEach((optionName) => {
            this.applyMaterialOptionsToMeshes(optionName, materialNameAndMeshesRecord[optionName])
        })
    }

    private applyMaterialOptionsToMeshes(optionsName: string, meshes: Mesh[]): void | Mesh[] | null {
        if (!this.materialsOptions[optionsName]) return
        meshes.forEach((mesh) => {
            this.applyMaterialOnMesh(mesh, this.materialsOptions[optionsName])
            mesh.metadata = {
                ...mesh.metadata,
                materialOptions: {
                    normalMaterial: this.materialsOptions[optionsName],
                    highlightedMaterial: this.highlightedMaterialOptions[optionsName],
                },
            }
        })
    }

    /**
     * Convert the material color to a darker color based on defined class dimming
     * values by lower v value of HSV
     * @param originalColor the original color3 object
     * @returns darkened object
     */
    private static calculateNewMaterialColor = (originalColor: Color3): Color3 => {
        // Convert to HSV, r = h, g = s, b =v
        const hsvColor = originalColor.toHSV()

        hsvColor.g = hsvColor.g * DrawableColorsMaterialGenerator.BRIGHTEN_COEFFICIENT
        const darkenedRGBColor = new Color3()

        Color3.HSVtoRGBToRef(hsvColor.r, hsvColor.g, hsvColor.b, darkenedRGBColor)

        return darkenedRGBColor
    }

    private calculateMaterialName = (hexCode: string) => `${hexCode}-drawable`

    private createMaterialFromHexColor = (hexColor: string, isHighlighted: boolean): StandardMaterial | void => {
        if (!DrawableColorsMaterialGenerator.isHexColorValid(hexColor)) return

        const newMaterial = new StandardMaterial(
            `${this.calculateMaterialName(hexColor)}${isHighlighted ? '-highlighted' : ''}`,
            this.scene!
        )
        const color3 = Color3.FromHexString(hexColor)

        !isHighlighted
            ? (newMaterial.ambientColor = DrawableColorsMaterialGenerator.calculateNewMaterialColor(color3))
            : (newMaterial.emissiveColor = color3)

        newMaterial.disableLighting = true

        if (hexColor === IMUP_DRAWABLE_COLORS['roofing'].hexCode) {
            /**
             * set the zOffset for the material to prevent z-order fighting
             * which can result in flickering on meshes that occupy a common plane.
             * relevant babylon forum: https://forum.babylonjs.com/t/flickering-when-camera-moves-far-away-z-order-fighting/669
             *
             * set to 1 to give priority to meshes constructed with the
             * custom mesh tool's create method, which builds majority of the model.
             */
            newMaterial.zOffset = 1
        }

        newMaterial.backFaceCulling = false

        return newMaterial
    }

    private createMaterialOptions = () => {
        Object.values(IMUP_DRAWABLE_COLORS).forEach((color) => {
            if (this.materialsOptions[this.calculateMaterialName(color.hexCode)]) return
            const newMaterial: StandardMaterial | void = this.createMaterialFromHexColor(color.hexCode, false)

            if (!newMaterial) return
            this.materialsOptions[this.calculateMaterialName(color.hexCode)] = newMaterial

            const newHighlightedMaterial: StandardMaterial | void = this.createMaterialFromHexColor(color.hexCode, true)

            if (!newHighlightedMaterial) return
            this.highlightedMaterialOptions[this.calculateMaterialName(color.hexCode)] = newHighlightedMaterial
        })
        DrawableColorsMaterialGenerator.NON_DRAWABLE_MATERIAL_COLORS.forEach((hexColor) => {
            const material = this.createMaterialFromHexColor(hexColor, false)

            if (!material) return
            this.materialsOptions[this.calculateMaterialName(hexColor)] = material
        })
    }
}
