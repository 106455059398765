import '@babylonjs/core/Collisions/collisionCoordinator'
import '@babylonjs/core/Culling/ray'

import { Scene } from '@babylonjs/core/scene'
import isNull from 'lodash/isNull'
import { call, put, select, StrictEffect } from 'redux-saga/effects'

import { build3DModelHome, Select3DInputData } from './build3DModel'
import { prepare3DTo2DMapping } from './data-prep/prepare3DTo2DMapping'
import { updateCornersMetadata } from './data-prep/prepareCorners'
import { updateGeometryMetadataIds } from './data-prep/prepareGeometryMetadata'
import { updateJunctionsMetadata } from './data-prep/prepareJunctions'
import { updatePolygonMetadata } from './data-prep/preparePolygons'
import { updateRoofEdgesMetaData } from './data-prep/prepareRoofEdges'
import { OpeningGroup } from '../../../models/activeDrawable'
import managers from '../../lib/managers'
import { BabylonManager } from '../../lib/managers/BabylonManager'
import { Label, Select, Workspace } from '../../lib/toolBoxes/3D'
import {
    openingToMeshIdMappingSelector,
    State3D,
    updateOverFramedRoofFaces,
    updateRoofFaces,
    visibleFloorSelector,
} from '../../slices/3D'
import { update3DTo2DMappings } from '../../slices/common'
import { selectDrawableGroupsGeometries } from '../../slices/geometry'
import { ThreeDToTwoDRecord } from '../../types'

export function* handleUpdateOpeningGroupsSuccess3D(): Generator<
    StrictEffect,
    void,
    (BabylonManager | null) &
        (Record<string, string> | null) &
        (ThreeDToTwoDRecord | null) &
        (string | null) &
        Scene &
        OpeningGroup[] &
        Workspace
> {
    const manager: BabylonManager | null = yield call(managers.get3DManager)

    if (isNull(manager)) return

    // Get the structure and geometry data from the store
    const {
        geometries,
        roofFacesData,
        overFramedRoofFacesData,
        cornersData,
        roofSingleEdgesData,
        junctionsData,
    }: Pick<
        State3D,
        | 'geometries'
        | 'roofFacesData'
        | 'overFramedRoofFacesData'
        | 'cornersData'
        | 'roofSingleEdgesData'
        | 'junctionsData'
    > = yield select(Select3DInputData)

    // get openings to MeshIds map
    const openingsToMeshIdsMap: Record<string, string> | null = yield select(openingToMeshIdMappingSelector)

    const drawableGroups = yield select(selectDrawableGroupsGeometries)

    // Get the current visible floor
    const visibleFloor: string | null = yield select(visibleFloorSelector)

    // prepare new 3D to 2D record (drawable groups updated by reducer prior to saga triggered)
    const threeDToTwoDMappings: ThreeDToTwoDRecord | null = yield call(
        prepare3DTo2DMapping,
        openingsToMeshIdsMap,
        drawableGroups
    )

    if (!isNull(threeDToTwoDMappings)) {
        // persist new threeDToTwoDMappings in store
        yield put(update3DTo2DMappings(threeDToTwoDMappings))

        const [workspace, label, selectTool]: [Workspace, Label, Select] = yield call(manager.getTools, [
            Workspace.NAME,
            Label.NAME,
            Select.NAME,
        ])

        if (!isNull(geometries)) {
            // Update geometries metadata
            yield call(updateGeometryMetadataIds, geometries, threeDToTwoDMappings)
        }

        if (!isNull(junctionsData)) {
            // Update junction metadata
            yield call(updateJunctionsMetadata, junctionsData, threeDToTwoDMappings)
        }

        if (!isNull(roofFacesData)) {
            //Update roof faces data
            yield call(updatePolygonMetadata, roofFacesData, threeDToTwoDMappings, updateRoofFaces)
        }

        if (!isNull(overFramedRoofFacesData)) {
            //Update overframed roof faces
            yield call(updatePolygonMetadata, overFramedRoofFacesData, threeDToTwoDMappings, updateOverFramedRoofFaces)
        }

        if (!isNull(roofSingleEdgesData)) {
            // Update roof edges
            yield call(updateRoofEdgesMetaData, roofSingleEdgesData, threeDToTwoDMappings)
        }

        if (!isNull(cornersData)) {
            // Update corner data
            yield call(updateCornersMetadata, cornersData, threeDToTwoDMappings)
        }

        // Delete meshes in scene
        yield call(workspace.clearScene)

        // Clear all GUI elements
        yield call(label.clearLabels)

        // Clear all selections
        yield call(selectTool.clearSelectedMeshes)

        // Rebuild scene but do not initialize core scene elements (root node, environment, etc.)
        yield call(build3DModelHome, false)

        // Toggle the correct visible floor
        yield call(workspace.toggleVisibleFloor, isNull(visibleFloor) ? [] : [visibleFloor])
    }
}

export default handleUpdateOpeningGroupsSuccess3D
