import { createSelector, PayloadAction } from '@reduxjs/toolkit'
import { call, fork, select } from 'redux-saga/effects'

import { resetDrawableHighlightState } from './resetDrawableHighlightState'
import { DRAWABLE_TYPES } from '../../../shared/constants/drawable-types'
import { RootState } from '../../../stores'
import managers from '../../lib/managers'
import { Color, Workspace } from '../../lib/toolBoxes/2D'

export const selectEditingActive = createSelector(
    (state: RootState) => state.IMUP['2D'].editingActive,
    (state) => state
)

export function* handleToggleOpeningLocationOnHover2D(
    action: PayloadAction<{ opening_location_id: number; exiting?: boolean }>
) {
    const paperManager = yield call(managers.get2DManager)

    if (!paperManager) return
    const workspaceTool: Workspace = yield call(paperManager.getTool, Workspace.NAME)

    const paths: paper.PathItem[] | null = yield call(
        workspaceTool.getItemsWithOpeningLocationId,
        action.payload.opening_location_id
    )

    // Return if there's no path found
    if (!paths) return

    // for headers the items found by opening location id
    // will be both the group with labels rectangle and the path
    // itself, we always want the path
    const path: paper.Item | undefined =
        paths.length === 1
            ? paths[0]
            : paths.find((item) => {
                  if (item.data.drawing_type === DRAWABLE_TYPES.HEADER) {
                      return !item.children
                  }
              })

    // Return if there's no path found
    if (!path) return

    const editingActive: boolean = yield select(selectEditingActive)

    if (editingActive) return

    // Attach the path's animation function and highlight it if there is no animation active on the project view
    // Otherwise, clear the animation and reset the shape
    const paperScope: paper.PaperScope = yield call(workspaceTool.getPaperScope)

    if (!paperScope.project.view.onFrame && !action.payload.exiting) {
        const colorTool: Color = yield call(paperManager.getTool, Color.NAME)
        const redColor = yield call(colorTool.createColor, 'red')

        yield call(highlightPath, path, redColor)
        yield call(animatePath, path)
    } else {
        const pathLine: paper.PathItem | paper.Item = path

        yield call(removeAnimation, pathLine)

        if (pathLine.strokeColor || pathLine.fillColor) yield fork(resetDrawableHighlightState, pathLine)

        if (pathLine.strokeWidth) pathLine.strokeWidth = pathLine.data.originalStrokeWidth
    }
}

export function animatePath(path: paper.Item) {
    path.project.view.onFrame = path.data.onFrameHandler
}

export function removeAnimation(path: paper.Item) {
    path.project.view.onFrame = null
}

export function highlightPath(path: paper.Item, color: paper.Color) {
    path.strokeColor = color
}
