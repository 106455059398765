import { PayloadAction } from '@reduxjs/toolkit'
import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects'

import { DELETE_BUILDING } from '../../../actions/drawable'
import { deleteProjectBuilding } from '../../../api/takeoff-api'
import { DocumentChunk } from '../../../models/documentChunk'
import { DocumentMapping } from '../../../models/documentMapping'
import { NormalizedFlag } from '../../../models/flags'
import { Building } from '../../../models/projectBuilding'
import { ENV_VAR_NAMES, getEnvVar } from '../../../shared/services/env-services'
import {
    hardUpdateDocumentMappings,
    moveChunksToBuilding,
    selectDocumentChunks,
    selectDocumentMappings,
} from '../../slices/documents'
import { selectMaterialFlags } from '../../slices/materialFlags'
import { deleteMultipleFlagsAction, handleDeleteMultipleFlags } from '../2D/deleteMultipleFlags'

export function* handleBuildingDelete(
    action: PayloadAction<{ buildingId: Building['id'] }>
): Generator<StrictEffect, void, DocumentMapping[] & DocumentChunk[] & NormalizedFlag[]> {
    const { buildingId } = action.payload
    const enableFlags = getEnvVar(ENV_VAR_NAMES.ENABLE_FLAGS).toString() === 'true'

    try {
        yield call(deleteProjectBuilding, buildingId)

        const mappings: DocumentMapping[] = yield select(selectDocumentMappings) || []
        const documentChunks: DocumentChunk[] | null = yield select(selectDocumentChunks)

        const documentChunkIds = mappings.reduce((accumulator: number[], mapping: DocumentMapping) => {
            if (mapping.building_id === buildingId) {
                accumulator.push(mapping.document_chunk_id)
            }

            return accumulator
        }, [])

        if (enableFlags) {
            const flags: NormalizedFlag[] = yield select(selectMaterialFlags)

            const flagIdsToDelete: number[] = []

            flags.forEach((flag) => {
                flag.opening_links.forEach((link) => {
                    if (documentChunkIds.includes(link.document_chunk_id) && !flagIdsToDelete.includes(flag.id)) {
                        flagIdsToDelete.push(flag.id)
                    }
                })
            })

            yield call(handleDeleteMultipleFlags, {
                payload: { flag_ids: flagIdsToDelete },
                type: deleteMultipleFlagsAction.type,
            })
        }

        if (documentChunks) {
            const documentChunksToMove = documentChunks.filter((documentChunk) =>
                documentChunkIds.includes(documentChunk.id)
            )

            if (documentChunksToMove.length) {
                yield put(moveChunksToBuilding({ toBuildingId: -1, updatedDocumentChunks: documentChunksToMove }))
            }
        }

        const updatedMappings = mappings.filter((mapping) => mapping.building_id !== buildingId)

        yield put(hardUpdateDocumentMappings(updatedMappings))
    } catch (error) {
        yield call(console.error, (error as Error).message)
    }
}

export function* watchForBuildingDelete() {
    yield takeEvery(DELETE_BUILDING, handleBuildingDelete)
}
