import { ActionReducerMapBuilder, createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import isArray from 'lodash/isArray'

import { FETCH_USER_PERMISSIONS_SUCCESS } from '../../actions/user'
import { UserPermission } from '../../models/user'

export type UserPermissionsState = { permissions: UserPermission[] }

export const initialUserPermissionsState: UserPermissionsState = {
    permissions: [],
}

export const gotUserPermissions = createAction<{ userPermissions: UserPermission[] | undefined }>(
    FETCH_USER_PERMISSIONS_SUCCESS
)

function handleUserPermissions(
    state: UserPermissionsState,
    { payload }: PayloadAction<{ userPermissions: UserPermission[] | undefined }>
) {
    const newPermissions = isArray<UserPermission>(payload.userPermissions)
        ? [...payload.userPermissions]
        : initialUserPermissionsState.permissions

    state.permissions = newPermissions
}

const extraReducers = (builder: ActionReducerMapBuilder<UserPermissionsState>): void => {
    builder.addCase(gotUserPermissions, handleUserPermissions)
}

const userPermissionsSlice = createSlice({
    name: 'userPermissions',
    initialState: initialUserPermissionsState,
    reducers: {},
    extraReducers,
})

export default userPermissionsSlice
