import { IStateMediator, ITool, IToolBox, ToolBoxConfig, Tools } from '../../types'

export abstract class AbstractToolBox implements IToolBox {
    public tools: Tools = {}
    protected mediator: IStateMediator

    constructor(config: ToolBoxConfig) {
        this.mediator = config.mediator
    }

    public getTool = <T extends ITool>(name: string): T => this.tools[name] as T

    public getTools = (names: string[]): ITool[] => names.map((name) => this.tools[name])

    protected registerTool = (tool: ITool): void => {
        if (tool.name) {
            AbstractToolBox[tool.name] = tool.name
            this.tools[tool.name] = tool
        }
        if (tool.cursor) {
            AbstractToolBox[tool.cursor] = tool.cursor
        }
    }

    abstract set(tool: any): void
}

export default AbstractToolBox
