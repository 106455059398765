import { FormValidation, UiSchema } from '@rjsf/core'
import isUndefined from 'lodash/isUndefined'

import { calculateLFMeasurementOfMaterial } from '../imup/sagas/2D/createDrawableLocation'
import { FormProperties } from '../imup/types'
import { ActiveDrawable } from '../models/activeDrawable'
import { GeneralDrawableSettings } from '../models/activeDrawableSettings'
import { DRAWABLE_TYPES } from '../shared/constants/drawable-types'
import { FORM_ERROR_MESSAGES } from '../shared/constants/error-messages'
import { pitchNumberValidation } from '../shared/services/field-validation/field-validation-service'
import { deckOrPorch } from '../utils/formSchema/constants'

const uiSchema: UiSchema = {
    name: {
        'ui:title': 'Generic SKU',
        'ui:placeholder': 'Enter Generic SKU',
    },
    description: {
        'ui:title': 'Description',
        'ui:placeholder': 'Enter Description',
    },
    package: {
        'ui:placeholder': 'Select Package',
    },
    quantity: {
        'ui:title': 'Quantity',
        'ui:placeholder': 'Enter Quantity',
    },
    pitch: {
        'ui:title': 'Pitch',
        'ui:placeholder': 'Enter Pitch',
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
    bundle_location: {
        'ui:placeholder': 'Select Sub-Region Location',
    },
    bundle_floor_level: {
        'ui:placeholder': 'Select Sub-Region Floor Level',
    },
    bundle_other_floor_level: {
        'ui:placeholder': 'Enter Sub-Region Other Floor Level',
    },
}

const onValidate = (formData: any, errors: FormValidation) => {
    if (!isUndefined(formData.pitch) && !pitchNumberValidation(formData.pitch)) {
        errors['pitch'].addError(FORM_ERROR_MESSAGES.PITCH_NUMBER)
    }
}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }

    newSettings.quantity = Math.ceil(formData.quantity)

    if (!deckOrPorch.includes(formData.bundle_name)) {
        newSettings.bundle_floor_level = null
    }

    if (formData.bundle_floor_level !== 'other') {
        newSettings.bundle_other_floor_level = null
    }

    return newSettings
}

/**
 * Augment the drawables of the from based on the new settings
 * @param drawables the drawables in the group
 * @param newSettings the new group settings
 * @param scaleFactor the scaleFactor of the page
 * @returns
 */
function prepareDrawables(input: {
    drawables: ActiveDrawable[]
    newSettings: GeneralDrawableSettings
    scaleFactor: number
}): ActiveDrawable[] {
    const { drawables, newSettings } = input

    return drawables.map((drawable) => {
        drawable.settings.quantity = calculateLFMeasurementOfMaterial(drawable.settings.linear_total, {
            type: DRAWABLE_TYPES.WILDCARD_LINE,
            settings: newSettings,
        })

        return drawable
    })
}

const form: FormProperties = { uiSchema, onValidate, onSubmit, prepareDrawables }

export default form
