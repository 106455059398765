import React from 'react'
import Modal from 'react-bootstrap/Modal'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'

import './modal-dialog.scss'

interface ModalDialogProps {
    title?: string | React.ReactElement
    body: string | React.ReactElement
    footer?: string | React.ReactElement
    className?: string
    footerClassName?: 'pull-left' | 'center' | 'pull-right'
    closeButton?: boolean
    handleClose?: () => void
}

const ModalDialogComponent = ({
    title,
    body,
    footer,
    className,
    footerClassName = 'center',
    closeButton,
    handleClose,
}: ModalDialogProps): React.ReactElement => {
    return (
        <Modal animation centered className={className} onHide={handleClose} show size="lg">
            <div className="modalContent">
                <ModalHeader closeButton={closeButton}>
                    <ModalTitle>{title}</ModalTitle>
                </ModalHeader>
                <ModalBody>{body}</ModalBody>
                <ModalFooter className={footerClassName}>{footer}</ModalFooter>
            </div>
        </Modal>
    )
}

export default ModalDialogComponent
