import { FormValidation, UiSchema } from '@rjsf/core'

import { calculateAreaMeasurementsForMaterial } from '../imup/sagas/2D/createDrawableLocation'
import { FormProperties } from '../imup/types'
import { ActiveDrawable } from '../models/activeDrawable'
import { GeneralDrawableSettings } from '../models/activeDrawableSettings'
import { Region } from '../models/region'
import { DRAWABLE_TYPES } from '../shared/constants/drawable-types'
import { FORM_ERROR_MESSAGES } from '../shared/constants/error-messages'
import { pitchNumberValidation } from '../shared/services/field-validation/field-validation-service'
import { calculateTotalArea } from '../utils/formSchema/formSchema-helper-functions'

const cedarAndPineValues = ['CEDAR TG', 'PINE TG']
const vGrooveAndFineLineAndBeadedCeilingValues = ['V GROOVE', 'FINE LINE', 'BEADED CEILING']
const otherValue = 'other'
const pitchOtherFieldName = 'PITCH_OTHER'

export const uiSchema: UiSchema = {
    label: {
        'ui:title': 'Label',
    },
    selection: {
        'ui:title': 'Label',
        'ui:placeholder': 'Select Selection',
    },
    location: {
        'ui:placeholder': 'Select a Location',
    },
    pitch: {
        'ui:placeholder': 'Select Pitch',
    },
    fc_texture: {
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
    'ui:order': [
        'label',
        'selection',
        'fc_texture',
        'location',
        'board_width',
        'board_width_other',
        'pitch',
        'pitch_other',
    ],
}

const onValidate = (formData: any, errors: FormValidation) => {
    if (formData.pitch === otherValue) {
        if (!pitchNumberValidation(formData[pitchOtherFieldName])) {
            errors[pitchOtherFieldName].addError(FORM_ERROR_MESSAGES.PITCH_NUMBER)
        }
    }
}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }
    const isPitchNotPresentInSettings = !('pitch' in newSettings)

    newSettings.pitch = isPitchNotPresentInSettings ? null : newSettings.pitch

    if (
        newSettings.selection !== '4X8 PANEL FC' &&
        newSettings.selection !== '4X9 PANEL FC' &&
        newSettings.selection !== '4X10 PANEL FC'
    ) {
        newSettings.fc_texture = null
    }

    if (
        !cedarAndPineValues.includes(formData.selection) &&
        !vGrooveAndFineLineAndBeadedCeilingValues.includes(formData.selection)
    ) {
        newSettings.board_width = ''
        newSettings.board_width_other = null
    }

    if (newSettings.pitch === otherValue && newSettings.pitch_other) {
        newSettings.pitch = String(newSettings.pitch_other).toUpperCase()

        delete newSettings.pitch_other
    } else if (newSettings.pitch !== otherValue) {
        newSettings.pitch_other = null
    }

    newSettings.label = !formData.label && settings.label ? null : formData.label

    return newSettings
}

function prepareDrawables(input: {
    drawables: ActiveDrawable[]
    newSettings: GeneralDrawableSettings
    scaleFactor: number
    dpi: number | null
    pdfScale: number
    xCalibrationFactor: number
    yCalibrationFactor: number
    regions: Region[]
    is3D?: boolean
}): ActiveDrawable[] {
    const {
        drawables,
        newSettings,
        scaleFactor,
        dpi,
        pdfScale,
        xCalibrationFactor,
        yCalibrationFactor,
        regions,
        is3D,
    } = input

    if (is3D) return drawables

    return drawables.map((drawable) => {
        const totalArea: number = calculateTotalArea(
            drawable.opening_locations,
            regions,
            scaleFactor,
            pdfScale,
            xCalibrationFactor,
            yCalibrationFactor,
            dpi
        )

        return {
            ...drawable,
            settings: {
                ...drawable.settings,
                quantity: calculateAreaMeasurementsForMaterial(totalArea, {
                    type: DRAWABLE_TYPES.PORCH_CEILING,
                    settings: newSettings,
                }),
            },
        }
    })
}

const form: FormProperties = { uiSchema, onValidate, onSubmit, prepareDrawables }

export default form
