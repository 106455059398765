import { createAction } from '@reduxjs/toolkit'
import { put, StrictEffect, takeLatest } from 'redux-saga/effects'

import {
    resetErrors,
    updateCorners,
    updateJoistLines,
    updateJunctions,
    updateOpeningsToMeshIdMap,
    updateOverFramedRoofFaces,
    updateRoofEdges,
    updateRoofFaces,
    updateShouldOpenMeshOpeningGroupMenu,
    updateStructure,
    updateStructureGeometries,
    updateTranslationInfo,
} from '../../slices/3D'
import { update3DTo2DMappings } from '../../slices/common'

export const reset3DState = createAction('reset3DState')

/**
 * Clears all the structures/geometries and mappings
 * to prevent rendering an old model.
 * Also clears errors.
 */
export function* handleReset3DState(): Generator<StrictEffect, void, unknown> {
    yield put(updateStructure(null))
    yield put(updateStructureGeometries(null))
    yield put(updateRoofFaces(null))
    yield put(updateOverFramedRoofFaces(null))
    yield put(updateCorners(null))
    yield put(updateRoofEdges(null))
    yield put(updateJunctions(null))
    yield put(update3DTo2DMappings(null))
    yield put(updateOpeningsToMeshIdMap(null))
    yield put(updateShouldOpenMeshOpeningGroupMenu(false))
    yield put(updateJoistLines(null))
    yield put(updateTranslationInfo(null))
    yield put(resetErrors())
}

export function* watchForReset3DState() {
    yield takeLatest(reset3DState.type, handleReset3DState)
}
