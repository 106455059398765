import { Vector3 } from '@babylonjs/core/Maths/math.vector'
import { AbstractMesh } from '@babylonjs/core/Meshes/abstractMesh'
import { LinesMesh } from '@babylonjs/core/Meshes/linesMesh'
import { Mesh } from '@babylonjs/core/Meshes/mesh'
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder'
import isNull from 'lodash/isNull'
import { all, call, takeLatest } from 'redux-saga/effects'

import managers from '../../lib/managers'
import { BabylonManager } from '../../lib/managers/BabylonManager'
import { Measure } from '../../lib/toolBoxes/3D'
import { Workspace } from '../../lib/toolBoxes/3D/tools/workspace/Workspace.tool'
import { updateMeasurementPoints } from '../../slices/3D'
import { IVector } from '../../types'

export function* createSpheresAndVectors(vectorInput: IVector, manager: BabylonManager, measureTool: Measure) {
    const newVector = new Vector3(vectorInput.x, vectorInput.y, vectorInput.z)
    const newSphere: Mesh = yield call(MeshBuilder.CreateSphere, Measure.MEASUREMENT_MESH_ID, {
        diameter: Measure.MEASUREMENT_DIAMETER,
    })

    newSphere.id = Measure.MEASUREMENT_MESH_ID
    newSphere.position = newVector
    newSphere.parent = manager.rootNode!
    newSphere.material = measureTool.measureHandleMaterial

    return newVector
}

export function shouldDeleteMeshesThatAreMeasurements(mesh: AbstractMesh) {
    return mesh.id === Measure.MEASUREMENT_MESH_ID
}

export function* handleDrawingMeasurementPoints(action: ReturnType<typeof updateMeasurementPoints>) {
    const manager: BabylonManager | null = yield call(managers.get3DManager)

    if (isNull(manager)) return

    const [workspace, measure]: [Workspace, Measure] = yield call(manager.getTools, [Workspace.NAME, Measure.NAME])

    yield call(workspace.deleteMeshesInCurrentScene, shouldDeleteMeshesThatAreMeasurements)

    if (!isNull(action.payload)) {
        const vectorPoints: Vector3[] = yield all(
            action.payload.map(function* (vectorInput) {
                return yield call(createSpheresAndVectors, vectorInput, manager, measure)
            })
        )

        if (vectorPoints.length === 2) {
            const newLineMesh: LinesMesh = yield call(
                MeshBuilder.CreateDashedLines,
                Measure.MEASUREMENT_MESH_ID,
                {
                    points: vectorPoints,
                    dashSize: Measure.MEASUREMENT_DASH_SIZE,
                    gapSize: Measure.MEASUREMENT_DASH_GAP_SIZE,
                    dashNb: Measure.MEASUREMENT_DASH_NUMBER,
                },
                manager.getScene()
            )

            newLineMesh.id = Measure.MEASUREMENT_MESH_ID
            newLineMesh.parent = manager.rootNode!
            newLineMesh.color = Measure.MEASUREMENT_LINE_COLOR
            newLineMesh.edgesColor = Measure.MEASUREMENT_LINE_COLOR.toColor4(1)
            newLineMesh.edgesWidth = Measure.MEASUREMENT_LINE_EDGE_WIDTH
            newLineMesh.enableEdgesRendering()
        }
    }
}

export function* watchForMeasurementPointsChange() {
    yield takeLatest(updateMeasurementPoints.type, handleDrawingMeasurementPoints)
}
