import { all, call, takeEvery } from 'redux-saga/effects'

import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Workspace } from '../../lib/toolBoxes/2D'
import { resetMaterialModificationConflicts } from '../../slices/masterSetPlan'

export function* handleRemoveModificationsFromCanvas() {
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (!manager) return

    const workspaceTool: Workspace = yield call(manager.getTool, Workspace.NAME)

    const items: paper.Item[] = yield call(workspaceTool.getItemsWithCriteria, 'data', (data) => data.isModification)

    yield all(items.map((item) => call(item.remove.bind(item))))
}

export function* watchForResettingMaterialModifications() {
    yield takeEvery(resetMaterialModificationConflicts, handleRemoveModificationsFromCanvas)
}
