import { Project } from '../models/project'

export const FETCH_PROJECT_PENDING = 'FETCH_PROJECT_PENDING'
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS'
export const FETCH_PROJECT_ERROR = 'FETCH_PROJECT_ERROR'
export const SELECTED_BOX_CHANGED = 'SELECTED_BOX_CHANGED'
export const STORE_IMAGE_REGION = 'STORE_IMAGE_REGION'
export const STORE_IMAGE_SCALE = 'STORE_IMAGE_SCALE'
export const STORE_PAGE_NAME = 'STORE_PAGE_NAME'
export const REMOVE_FLOOR_COORDINATES = 'REMOVE_FLOOR_COORDINATES'
export const SET_SELECTED_FLOORS = 'SET_SELECTED_FLOORS'
export const SET_SELECTED_CHUNK_ID = 'SET_SELECTED_CHUNK_ID'
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS'
export const FETCH_CUSTOMER_LOCATIONS_SUCCESS = 'FETCH_CUSTOMER_LOCATIONS_SUCCESS'
export const ROTATE_IMAGES_RIGHT = 'ROTATE_IMAGES_RIGHT'
export const ROTATE_IMAGES_LEFT = 'ROTATE_IMAGES_LEFT'
export const ROTATE_IMAGES_UPDATE = 'ROTATE_IMAGES_UPDATE'
export const CLEAN_PROJECT_STATE = 'CLEAN_PROJECT_STATE'

const fetchProjectPending = () => {
    return {
        type: FETCH_PROJECT_PENDING,
    }
}

const fetchProjectSuccess = (project: Project) => {
    return {
        type: FETCH_PROJECT_SUCCESS,
        payload: project,
    }
}

const setSelectedBox = (selectedBox) => {
    const selectedBoxCopy = {
        ...selectedBox,
        file: {
            ...selectedBox.file,
            document_chunks: selectedBox.file.document_chunks.map((chunk) => {
                if (chunk.rotateDegree) {
                    return chunk
                }

                return { ...chunk, rotateDegree: 0 }
            }),
        },
    }

    return {
        type: SELECTED_BOX_CHANGED,
        payload: selectedBoxCopy,
    }
}

const setSelectedChunkId = (chunkId) => {
    return {
        type: SET_SELECTED_CHUNK_ID,
        payload: chunkId,
    }
}

const setSelectedFloors = (fileId, selectedFloors) => {
    return {
        type: SET_SELECTED_FLOORS,
        payload: {
            fileId,
            selectedFloors,
        },
    }
}

const removeFloorCoordinates = (floorHash, chunkId, fileId) => {
    return {
        type: REMOVE_FLOOR_COORDINATES,
        payload: {
            floorHash,
            chunkId,
            fileId,
        },
    }
}

const storeImageRegions = (regionsData, chunkId, fileId) => {
    return {
        type: STORE_IMAGE_REGION,
        payload: {
            regions: regionsData,
            chunkId,
            fileId,
        },
    }
}

const storeImageScale = (scale) => {
    return {
        type: STORE_IMAGE_SCALE,
        payload: {
            scale,
        },
    }
}
const storePageName = (pageName) => {
    return {
        type: STORE_PAGE_NAME,
        payload: {
            pageName,
        },
    }
}

const fetchCustomersSuccess = (customers) => {
    return {
        type: FETCH_CUSTOMERS_SUCCESS,
        payload: customers,
    }
}

const fetchCustomerLocationsSuccess = (customerLocations) => {
    return {
        type: FETCH_CUSTOMER_LOCATIONS_SUCCESS,
        payload: customerLocations,
    }
}

const rotatedImageUpdate = (images) => {
    return {
        type: ROTATE_IMAGES_UPDATE,
        payload: images,
    }
}

const rotateImageRightSuccess = (activeImageIndex) => {
    return {
        type: ROTATE_IMAGES_RIGHT,
        payload: activeImageIndex,
    }
}

const rotateImageLeftSuccess = (activeImageIndex) => {
    return {
        type: ROTATE_IMAGES_LEFT,
        payload: activeImageIndex,
    }
}

const cleanProjectState = () => {
    return {
        type: CLEAN_PROJECT_STATE,
    }
}

export {
    cleanProjectState,
    fetchCustomerLocationsSuccess,
    fetchCustomersSuccess,
    fetchProjectPending,
    fetchProjectSuccess,
    removeFloorCoordinates,
    rotatedImageUpdate,
    rotateImageLeftSuccess,
    rotateImageRightSuccess,
    setSelectedBox,
    setSelectedChunkId,
    setSelectedFloors,
    storeImageRegions,
    storeImageScale,
    storePageName,
}
