import { REGION_ENUMS, SnappingSide } from '../../types'
import { SNAPPING_SIDE, SNAPPING_TOLERANCE } from '../../utils/constants'

/**
 * This utility function manages the snapping behavior while drawing
 * @param paper
 * @param input
 * @param options
 * @param options.tolerance the tolerance of the snapping
 * @returns
 */
export const snap = (
    paper: paper.PaperScope,
    input: paper.Point,
    options?: {
        tolerance: number
    }
): paper.Point | null => {
    const result = paper.project.hitTest(input, {
        tolerance: options?.tolerance ?? 10,
        fill: true,
        stroke: true,
        segments: true,
        match: hitFilter,
    })

    return result ? result.point : null
}

/**
 * Filter out highlights and regions from the hitTest for snapping. This function is only called if there is a hit result that needs to be checked for filtering.
 * See http://paperjs.org/reference/project/#hittest-point match option for more info.
 * @param hit: HitResult
 * @returns boolean -> see documentation referenced above for more info
 */
export const hitFilter = (hit: paper.HitResult): boolean => {
    return !(
        hit.item.data.isHighlight ||
        hit.item.data.shapeType === REGION_ENUMS.TYPE ||
        hit.item.data.ignoreHitFilter
    )
}

/**
 * Get the closest point on the path to the given point
 * Calculate distance between the closest point and the given point
 *
 * @param point
 * @param path
 */
export const distanceToPath = (point: paper.Point, path: paper.Path): number => {
    const closestPoint = path.getNearestPoint(point)

    return closestPoint.getDistance(point)
}

export const getElementsWithinDistance = (paper: paper.PaperScope, point: paper.Point, distance: number) => {
    return paper.project.getItems({
        data: (data) => data?.drawable_id || data?.aiSuggestion?.id,
        match: (item) => {
            // ignore elements which are groups or hidden
            if (!!item?.children?.length || !item.visible) {
                return false
            }

            return point.getDistance(item?.getNearestPoint(point)) <= distance
        },
    })
}

export const getPreparedSegments = (path: paper.Path | paper.CompoundPath): paper.Segment[] => {
    if (path?.children && !!path.children.length) {
        // used [0] because it's a layout outside element
        return (path.children[0] as paper.Path).segments
    }

    return (path as paper.Path).segments
}

/**
 * Check if paths intersect with tolerance
 *
 * @param path1
 * @param path2
 * @param tolerance
 */

export const pathsIntersect = (path1: paper.Path, path2: paper.Path, tolerance: number): boolean => {
    const segments1 = getPreparedSegments(path1)
    const segments2 = getPreparedSegments(path2)

    // check if any point from path1 lies within path2 within tolerance
    for (let i = 0; i < segments1.length; i++) {
        const point = segments1[i].point

        if (distanceToPath(point, path2) <= tolerance) {
            return true
        }
    }

    // check if any point from path2 lies within path1 within tolerance
    for (let i = 0; i < segments2.length; i++) {
        const point = segments2[i].point

        if (distanceToPath(point, path1) <= tolerance) {
            return true
        }
    }

    return false // No intersections found
}

/**
 * Return new snap point base on calculation
 *
 * @param paper
 * @param item - all items on current page that can be snapped
 * @param movingItem - drawing element
 * @param tolerance - px when we can snap
 * @param eventPoint - used to snap on draw
 */
export const getSnapPosition = (
    paper: paper.PaperScope,
    item: paper.Item,
    movingItem: paper.PathItem,
    tolerance: number,
    eventPoint?: paper.Point
): paper.Point | null => {
    if (Math.abs(item.bounds.right - movingItem.bounds.left) <= tolerance) {
        if (eventPoint) {
            return new paper.Point(item.bounds.right, eventPoint.y)
        }

        // snap on the right
        return new paper.Point(item.bounds.right + movingItem.bounds.width / 2, movingItem.position.y)
    } else if (Math.abs(item.bounds.left - movingItem.bounds.right) <= tolerance) {
        if (eventPoint) {
            return new paper.Point(item.bounds.left, eventPoint.y)
        }

        // snap on the left
        return new paper.Point(item.bounds.left - movingItem.bounds.width / 2, movingItem.position.y)
    } else if (Math.abs(item.bounds.top - movingItem.bounds.bottom) <= tolerance) {
        if (eventPoint) {
            return new paper.Point(eventPoint.x, item.bounds.top)
        }

        // snap on the top
        return new paper.Point(movingItem.position.x, item.bounds.top - movingItem.bounds.height / 2)
    } else if (Math.abs(item.bounds.bottom - movingItem.bounds.top) <= tolerance) {
        if (eventPoint) {
            return new paper.Point(eventPoint.x, item.bounds.bottom)
        }

        // snap on the bottom
        return new paper.Point(movingItem.position.x, item.bounds.bottom + movingItem.bounds.height / 2)
    }

    return null
}

/**
 * Using path and event point calculate the snap point
 * @param paper
 * @param path - path to check if we can snap
 * @param eventPoint - event point which is moved
 * @param tolerance - number when we should snap
 */
export const getSnapPositionForEventPoint = (
    paper: paper.PaperScope,
    path: paper.Path,
    eventPoint: paper.Point,
    tolerance: number = SNAPPING_TOLERANCE
): SnappingSide => {
    const { top, right, bottom, left } = path.bounds

    const inBetweenHorizontal = left <= eventPoint.x && eventPoint.x <= right
    const inBetweenVertical = top <= eventPoint.y && eventPoint.y <= bottom

    if (inBetweenHorizontal && !inBetweenVertical && Math.abs(path.bounds.top - eventPoint.y) <= tolerance) {
        return { side: SNAPPING_SIDE.TOP, point: new paper.Point(eventPoint.x, path.bounds.top) }
    }

    if (inBetweenVertical && !inBetweenHorizontal && Math.abs(path.bounds.right - eventPoint.x) <= tolerance) {
        return { side: SNAPPING_SIDE.RIGHT, point: new paper.Point(path.bounds.right, eventPoint.y) }
    }

    if (inBetweenHorizontal && !inBetweenVertical && Math.abs(path.bounds.bottom - eventPoint.y) <= tolerance) {
        return { side: SNAPPING_SIDE.BOTTOM, point: new paper.Point(eventPoint.x, path.bounds.bottom) }
    }

    if (inBetweenVertical && !inBetweenHorizontal && Math.abs(path.bounds.left - eventPoint.x) <= tolerance) {
        return { side: SNAPPING_SIDE.LEFT, point: new paper.Point(path.bounds.left, eventPoint.y) }
    }

    return { side: SNAPPING_SIDE.NONE, point: eventPoint }
}

/**
 * Return items which can be snapped
 *
 * @param paper
 * @param items
 */
export const getSnapPaths = (paper: paper.PaperScope, items: paper.Item[]): paper.Path[] => {
    return items
        .flatMap((item) => {
            if (item.visible) {
                // compound path is areas with cutout
                if (item instanceof paper.CompoundPath) {
                    return item.children.map((child) => (child instanceof paper.Path ? child : null))
                }

                if (item instanceof paper.Path) {
                    return item
                }
            }

            return null
        })
        .filter((item) => item !== null) as paper.Path[]
}

/**
 * Get the paper scope, items on layout and mouse event, calculate and return snapping point
 * @param paper
 * @param items
 * @param eventPoint
 */
export const getSnapPoint = (paper: paper.PaperScope, items: paper.Item[], eventPoint: paper.Point): paper.Point => {
    const paths = getSnapPaths(paper, items)
    let snapPoint: paper.Point = eventPoint

    paths.forEach((path) => {
        if (snapPoint && path.visible && distanceToPath(snapPoint, path as paper.Path) <= SNAPPING_TOLERANCE) {
            snapPoint = path.getNearestPoint(snapPoint)
        }
    })

    return snapPoint
}

/**
 * Get position between items to snap on draw
 *
 * @param paper
 * @param eventPoint
 * @param nearElementsPoints
 */
export const getSnapPointBetweenItems = (
    paper: paper.PaperScope,
    eventPoint: paper.Point,
    nearElementsPoints: SnappingSide[]
): paper.Point => {
    let calculatedX = eventPoint.x
    let calculatedY = eventPoint.y

    const sideMap: { [key: string]: (point: paper.Point) => void } = {
        [SNAPPING_SIDE.TOP]: (point) => (calculatedY = point.y),
        [SNAPPING_SIDE.RIGHT]: (point) => (calculatedX = point.x),
        [SNAPPING_SIDE.BOTTOM]: (point) => (calculatedY = point.y),
        [SNAPPING_SIDE.LEFT]: (point) => (calculatedX = point.x),
    }

    nearElementsPoints.forEach((element) => {
        if (sideMap[element.side]) {
            sideMap[element.side](element.point)
        }
    })

    return new paper.Point(calculatedX, calculatedY)
}

/**
 * On drawing element, detect the closest element where distance is less or equal to SNAPPING_TOLERANCE
 * and return the snap point for one element or calculate for multiple points
 *
 * @param paper
 * @param eventPoint
 * @param nearElements
 */
export const getSnapPointOnDraw = (
    paper: paper.PaperScope,
    eventPoint: paper.Point,
    nearElements: paper.Path[]
): paper.Point => {
    const nearElementsPoints: SnappingSide[] = nearElements.map((element) => {
        return getSnapPositionForEventPoint(paper, element, eventPoint, SNAPPING_TOLERANCE)
    })

    // when single item is the near one, use that item point
    if (nearElementsPoints.length === 1) {
        return nearElementsPoints[0].point
    }

    // when multiple items found, calculate initial point from where drawing is started
    return getSnapPointBetweenItems(paper, eventPoint, nearElementsPoints)
}
