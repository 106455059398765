/**
 * Enum contains DrawableTypes names and BundleName
 * It's used to set the group title and order in Navigation Tree
 * NOTE: object keys are in order that we display them in IMuP
 * "n" means "/" - added to replace and not remove and when it's needed
 */
export enum DRAWABLE_GROUPPING_NAMES {
    WILDCARD_AREA_TOOL = 'wildcard_area',
    WILDCARD_LINE_TOOL = 'wildcard_line',
    WILDCARD_COUNT_TOOL = 'wildcard_count',
    APPLIANCE = 'appliance',
    FLOOR_AREA = 'floor_area',
    BASEMENT_FINISHED_CEILING = 'basement_finished_ceiling',
    GABLE_AREA = 'gable_area',
    FRAMING_ROOFING = 'framing_roofing',
    EAVE_GABLE = 'eave_gable',
    WALL = 'wall',
    LOUVER = 'louver',
    HOG_FENCE = 'hog_fence',
    FOUNDATION_PILON = 'foundation_pilon',
    HEADER_N_BEAM = 'header_n_beam',
    DROPPED_BEAM = 'dropped_beam',
    FLUSH_BEAM = 'flush_beam',
    HEADER = 'header',
    HIP_N_VALLEY_BEAM = 'hip_n_valley_beam',
    RIDGE_BEAM = 'ridge_beam',
    POST = 'bearing_column',
    STEEL_BEAM_PLATE = 'steel_beam_plate',
    TRAYED_CEILING_AND_SOFFIT = 'trayed_ceiling_and_soffit',
    JOISTS = 'joists',
    LEDGER = 'ledger',
    BLOCKING = 'blocking',
    RIMBOARD_N_BLOCKING = 'rimboard_n_blocking',
    BRIDGING = 'bridging',
    FLOOR_TRUSS = 'floor_truss',
    CANTILEVER = 'cantilever',
    ROOFING_BEAM = 'roofing_beam',
    CANALES = 'canales',
    INTERIOR_FINISHED_CEILING = 'interior_finished_ceiling',
    PORCH = 'porch',
    SCREEN_PORCH = 'screen_porch',
    INTERIOR_DOOR = 'interior_door',
    EXTERIOR_DOOR = 'exterior_door',
    PATIO = 'patio_door',
    STAIRS = 'stairs',
    TRIM = 'trim',
    EXTERIOR_ROOFING = 'exterior_roofing',
    FLASHING = 'flashing',
    PORCH_CEILING = 'porch_ceiling',
    SIDING = 'siding',
    CORNER = 'corners',
    SIDING_STARTER = 'siding_starter',
    POST_WRAP = 'post_wrap',
    FYPON_BRACKET = 'fypon_bracket',
    ENTRYDOOR = 'entry_door',
    PORCH_MATERIAL = 'porch_material',
    SIDELIGHT = 'sidelight',
    AUTOMATED_BEAM_AND_HEADER = 'suggested_headers',
    LE_DOOR = 'door',
    LE_UNKNOWN = 'windows_and_doors',
    LE_WINDOW = 'window',
    FLOOR_SYSTEM = 'floor_system',
    ROOF_SYSTEM = 'roof_system',
    BLOCK_ROUGH = 'block_rough_opening',
    LOUVER_HOG_FENCE = 'louver_hog_fence',
    LF_SCREEN_OPENING = 'lf_screen_opening',
    CANTILEVER_CLOSURE = 'cantilever_closure',
    LF_CASING = 'lf_casing',
    LF_FIVEDOTFIVE_DECKING = 'L/F 5.5" Decking',
    // Below are bundle names
    'FOUNDATION MATERIAL' = 'FOUNDATION MATERIAL',
    'POSTS & BEAMS' = 'POSTS & BEAMS',
    'BASEMENT BEARING WALL' = 'BASEMENT BEARING WALL',
    'BASEMENT INTERIOR NON-BEARING WALLS' = 'BASEMENT INTERIOR NON-BEARING WALLS',
    'MAIN FLOOR DECK' = 'MAIN FLOOR DECK',
    'MAIN FLOOR EXTERIOR WALLS' = 'MAIN FLOOR EXTERIOR WALLS',
    'MAIN FLOOR INTERIOR WALLS' = 'MAIN FLOOR INTERIOR WALLS',
    'GARAGE MATERIAL' = 'GARAGE MATERIAL',
    '2ND FLOOR DECK' = '2ND FLOOR DECK',
    '2ND FLOOR EXTERIOR WALLS' = '2ND FLOOR EXTERIOR WALLS',
    '2ND FLOOR INTERIOR WALLS' = '2ND FLOOR INTERIOR WALLS',
    '3RD FLOOR DECK' = '3RD FLOOR DECK',
    '3RD FLOOR EXTERIOR WALLS' = '3RD FLOOR EXTERIOR WALLS',
    '3RD FLOOR INTERIOR WALLS' = '3RD FLOOR INTERIOR WALLS',
    '4TH FLOOR DECK' = '4TH FLOOR DECK',
    '4TH FLOOR EXTERIOR WALLS' = '4TH FLOOR EXTERIOR WALLS',
    '4TH FLOOR INTERIOR WALLS' = '4TH FLOOR INTERIOR WALLS',
    '5TH FLOOR DECK' = '5TH FLOOR DECK',
    '5TH FLOOR EXTERIOR WALLS' = '5TH FLOOR EXTERIOR WALLS',
    '5TH FLOOR INTERIOR WALLS' = '5TH FLOOR INTERIOR WALLS',
    'ROOF FRAMING' = 'ROOF FRAMING',
    'PORCH MATERIAL' = 'PORCH MATERIAL',
    'SCREEN PORCH' = 'SCREEN PORCH',
    'SUNROOM' = 'SUNROOM',
    'DECK' = 'DECK',
    'HARDWARE' = 'HARDWARE',
}

// specific sorting for wall group by floor level
export const wallsDrawablesSortOrder = [
    'basement',
    'main floor',
    '2nd floor',
    '3rd floor',
    '4th floor',
    '5th floor',
    'garage',
    'porch wall',
    'sun room',
    'courtyard',
    'other',
    '', // when floor level is empty, keep it in the end
]

// specific sorting for dropped beam group by type and material
export const droppedBeamDrawablesSortOrder = [
    'dropped beam gl',
    'dropped beam lsl',
    'dropped beam psl',
    'dropped beam dimensional lumber',
    'dropped beam lvl',
    'dropped beam', // dropped beam without material go to the end
    'flush beam gl',
    'flush beam lsl',
    'flush beam psl',
    'flush beam dimensional lumber',
    'flush beam lvl',
    'flush beam', // flush beam without material go to the end
    'steel beam plate',
    'solid beam',
]

// specific sorting for corner group by location
export const cornerDrawablesSortOrder = [
    'outside',
    'inside',
    '', // empty location go to the end
]

// specific sorting for floor truss group by type
export const floorTrussDrawablesSortOrder = ['floor_truss_bracing', 'floor_truss_system']

// specific sorting for eave gable group by name
export const eaveGableDrawablesSortOrder = ['eave length', 'gable length']

// specific sorting for roofing beam group by type
export const roofingBeamDrawablesSortOrder = ['ridge_beam', 'hip_and_valley_beam']

// specific sorting for exterior roofing group by name
export const exteriorRoofingDrawablesSortOrder = [
    'ev',
    'pv',
    'asphalt',
    'metal',
    'ridge l/f',
    'hip',
    'valley',
    'flat roof edge',
    'porch ceiling',
]
