import '@babylonjs/core/Collisions/collisionCoordinator'
import '@babylonjs/core/Culling/ray'
import '@babylonjs/core/Rendering/edgesRenderer'

import { Scene } from '@babylonjs/core/scene'
import isEmpty from 'lodash/isEmpty'

import AbstractToolBox from '../AbstractToolBox'
import * as Tools from './tools'
import { BabylonToolBoxConfig, BabylonToolConfig } from '../../../types'

export class BabylonToolBox extends AbstractToolBox {
    private scene: Scene

    constructor(config: BabylonToolBoxConfig) {
        super(config)
        this.scene = config.scene
        Object.values(Tools)
            .filter((Tool) =>
                config.tools && !isEmpty(config.tools) ? config.tools.includes((Tool as any).NAME) : true
            )
            .forEach(this.set)
    }

    /**
     * Creates and registers a tool
     * @param Tool the tool to create and register
     */
    public set = (Tool: typeof Tools.BabylonTool): void => {
        const config: BabylonToolConfig = { mediator: this.mediator, scene: this.scene }
        const tool = new Tool(config)

        this.registerTool(tool)
    }
}

export default BabylonToolBox
