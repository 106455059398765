import { createAction } from '@reduxjs/toolkit'
import { call, fork, put, takeLatest } from 'redux-saga/effects'

import { recalculateMeasurementsByProjectId } from '../../../api/projects-api'
import { preparedDrawablesFailure } from '../../slices/2D'
import { requestCompleted, requestPending } from '../../slices/loading'
import { prepareMarkupData } from '../2D/data-prep/prepareMarkupData'

export const recalculateMeasurements = createAction<number>('recalculateMeasurements')

export function* handleRecalculateMeasurements(action: ReturnType<typeof recalculateMeasurements>) {
    try {
        yield put(requestPending())

        yield call(recalculateMeasurementsByProjectId, action.payload)

        yield put(requestCompleted())

        yield fork(prepareMarkupData, action)
    } catch (error) {
        yield put(preparedDrawablesFailure((error as any).message))
    }
}

export function* watchForRecalculateMeasurements() {
    yield takeLatest(recalculateMeasurements.type, handleRecalculateMeasurements)
}
