import { MeasurementsToUpdate } from '../../../imup/types'
import { DRAWABLE_TYPES } from '../../../shared/constants/drawable-types'

/**
 * Calculates Custom Business Measurements based on material type
 * @param measurements
 * @param type
 * @returns measurements the result of business formula
 */
export function calculateCustomMeasurements(
    measurements: MeasurementsToUpdate,
    type?: DRAWABLE_TYPES
): MeasurementsToUpdate {
    switch (type) {
        case DRAWABLE_TYPES.UNDER_VALLEY_SHEATHING:
            measurements.quantity = measurements.count ? measurements.count * 6 : 0

            return measurements
        default:
            return measurements
    }
}
