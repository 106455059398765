import { put } from 'redux-saga/effects'

import ModelUtils from '../../../components/IMUP3DComponent/babylon/ModelUtils'
import { updateJunctions } from '../../../slices/3D'
import {
    FloorJunctionTypes,
    IBabylonLineWithMetadata,
    IFloorJunctionData,
    IRoofJunctionData,
    IVector,
    JunctionRawInput,
    ModelType,
    RevitIdToConfigIdMap,
    ROOF_STOREY_NAME,
    ThreeDToTwoDRecord,
} from '../../../types'

export function* prepareJunctions(
    lineResponses: JunctionRawInput,
    threeDToTwoDMappings: ThreeDToTwoDRecord,
    geometriesRevitIdToConfigIdMap: RevitIdToConfigIdMap
) {
    const lineDictionary: Record<string, IBabylonLineWithMetadata> = {}
    const floorJunctionsRawInput = lineResponses['floorJunctions']
    const roofJunctionsRawInput = lineResponses['roofJunctions']

    if (floorJunctionsRawInput) {
        yield Object.keys(floorJunctionsRawInput).forEach((id) => {
            const parsedFloorJunctionData: IFloorJunctionData = JSON.parse(floorJunctionsRawInput[id].data)
            const isInterior: boolean = parsedFloorJunctionData.junctionType !== FloorJunctionTypes.SIDING
            const path: IVector[] = []

            parsedFloorJunctionData.junctionEdges.forEach((edge) => {
                path.push(
                    ...[
                        { x: edge.point1.x, y: edge.point1.y, z: -edge.point1.z },
                        { x: edge.point2.x, y: edge.point2.y, z: -edge.point2.z },
                    ]
                )
            })
            lineDictionary[parsedFloorJunctionData.customRevitId] = {
                path,
                metaData: {
                    isReflectedInTwoD: !!threeDToTwoDMappings[parsedFloorJunctionData.customRevitId],
                    ids: threeDToTwoDMappings[parsedFloorJunctionData.customRevitId]
                        ? threeDToTwoDMappings[parsedFloorJunctionData.customRevitId]
                        : [],
                    isInterior,
                    storeyName: '',
                    modelType: ModelType.JUNCTION,
                    configuration_id:
                        geometriesRevitIdToConfigIdMap[
                            ModelUtils.extractGeometryIDFromBaseId(parsedFloorJunctionData.customRevitIdBase)
                        ],
                },
            }
        })
    }

    if (roofJunctionsRawInput) {
        yield Object.keys(roofJunctionsRawInput).forEach((id) => {
            const parsedRoofJunctionData: IRoofJunctionData = JSON.parse(roofJunctionsRawInput[id].data)
            const path = [
                {
                    x: parsedRoofJunctionData.point1.x,
                    y: parsedRoofJunctionData.point1.y,
                    z: -parsedRoofJunctionData.point1.z,
                },
                {
                    x: parsedRoofJunctionData.point2.x,
                    y: parsedRoofJunctionData.point2.y,
                    z: -parsedRoofJunctionData.point2.z,
                },
            ]

            lineDictionary[parsedRoofJunctionData.customRevitId] = {
                path,
                metaData: {
                    isReflectedInTwoD: !!threeDToTwoDMappings[parsedRoofJunctionData.customRevitId],
                    ids: threeDToTwoDMappings[parsedRoofJunctionData.customRevitId]
                        ? threeDToTwoDMappings[parsedRoofJunctionData.customRevitId]
                        : [],
                    isInterior: false,
                    storeyName: ROOF_STOREY_NAME,
                    modelType: ModelType.JUNCTION,
                    configuration_id:
                        geometriesRevitIdToConfigIdMap[
                            ModelUtils.extractGeometryIDFromBaseId(parsedRoofJunctionData.customRevitIdBase)
                        ],
                },
            }
        })
    }

    yield put(updateJunctions(lineDictionary))
}

export function* updateJunctionsMetadata(
    junctions: Record<string, IBabylonLineWithMetadata>,
    threeDToTwoDMappings: ThreeDToTwoDRecord
) {
    const updatedJunctionsData: Record<string, IBabylonLineWithMetadata> = {}

    Object.keys(junctions).forEach((id) => {
        updatedJunctionsData[id] = {
            ...junctions[id],
            metaData: { ...junctions[id]['metaData'], ids: threeDToTwoDMappings[id] ? threeDToTwoDMappings[id] : [] },
        }
    })

    yield put(updateJunctions(updatedJunctionsData))
}
