import { FormValidation, UiSchema } from '@rjsf/core'

import { FormProperties } from '../imup/types'
import { validateGivenFieldsAreFractions } from './validation/fractionValidation'

export const uiSchema: UiSchema = {
    schedule_code: { 'ui:title': 'Schedule Code' },
    type: { 'ui:title': 'Type' },
    shape: { 'ui:title': 'Shape' },
    measurement_type: { 'ui:title': 'Measurement Type' },
    door_wall_depth: { 'ui:title': 'Wall Depth' },
    wall_depth_other: { 'ui:title': 'Other Wall Depth' },
    grilles: { 'ui:title': 'Grilles', 'ui:field': 'RadioButtons', 'ui:options': { inline: true } },
    number_wide: { 'ui:title': 'Number Wide' },
    number_high: { 'ui:title': 'Number High' },
    configuration: { 'ui:title': 'Configuration' },
    operation: { 'ui:title': 'Operation' },
    manual_width: { 'ui:title': 'Width' },
    manual_height: { 'ui:title': 'Height' },
    tempered: { 'ui:title': 'Tempered' },
    egress: { 'ui:title': 'Egress' },
    discrepancy: { 'ui:title': 'Discrepancy Exists?', 'ui:field': 'RadioButtons', 'ui:options': { inline: true } },
    discrepancy_comment: { 'ui:title': 'Discrepancy Comment' },
    remarks: { 'ui:title': 'Remarks' },
    'ui:order': [
        'schedule_code',
        'type',
        'shape',
        'measurement_type',
        'wall_depth',
        'wall_depth_other',
        'grilles',
        'number_wide',
        'number_high',
        'configuration',
        'operation',
        'manual_width',
        'manual_height',
        'tempered',
        'egress',
        'discrepancy',
        'discrepancy_comment',
        'remarks',
    ],
}

const onValidate = (formData: any, errors: FormValidation) => {
    validateGivenFieldsAreFractions(['wall_depth_other', 'manual_width', 'manual_height'], formData, errors)
}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }

    newSettings.isWidthManual = true
    newSettings.isHeightManual = true
    newSettings.quantity = (parseFloat(newSettings.manual_width) * parseFloat(newSettings.manual_height)).toFixed(2)

    if (formData.discrepancy === 'false') {
        newSettings.discrepancy_comment = null
    }
    if (formData.wall_depth !== 'other') {
        newSettings.wall_depth_other = null
    }

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
