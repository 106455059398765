import { createAction } from '@reduxjs/toolkit'
import { all, call, put, takeEvery } from 'redux-saga/effects'

import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Flag, Workspace } from '../../lib/toolBoxes/2D'
import { setTaskEditMode, toggleFlagForm } from '../../slices/materialFlags'

export const closeFlagFormAction = createAction('closeFlagForm')

export function* handleCloseFlagForm() {
    try {
        const manager: PaperManager | null = yield call(managers.get2DManager)

        if (!manager) return

        const workspaceTool: Workspace = yield call(manager.getTool, Workspace.NAME)

        // close the form
        yield put(toggleFlagForm({ toggleForm: false }))

        yield put(setTaskEditMode({ isEditMode: false }))

        // get temporary flag items
        const temporaryFlagItems: paper.Item[] = yield call(
            workspaceTool.getItemsWithCriteria,
            'data',
            (data) => data?.toolName === Flag.NAME && data?.isTemporaryGroup
        )

        // remove flags
        yield all(temporaryFlagItems.map((item) => item.remove()))
    } catch (error) {
        yield call(console.error, error)
    }
}

export function* watchForCloseFlagForm() {
    yield takeEvery(closeFlagFormAction.type, handleCloseFlagForm)
}
