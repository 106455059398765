import { call, select } from 'redux-saga/effects'

import { getFormByType } from '../effects/getFormByType'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { selectDrawablesToCreateByTool } from '../../slices/2D'
import { setActiveToolObjectId } from '../../slices/tools'

export function* setActiveToolAndLoadSchema() {
    const manager: PaperManager = yield call(managers.get2DManager)

    if (!manager) return

    const toolIdsToCreate = yield select(selectDrawablesToCreateByTool)

    yield call(getFormByType, { type: setActiveToolObjectId.type, payload: toolIdsToCreate[0] })
}
