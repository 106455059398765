import isFinite from 'lodash/isFinite'

/**
 * Corrects the area of the roof using the pitch
 * (2 sig figs)
 * @param pitch the roof pitch
 * @param rawArea thr raw area of the roof elements
 * @param isOverheadProjection if the projected area is the horizontal or vertical projection
 * @returns the corrected roof area
 */
export function correctAreaUsingPitch(pitch: number, rawArea: number, isOverheadProjection: boolean): number {
    if (!isFinite(pitch)) return rawArea

    if (!isOverheadProjection && pitch === 0) return rawArea

    const rise = pitch
    const run = 12
    const pitchFactor = Math.sqrt(rise * rise + run * run) / (isOverheadProjection ? run : rise)

    return parseFloat((rawArea * pitchFactor).toFixed(2))
}

/**
 * Correcting the length of the hip/valley elements
 * using the pitch of the roof (2 sig figs)
 * @param pitches the pitches of the roofs joined by the hip/valley
 * @param rawLF the raw linear feet
 * @returns the modified hip/valley length
 */
export function correctHipValleyLFUsingGivenPitch(pitches: [number, number], rawLF: number): number {
    if (pitches.some((pitch) => !isFinite(pitch) || (isFinite(pitch) && pitch === 0))) return rawLF

    // Based on the fact that runs of either side of the triangle are
    // based on pitches of the roof anf the angle of the resulting triangle
    // is the base of the pitch
    // the final rise is the LCM of the two pitches
    // based on https://www.spikevm.com/calculators/framing/roof-factors.php
    // and https://roofonline.com/roof-pitch/hip-and-valley-factor-table/
    // and https://www.finehomebuilding.com/forum/roof-framing-hip-joining-two-differ
    // we cannot use the hip and valley factor directly
    // as that is uses the horizontal run
    // the raw length is the angled run not taking into account
    // the pitch so we must project the length and that is
    // done via trig identities
    const inchesPerFoot = 12
    const hipValleyRun = (inchesPerFoot / pitches[1]) * Math.sqrt(pitches[1] * pitches[1] + pitches[0] * pitches[0])
    const unitRise = (pitches[0] * pitches[1]) / Math.sqrt(pitches[1] * pitches[1] + pitches[0] * pitches[0])
    const resultingPitch = (unitRise * hipValleyRun) / inchesPerFoot
    const hipSlope = Math.atan(resultingPitch / hipValleyRun)

    return parseFloat((rawLF / Math.cos(hipSlope)).toFixed(2))
}

/**
 * Returns L/F incorporating pitch assuming that the measured
 * length in IMUP is the flat run
 * @param pitch the roof pitch
 * @param rawLF the raw LF
 * @returns the linear footage with the pitch taken into account
 */
export function correctRoofLFUsingPitch(pitch: number, rawLF: number): number {
    if (!isFinite(pitch)) return rawLF

    return parseFloat((rawLF / Math.cos(Math.atan(pitch / 12))).toFixed(2))
}
