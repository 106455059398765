import { ActionReducerMapBuilder, createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { FETCH_PROJECT_SUCCESS } from '../../actions'
import { Project } from '../../models/project'
import { RootState } from '../../stores'

export const gotProjectAction = createAction<Project>(FETCH_PROJECT_SUCCESS)

type ProjectState = {
    id: number | null
    project: Project | null
}

export const initialProjectState: ProjectState = {
    id: null,
    project: null,
}

const handleProject = (state: ProjectState, { payload }: PayloadAction<Project>) => {
    state.project = payload
}

const extraReducers = (builder: ActionReducerMapBuilder<ProjectState>): void => {
    builder.addCase(gotProjectAction, handleProject)
}

const projectSlice = createSlice({
    name: 'project',
    initialState: initialProjectState,
    reducers: {
        selectProjectWithId(state, action: PayloadAction<number>) {
            state.id = action.payload
        },
    },
    extraReducers,
})

export const selectProjectId = createSelector(
    (state: RootState) => state.IMUP.project.id,
    (id) => id
)

export const selectProjectStatusName = createSelector(
    (state: RootState) => state.IMUP.project.project?.projectStatus.name,
    (projectStatusName) => projectStatusName
)

export const { selectProjectWithId } = projectSlice.actions

export default projectSlice
