import { Coordinate } from '../../models/activeDrawable'
import { DRAWING_TYPES } from '../../shared/constants/drawable-types'
import { NUM_OF_POINTS_FOR_RADIUS_LINES } from '../../shared/constants/material-cal-needs'

/**
 * Function builds Radius Line coordinates from Paper.Path
 * @param path
 * @returns Array of Radius Line coordinates in the followind order: start, center, middle
 */
export const buildRadiusLineCoordinatesFromPath = (path: paper.Path): Coordinate[] => {
    const coordinates: Coordinate[] = []
    const start = path.getPointAt(0)
    const end = path.getPointAt(path.length)
    const center = path.getPointAt(path.length / 2)

    coordinates.push([start.x, start.y])
    coordinates.push([center.x, center.y])
    coordinates.push([end.x, end.y])

    return coordinates
}
/**
 * Function builds Digitizer Coordinates array of coordinates basen on Paper.Js data,
 * and Drawing Type (Point/Radius/Section/Area)
 * @param item paper.Item
 * @param path paper.Path
 * @returns Coordinates array | Coordinate[]
 */
export const buildCoordinatesFromPaperItem = (item: paper.Item, path: paper.Path): Coordinate[] => {
    if (item.data.shapeType === DRAWING_TYPES.POINT) {
        return [item.position].map((position) => [position.x, position.y])
    } else if (item.data.shapeType === DRAWING_TYPES.RADIUS) {
        return buildRadiusLineCoordinatesFromPath(path)
    }

    return path.segments.map((segment) => [segment.point.x, segment.point.y])
}

/**
 * Function returns array of multiple points to represent radius-line
 * NUM_OF_POINTS_FOR_RADIUS_LINES is the default amount of points returned, or amount can be specified
 *
 * Logic is used to support Material Calc Model
 * @param path
 * @param amountOfPoints
 * @returns Coordinate[]
 */
export const getMultipleArcCoordinatesFromPath = (path: paper.Path, amountOfPoints?: number): Coordinate[] => {
    const numOfPoints = amountOfPoints ?? NUM_OF_POINTS_FOR_RADIUS_LINES

    return Array.from({ length: numOfPoints }, (_, i) => {
        const position = i / (numOfPoints - 1)
        const point = path.getPointAt(path.length * position)

        return [point.x, point.y]
    })
}
