import { PathTool } from '../path/Path.tool'
import { Coordinates2D, PaperToolConfig } from '../../../../../types'

export class PolygonTool extends PathTool {
    static NAME = 'POLYGON'

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = PolygonTool.NAME
    }

    public createPolygon = (coordinates: Coordinates2D) => {
        const path = this.createPath(coordinates)

        path.closed = true

        return path
    }
}
