import isNull from 'lodash/isNull'
import { call, put, takeLatest } from 'redux-saga/effects'

import { SET_ACTIVE_FLOOR } from '../../../actions/drawable'
import { PROJECT_PAGE_TAB_VALUES } from '../../../shared/constants/project-settings-names'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { setOverlayChunkId } from '../../slices/2D'
import { setActiveTabOnProjectPage } from '../../slices/navigation'

export function* handleResetActiveOverlayOnPageChange(triggerAction) {
    // Short circuit if changing tab to anything BUT digitizer
    if (
        triggerAction.type === 'navigation/setActiveTabOnProjectPage' &&
        triggerAction.payload !== PROJECT_PAGE_TAB_VALUES['DIGITIZER']
    ) {
        return
    }

    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (isNull(manager)) return

    yield put(setOverlayChunkId(null))
}

export function* watchForPageChangeAndResetOverlay() {
    // Watches for change of plan page
    yield takeLatest(SET_ACTIVE_FLOOR, handleResetActiveOverlayOnPageChange)
    // Watches for tab change to Digitizer
    yield takeLatest(setActiveTabOnProjectPage, handleResetActiveOverlayOnPageChange)
}
