import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PROJECT_PAGE_TAB_VALUES } from '../../shared/constants/project-settings-names'

type NavigationSliceState = {
    activeTab: PROJECT_PAGE_TAB_VALUES
}

export const initialNavigationState: NavigationSliceState = {
    activeTab: PROJECT_PAGE_TAB_VALUES.HISTORY,
}

const navigationSlice = createSlice({
    name: 'navigation',
    initialState: initialNavigationState,
    reducers: {
        setActiveTabOnProjectPage(state, action: PayloadAction<PROJECT_PAGE_TAB_VALUES>) {
            state.activeTab = action.payload
        },
    },
})

export const { setActiveTabOnProjectPage } = navigationSlice.actions

export default navigationSlice
