import { distinctUntilChanged, map, takeWhile } from 'rxjs'

import PaperTool from '../paperTool/PaperTool'
import { Coordinate } from '../../../../../../models/activeDrawable'
import { DRAWING_TYPES } from '../../../../../../shared/constants/drawable-types'
import { initialToolsState, ToolsState } from '../../../../../slices/tools'
import { Cursors, IMUPState, ItemScale, PaperToolConfig, VIEW_MODE } from '../../../../../types'
/**
 * Point.tool.ts
 * Creates a small point (circle) on mouse click
 */
export class Count extends PaperTool {
    static NAME = 'COUNT'
    static CURSOR = Cursors.CROSSHAIR

    private color: ToolsState['color'] = initialToolsState.color
    private opacity: ToolsState['pointOpacityValue'] = initialToolsState.pointOpacityValue

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = Count.NAME
        this.cursor = Count.CURSOR

        this.mediator
            .get$()
            .pipe(
                takeWhile(({ common: { activeMode } }: IMUPState) => activeMode === VIEW_MODE.Markup2D),
                map(({ tools: { pointOpacityValue: opacity, color } }: IMUPState) => ({
                    opacity,
                    color,
                })),
                distinctUntilChanged()
            )
            .subscribe(({ color, opacity }) => {
                this.color = color
                this.opacity = opacity
            })
    }

    private renderPoint = (center: paper.Point, pointRadius?: number): paper.Path => {
        const point = new this.paper.Path.Circle({
            center,
            radius: this.maxImageDimension * ItemScale.POINT,
        })

        point.data.shapeType = DRAWING_TYPES.POINT
        point.strokeWidth = 0 // remove the stroke width for the points

        return point
    }

    /**
     * Create a paper point (path) using the provided
     * coordinates
     * @param point the x and y coords to create the new point
     * @param point.x the x coord to create the point at
     * @param point.y the y coord to create the point at
     * @param radius (optional) radius of the point to be created
     * @returns the created paper point (path)
     */
    public createPoint = ([x, y]: Coordinate, radius?: number): paper.Path =>
        this.renderPoint(new this.paper.Point(x, y), radius)

    /**
     * On mouse down if right or middle then change to
     * dragging cursor, otherwise create a point
     * @param event the paper mouse event
     * @returns
     */
    public onMouseDown = (event: paper.ToolEvent): void => {
        if (this.isPanningClick(event)) return

        const newPoint = this.renderPoint(event.point)

        newPoint.fillColor = new this.paper.Color(this.color)
        newPoint.opacity = this.opacity
        this.setState('2D', { drawablesToCreate: [newPoint.id] })
    }

    /**
     * On mouse up change the cursor back to the default
     * of the tool
     */
    onMouseUp = () => {
        this.setState('common', { cursor: this.cursor })
    }

    /**
     * On mouse drag if it is right click
     * or middle click then pan
     * @param event the paper mouse event
     * @returns
     */
    onMouseDrag = (event) => {
        if (this.toolPanning(event)) return

        // remove the last child which is the previous path that is no longer relevant
        this.paper.project.activeLayer.lastChild.position = event.point
    }
}

export default Count
