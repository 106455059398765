import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import { call, delay, put, select } from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'

import { selectProject } from './createDrawableLocation'
import { convertOpeningsWithGroupIdsToDrawableLocations } from './data-prep/convertOpeningsToDrawableLocations'
import { createOpening, createOpeningGroupMaterial, CreateOpeningResponse } from '../../../api/projects-api'
import { ActiveDrawable, Coordinate } from '../../../models/activeDrawable'
import { ActiveFloor } from '../../../models/activeFloor'
import { Project } from '../../../models/project'
import { Building } from '../../../models/projectBuilding'
import { DRAWABLE_TYPES } from '../../../shared/constants/drawable-types'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { PathTool, Workspace } from '../../lib/toolBoxes/2D'
import { clearHighlightsToCreate, createHighlight, insertDrawableLocation } from '../../slices/2D'
import { selectActiveBuilding } from '../../slices/buildings'
import { updateToolbarMessage } from '../../slices/common'
import { selectDrawableActiveFloor } from '../../slices/documents'
import { selectHighlightColor } from '../../slices/tools'
import {
    DRAWABLE_UNITS_OF_MEASURE,
    IMUP2DDrawableLocation,
    MeasurementsToUpdate,
    OpeningWithGroupId,
    ROUNDED_CORNER_SIZE_IN_PX,
    SAVING_MESSAGE,
    TOOLBAR_MESSAGE_TIMER,
} from '../../types'

export function* createHighlights2D({ payload }: ReturnType<typeof createHighlight>) {
    const manager: PaperManager = yield call(managers.get2DManager)
    const selectedHighlightColor: string = yield select(selectHighlightColor)

    if (!manager) return

    const workspaceTool: Workspace = yield call(manager.getTool, Workspace.NAME)
    const highlightPaperItem: paper.Path | null = yield call(workspaceTool.getItemWithPaperId, payload)

    if (!highlightPaperItem) return

    try {
        const activeFloor: ActiveFloor | null = yield select(selectDrawableActiveFloor)
        const activeBuilding: Building | undefined = yield select(selectActiveBuilding)
        const project: Project = yield select(selectProject)

        yield put(updateToolbarMessage(SAVING_MESSAGE))

        // Bail and delete highlight group if missing data
        if (isNull(activeFloor) || isUndefined(activeBuilding) || !project.id) {
            throw Error('Missing required data to create highlight area')
        }

        const rcm_id = yield call(uuidv4)

        // Create new highlight group
        const newHighlightGroup: ActiveDrawable | undefined = yield call(
            createOpeningGroupMaterial,
            project.id,
            undefined,
            {
                name: `${DRAWABLE_TYPES.HIGHLIGHTER}_group`,
                rcm_building_id: activeBuilding.rcm_id,
                rcm_id,
                color: selectedHighlightColor.replace('#', ''),
            },
            DRAWABLE_TYPES.HIGHLIGHTER
        )

        if (isUndefined(newHighlightGroup)) {
            // Something went wrong with creating the group remove the
            // item
            yield call(highlightPaperItem.remove.bind(highlightPaperItem))

            return
        }

        const activeFloorHash = activeFloor.hash
        const projectId = project.id
        const documentChunkId = activeFloor.document_chunk.id
        const path = highlightPaperItem as paper.Path

        const coordinates: Coordinate[] = yield path.segments.map((segment) => [segment.point.x, segment.point.y])

        const measurements: MeasurementsToUpdate = {
            quantity: null,
            linear_total: null,
            area: null,
        }

        const drawable: CreateOpeningResponse = yield call(
            createOpening,
            projectId,
            activeFloorHash,
            newHighlightGroup.id,
            coordinates,
            documentChunkId,
            measurements,
            {
                unit_of_measure: DRAWABLE_UNITS_OF_MEASURE.area,
            },
            null
        )

        const drawableWithGroupId: OpeningWithGroupId = {
            ...drawable,
            groupId: newHighlightGroup.id,
        }

        const drawableLocations: IMUP2DDrawableLocation[] = yield call(convertOpeningsWithGroupIdsToDrawableLocations, [
            drawableWithGroupId,
        ])

        const drawableLocation = drawableLocations[0]

        path.data.drawable_id = drawableLocation.drawable_id
        path.data.opening_group_id = drawableLocation.opening_group_id
        path.data.opening_location_id = drawableLocation.opening_location_id

        const pathTool: PathTool = yield call(manager.getTool, PathTool.NAME)

        yield call(pathTool.roundPath, path, ROUNDED_CORNER_SIZE_IN_PX)

        // Add new drawable to store
        yield put({ type: insertDrawableLocation.type, payload: drawableLocation })
        yield put(updateToolbarMessage(null))
        yield put(clearHighlightsToCreate())
    } catch (error) {
        yield call(highlightPaperItem.remove.bind(highlightPaperItem))
        yield put(updateToolbarMessage('Error creating highlighted area'))

        yield call(console.error, error)

        yield delay(TOOLBAR_MESSAGE_TIMER)
        yield put(updateToolbarMessage(null))
    }
}
