import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import isBoolean from 'lodash/isBoolean'

import { AzureFeatureFlag } from '../../models/azureFeatureFlags'
import { localStorageItem } from '../../models/localStorageItem'
import { RootState } from '../../stores'

export type FlagsState = {
    newRendererEnabled: boolean
    alphaEnabled: boolean
    nightlyEnabled: boolean
    azureFeatureFlags: AzureFeatureFlag[] | null
    isTestingEnabled: boolean
    isTestingEnableExists: boolean
}

export const initialFlagState: FlagsState = {
    // Till https://portal.myparadigm.com/browse/BLUEPRINTS-8160 is completed
    // we do not want to support old IMUP
    newRendererEnabled: true,
    nightlyEnabled: window.localStorage.getItem(localStorageItem.NIGHTLY_STORAGE_KEY) === 'true',
    alphaEnabled: false,
    azureFeatureFlags: null,
    isTestingEnabled: localStorage.getItem(localStorageItem.IS_TESTING_ENABLED) === 'true',
    // should be added in local storage only, do not have functionality to toggle it from FE
    isTestingEnableExists: !!localStorage.getItem(localStorageItem.IS_TESTING_ENABLED),
}

function handleUpdateFlag(
    state: FlagsState,
    {
        payload: { newRendererEnabled, nightlyEnabled, alphaEnabled, isTestingEnabled },
    }: PayloadAction<Partial<FlagsState>>
) {
    if (isBoolean(newRendererEnabled)) {
        state.newRendererEnabled = newRendererEnabled
        window.localStorage.setItem(localStorageItem.NEW_IMUP_STORAGE_KEY, JSON.stringify(newRendererEnabled))
    }

    if (isBoolean(nightlyEnabled)) {
        state.nightlyEnabled = nightlyEnabled
        window.localStorage.setItem(localStorageItem.NIGHTLY_STORAGE_KEY, JSON.stringify(nightlyEnabled))
    }

    if (isBoolean(alphaEnabled)) {
        state.alphaEnabled = alphaEnabled
    }

    if (isBoolean(isTestingEnabled)) {
        state.isTestingEnabled = isTestingEnabled
        window.localStorage.setItem(localStorageItem.IS_TESTING_ENABLED, JSON.stringify(isTestingEnabled))
    }
}

function handleSetAzureFeatureFlags(state: FlagsState, { payload }: PayloadAction<AzureFeatureFlag[]>) {
    state.azureFeatureFlags = payload
}

const flagsSlice = createSlice({
    name: 'flags',
    initialState: initialFlagState,
    reducers: {
        updateFlags: handleUpdateFlag,
        setAzureFeatureFlags: handleSetAzureFeatureFlags,
    },
})

export const selectFeatures = createSelector(
    (state: RootState) => ({
        flags: state.IMUP.flags,
    }),
    (state) => ({
        newRendererEnabled: state.flags.newRendererEnabled,
        nightlyEnabled: state.flags.nightlyEnabled,
        isTestingEnabled: state.flags.isTestingEnabled,
        isTestingEnabledExists: state.flags.isTestingEnableExists,
    })
)

export const selectAzureFeatureFlags = createSelector(
    (state: RootState) => state.IMUP.flags.azureFeatureFlags,
    (azureFeatureFlags) => azureFeatureFlags
)

export const { updateFlags, setAzureFeatureFlags } = flagsSlice.actions

export default flagsSlice
