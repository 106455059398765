import { put } from 'redux-saga/effects'

import { GeometryInputMap, GeometryResultMap } from './prepare3DModel'
import { transformGeometryVertices } from './transformGeometryVertices'
import ModelUtils from '../../../components/IMUP3DComponent/babylon/ModelUtils'
import { updateStructureGeometries } from '../../../slices/3D'
import { IModel, ModelType, ThreeDToTwoDRecord } from '../../../types'

const GEOMETRY_UVS_NODE_PROPERTY = 'GeometryUVs'

/**
 * Prepare a geometry object
 * @param geometries
 * @param modelNodes the structural model nodes organized by storey
 * @param threeDToTwoDMappings mappings between 2D items and 3D meshes
 * @returns geometry object
 */
export const prepareGeometryMetadata = (
    geometries: GeometryInputMap = {},
    modelNodes: IModel[] = [],
    threeDToTwoDMappings: ThreeDToTwoDRecord = {}
): GeometryResultMap =>
    modelNodes.reduce((geometryMap, model) => {
        if (!model.geometryChecksum) return geometryMap

        const geometry = { ...geometries[model.geometryChecksum] }
        const modelId = threeDToTwoDMappings[model.guid] ? model.guid : model.splitFromElementGuid || model.guid

        if (geometry) {
            const metaData = {
                isReflectedInTwoD: !!threeDToTwoDMappings[modelId],
                modelType: model.type.toUpperCase(),
                ids: threeDToTwoDMappings[modelId] || [],
                storeyName: model.storeyName,
                isInterior: model.interior,
                configuration_id: ModelUtils.generateConfigurationIdFromModel(model),
                isLandscaping:
                    model.type.toUpperCase() === ModelType.SLAB && model.family.toUpperCase() === ModelType.GRASS,
            }

            geometry.name = model.name
            geometry.metaData = metaData

            // Add meta data
            if (model.geometryMatrix && geometry.vertices.length) {
                geometry.vertices = transformGeometryVertices(geometry, model.geometryMatrix)
            }

            // Add UVs to geometry
            if (model.properties[GEOMETRY_UVS_NODE_PROPERTY]) {
                geometry.uvs = model.properties[GEOMETRY_UVS_NODE_PROPERTY]!.value
            }

            // Update geometry map
            geometryMap[modelId] = geometryMap[modelId] || []
            geometryMap[modelId].push(geometry)
        }

        return geometryMap
    }, {})

export function* updateGeometryMetadataIds(geometries: GeometryResultMap, threeDToTwoDMappings: ThreeDToTwoDRecord) {
    const newGeometryMap: GeometryResultMap = {}

    Object.keys(geometries).forEach((modelId) => {
        const updatedGeometries = geometries[modelId].map((geometry) => {
            return {
                ...geometry,
                metaData: {
                    ...geometry['metaData'],
                    ids: threeDToTwoDMappings[modelId] ? threeDToTwoDMappings[modelId] : [],
                },
            }
        })

        newGeometryMap[modelId] = updatedGeometries
    })
    yield put(updateStructureGeometries(newGeometryMap))
}
