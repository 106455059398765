import { UiSchema } from '@rjsf/core'
import isNull from 'lodash/isNull'

import { AzureFeatureFlag } from '../../../models/azureFeatureFlags'
import { AzureFeatureFlagIds } from '../../constants/azure-feature-flags'

/**
 * By list of received azure feature flags, check if a current flag exists and if it's enabled
 *
 * @param azureFeatureFlags
 * @param azureFeatureFlagIds
 */
export const isAzureFeatureFlagEnabled = (
    azureFeatureFlags: AzureFeatureFlag[] | null,
    azureFeatureFlagIds: AzureFeatureFlagIds
): boolean => {
    if (isNull(azureFeatureFlags)) return false

    const foundAzureFeatureFlag: AzureFeatureFlag | undefined = azureFeatureFlags.find(
        (flag) => flag.id === azureFeatureFlagIds
    )

    return foundAzureFeatureFlag?.id ? foundAzureFeatureFlag.enabled : false
}

/**
 * Updates the uiSchema based on Azure feature flags.
 * If a field has a `ui:featureFlag` and `ui:featureFlagUiSchema` set,
 * it checks whether the feature flag is enabled.
 * If the flag is enabled,
 * it uses the configuration in `ui:featureFlagUiSchema`, otherwise it retains the original field.
 *
 * @param {UiSchema} uiSchema - The UI schema to modify
 * @param {AzureFeatureFlag[] | null} azureFeatureFlags - List of active Azure feature flags
 * @returns {UiSchema} - The updated UI schema based on feature flags
 */
export const prepareUiSchemaBasedOnAzureFeatureFlag = (
    uiSchema: UiSchema,
    azureFeatureFlags: AzureFeatureFlag[] | null
): UiSchema => {
    return Object.keys(uiSchema).reduce((updatedUiSchema, uiSchemaKey) => {
        const field = uiSchema[uiSchemaKey]
        const featureFlag = field?.['ui:featureFlag']
        const featureFlagUiSchema = field?.['ui:featureFlagUiSchema']

        // if there's no feature flag or specific feature flag logic, retain original field
        if (!featureFlag || !featureFlagUiSchema) {
            updatedUiSchema[uiSchemaKey] = field

            return updatedUiSchema
        }

        // check if the feature flag is enabled
        const isFeatureFlagEnabled = isAzureFeatureFlagEnabled(azureFeatureFlags, featureFlag)

        // if the feature flag is disabled, retain the original field
        if (!isFeatureFlagEnabled) {
            updatedUiSchema[uiSchemaKey] = field

            return updatedUiSchema
        }

        // if the feature flag is enabled, use the 'ui:featureFlagUiSchema' configuration
        updatedUiSchema[uiSchemaKey] = featureFlagUiSchema

        return updatedUiSchema
    }, {})
}
