import isNull from 'lodash/isNull'
import { all, call, put } from 'redux-saga/effects'

import { deleteProjectContextMarkups } from '../../../api/projects-api'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Select } from '../../lib/toolBoxes/2D'
import WorkspaceTool from '../../lib/toolBoxes/2D/tools/workspace/Workspace.tool'
import { setAltSelectedItems } from '../../slices/2D'
import { clearActiveForm } from '../../slices/forms'
import { removeToolObjectsByToolId, setActiveToolObjectId } from '../../slices/tools'

export function* deleteToolObject(items: paper.Item[]) {
    try {
        const manager: PaperManager = yield call(managers.get2DManager)

        if (!manager) return

        const selectTool = yield call(manager.getTool, Select.NAME)
        const workspaceTool = yield call(manager.getTool, WorkspaceTool.NAME)

        const contextMarkupIds: number[] = items.map((item) => item.data.toolObject.id)

        yield call(deleteProjectContextMarkups, contextMarkupIds)

        const itemsToDelete: paper.Item[] | null = yield call(workspaceTool.getItemsWithCriteria, 'data', (data) =>
            contextMarkupIds.includes(data?.toolObject?.id)
        )

        if (isNull(itemsToDelete)) return

        // remove items from blueprint
        yield all(itemsToDelete.map((item) => call(item.remove.bind(item))))

        // remove items from store
        yield put(removeToolObjectsByToolId(contextMarkupIds))

        // unset active tool object to null, because we deleted it
        yield put(setActiveToolObjectId(null))

        yield put(clearActiveForm())

        // reset alt selected items
        yield put(setAltSelectedItems(null))

        // exit selected mode, remove handlers
        yield call(selectTool.exitSelectMode)
    } catch (e) {
        console.log('Error on deleting Tool Object', e)
    }
}
