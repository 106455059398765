import { UiSchema } from '@rjsf/core'

import { FormProperties } from '../imup/types'
import { OpeningGroup } from '../models/activeDrawable'

export const uiSchema: UiSchema = {
    type: {
        'ui:title': 'Canales Type',
        'ui:placeholder': 'Select Canales Type',
    },
    length: {
        'ui:title': 'Length',
        'ui:placeholder': 'Canales Length',
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
}

const onValidate = () => {}

const onSubmit = (settings, formData): OpeningGroup['settings'] => ({
    ...settings,
    ...formData,
})

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
