import { distinctUntilChanged, map, takeWhile } from 'rxjs'

import { PathTool } from '../path/Path.tool'
import { DRAWING_TYPES } from '../../../../../../shared/constants/drawable-types'
import { applyScaleFactorToPathArea } from '../../../../../../utils/calculations/scaleConversion/scaleConversion'
import { initialToolsState, ToolsState } from '../../../../../slices/tools'
import { Cursors, IMUPState, ItemScale, PaperToolConfig, VIEW_MODE } from '../../../../../types'
import addSelectFunctionalityToDrawable from '../../../../utils/functionality-bindings/addSelectFunctionalityToDrawable'

/**
 * Cutout.tool.tsx
 * Creates a corner to corner Cutout upon mouse drag
 */
export class Cutout extends PathTool {
    static NAME = 'CUTOUT'
    static CURSOR = Cursors.CROSSHAIR

    private startingPoint: paper.Point | null = null
    private cutoutPath: paper.Path | null = null

    private color: ToolsState['color'] = initialToolsState.color
    private opacity: ToolsState['areaOpacityValue'] = initialToolsState.areaOpacityValue
    private currentPaperId: number | null = null
    private rectangleId: number | null = null
    private cutoutItemId: number | null = null

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = Cutout.NAME
        this.cursor = Cutout.CURSOR

        this.mediator
            .get$()
            .pipe(
                takeWhile(({ common: { activeMode } }: IMUPState) => activeMode === VIEW_MODE.Markup2D),
                map(({ tools: { areaOpacityValue, cutoutItem } }: IMUPState) => ({
                    areaOpacityValue,
                    cutoutItem,
                })),
                distinctUntilChanged()
            )
            .subscribe(({ areaOpacityValue, cutoutItem }) => {
                this.opacity = areaOpacityValue
                this.cutoutItemId = cutoutItem
            })
    }

    protected draw(color: paper.Color | null, from: paper.Point, to: paper.Point): paper.Path.Rectangle {
        const path = new this.paper.Path.Rectangle(from, to)

        path.strokeColor = color
        path.opacity = this.opacity
        path.fillColor = color
        path.data.shapeType = DRAWING_TYPES.AREA
        path.strokeWidth = 0

        this.currentPaperId = path.id

        return path
    }

    /**
     * Clear the points that are being drawn with the tool
     */
    cancel = () => {
        const rect = this.paper.project.getItem({ id: this.currentPaperId })

        if (rect) rect.remove()
        this.cutoutPath = null
        this.rectangleId = null
        this.startingPoint = null

        this.setState('common', { cursor: this.cursor, tooltip: { title: '', visible: false, color: '#000000' } })
    }

    /**
     * Constructs the cutout item based on the type of the parent.
     *  parent === compound path -> append the cutout path as a child
     *  parent === path          -> create a new compound path with the original parent as the first child, cutouts are following children
     * @param parent
     * @param cutoutPath
     * @returns
     */
    private constructCutoutItem = (parent: paper.Item, cutoutPath: paper.Path): paper.CompoundPath => {
        // The new compound path will have a different ids so these needs to be updated
        this.setState('2D', {
            activeOpeningLocationId: parent.data.opening_location_id,
            activeDrawableId: parent.data.drawable_id,
            openingGroupId: parent.data.opening_group_id,
        })

        if (parent instanceof this.paper.CompoundPath) {
            parent.addChild(cutoutPath)

            return parent
        }
        const path = new this.paper.CompoundPath({
            children: [parent, cutoutPath],
        })

        this.setState('2D', {
            selectedItem: path.id,
        })

        return path
    }

    /**
     * Takes in the shape to be styled and applies the styles from the parent
     * @param shape -> shape to be styled
     * @param parent -> shape to copy styles from
     * @returns
     */
    private styleNewShape = (shape: paper.CompoundPath, referencePath: paper.Item) => {
        const color: paper.Color = referencePath.fillColor ? referencePath.fillColor : referencePath.strokeColor!

        color.alpha = 0.8 // set the opacity value for areas

        shape.strokeColor = color
        shape.strokeWidth = this.scaleFactor * ItemScale.LINE // scale the stroke width to be 4" no matter the resolution (1/3 is used because the scale is feet : canvasUnit)
        shape.fillColor = color
        shape.fillRule = 'evenodd' // this makes it so the children of the compound path are cutout instead of being additive
        shape.strokeWidth = 1 // hide since shape is filled
        shape.children[0].data = null

        return shape
    }

    /**
     * Handles the creation of the cutout rectangle.
     *  1st click -> create the cutout anchor point
     *  2nd click -> create the compound path & apply changes
     *  contraint -> cutout must be within the bound of the parent shape
     */
    onMouseDown = (event: paper.ToolEvent): void => {
        if (this.isPanningClick(event)) return

        let parent = this.paper.project.getItem({ id: this.cutoutItemId })

        if (!parent) return

        // When multiple cutouts is drawn on the same iteration with area, Compound path has to be reassigned
        if (parent?.parent && parent.parent instanceof this.paper.CompoundPath) {
            parent = parent.parent
        }

        // Core assumption here is that the
        // first child is a compound path is
        // the "parent" AKA not a cutout but the base item
        // using this assumption, test whether or not
        // the cutout the user is trying to make
        // intersects or covers any of the current cutouts
        // in the shape, if it is then we do not allow the
        // user to make such a change, this is to address
        // https://portal.myparadigm.com/browse/BLUEPRINTS-10852
        const isNotIntersectingAnExistingCutout =
            !parent.children ||
            !this.cutoutPath ||
            parent.children
                .slice(1)
                .every(
                    (child) =>
                        this.cutoutPath &&
                        !child.intersects(this.cutoutPath as paper.Item) &&
                        !child.isInside(this.cutoutPath?.bounds)
                )

        if (isNotIntersectingAnExistingCutout && this.cutoutItemId && parent.contains(event.point)) {
            if (this.startingPoint && this.cutoutPath) {
                this.startingPoint = null
                const parentDataCopy = { ...parent.data }
                const itemToUpdate = this.constructCutoutItem(parent, this.cutoutPath)

                // reset the rectangle id & cutout path so that it isn't removed from the compound path
                this.rectangleId = null
                this.cutoutPath = null

                this.styleNewShape(itemToUpdate, parent)

                // move the data to the new item
                itemToUpdate.data = parentDataCopy
                itemToUpdate.children[0].data = null

                this.currentPaperId = null
                const dataToUpdate = this.convertPaperItemToAPICoordinateModel(itemToUpdate, this.scaleFactor)
                const updatedCoordinates = dataToUpdate.coordinates
                const updatedCutouts = dataToUpdate.cutouts

                this.setState('2D', {
                    coordinatesToUpdate: {
                        coordinates: updatedCoordinates,
                        measurements: dataToUpdate.measurements,
                        cutouts: updatedCutouts,
                    },
                })

                addSelectFunctionalityToDrawable(itemToUpdate)
            } else {
                this.startingPoint = event.point
                this.draw(new this.paper.Color(this.color), this.startingPoint, this.startingPoint)
            }
        } else if (!isNotIntersectingAnExistingCutout && this.startingPoint && this.cutoutPath) {
            this.cutoutPath.remove()
            this.startingPoint = null
            this.rectangleId = null
            this.cutoutPath = null
        }
    }

    onMouseUp = (event) => {
        this.setState('common', { cursor: this.cursor })
    }

    onMouseDrag = (event) => {
        this.toolPanning(event)
    }

    onMouseMove = (event: paper.MouseEvent): void => {
        if (
            this.startingPoint instanceof this.paper.Point &&
            this.cutoutItemId &&
            this.paper.project.getItem({ id: this.cutoutItemId }).contains(event.point)
        ) {
            // remove the previous rectangle that is no longer relevant
            if (this.rectangleId) this.paper.project.getItem({ id: this.rectangleId }).remove()
            const rect = this.draw(new this.paper.Color(this.color), this.startingPoint, event.point)

            this.rectangleId = rect.id
            this.cutoutPath = rect
            this.mediator.mediate('common', {
                tooltip: {
                    title: `Area: ${applyScaleFactorToPathArea({
                        pxValue: rect.area,
                        scaleFactor: this.scaleFactor,
                        dpi: this.getActiveDocumentChunk()?.dpi ?? null,
                        xCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_x ?? 1,
                        yCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_y ?? 1,
                        pdfScale: this.getActiveDocumentChunk()?.pdf_scale ?? 1,
                    }).toFixed(2)} sqft`,
                    visible: true,
                    color: '#000000',
                },
            })
        }
    }
}

export default Cutout
