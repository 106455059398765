import { DRAWABLE_TYPES, IMUP_JOIST_TYPES_GROUP } from '../constants/drawable-types'
import { DRAWABLE_GROUPPING_NAMES } from '../../components/markup/utils/constants/drawables-sort-order'
import { GeometryGroup, GeometryGroupWithTempType } from '../../imup/slices/geometry'
import { DrawableCoordinate, OpeningGroup } from '../../models/activeDrawable'

export const isHeaderAndBeamGroup = (type: DRAWABLE_TYPES, settingsType): boolean => {
    return (
        type?.toLowerCase() === DRAWABLE_TYPES.HEADER ||
        (type?.toLowerCase() === DRAWABLE_TYPES.AUTOMATED_BEAM_AND_HEADER && !!settingsType)
    )
}

export const isHeader = (type: DRAWABLE_TYPES): boolean => {
    return type?.toLowerCase() === DRAWABLE_TYPES.HEADER
}

export const isDroppedBeamGroup = (drawableSettingsType: string): boolean => {
    return (
        drawableSettingsType?.toLowerCase() === DRAWABLE_TYPES.DROPPED_BEAM ||
        drawableSettingsType?.toLowerCase() === DRAWABLE_TYPES.FLUSH_BEAM
    )
}

export const isEaveGableGroup = (drawableType: DRAWABLE_TYPES, drawableGroupSettingsName: string): boolean => {
    return (
        (drawableType === DRAWABLE_TYPES.ROOFING && drawableGroupSettingsName?.toLowerCase().includes('eave length')) ||
        (drawableType === DRAWABLE_TYPES.ROOFING &&
            drawableGroupSettingsName?.toLowerCase().includes('gable length')) ||
        drawableType === DRAWABLE_TYPES.EAVE_LENGTH ||
        drawableType === DRAWABLE_TYPES.GABLE_LENGTH
    )
}

export const isFoundationPilonGroup = (drawableType: string): boolean => {
    return drawableType === DRAWABLE_TYPES.FOUNDATION_PILON
}

export const isRoofBeam = (drawableType: string): boolean => {
    return (
        drawableType?.toLowerCase() === DRAWABLE_TYPES.RIDGE_BEAM ||
        drawableType?.toLowerCase() === DRAWABLE_TYPES.HIP_AND_VALLEY_BEAM
    )
}

export const isDeckOrPorchGroup = (drawableGroup): boolean => {
    return (
        drawableGroup.settings.folder?.toLowerCase() === DRAWABLE_GROUPPING_NAMES.DECK ||
        drawableGroup.settings.folder?.toLowerCase()?.includes(DRAWABLE_GROUPPING_NAMES.PORCH) ||
        drawableGroup.type?.toLowerCase() === DRAWABLE_TYPES.LF_FIVE_DOT_FIVE_DECKING ||
        drawableGroup.type?.toLowerCase() === DRAWABLE_TYPES.LF_RAILING
    )
}

export const isJoistGroup = (type: DRAWABLE_TYPES): boolean => {
    return IMUP_JOIST_TYPES_GROUP.includes(type)
}

export const isJoistLine = (c: DrawableCoordinate): boolean => {
    return isJoistGroup(c.drawable.type) && c.coordinates.length === 2
}

export const isFloorTrussSystemGroup = (type: DRAWABLE_TYPES): boolean => {
    return type === DRAWABLE_TYPES.FLOOR_TRUSS_SYSTEM || type === DRAWABLE_TYPES.FLOOR_TRUSS_BRACING
}

export const isPorchCeiling = (type: DRAWABLE_TYPES, folderName: string): boolean => {
    return (
        type?.toLowerCase() === DRAWABLE_TYPES.PORCH_CEILING ||
        (type?.toLowerCase() === DRAWABLE_TYPES.ROOFING && folderName?.toLowerCase()?.includes('porch ceiling'))
    )
}

export const isRoofingExterior = (type: DRAWABLE_TYPES, name: OpeningGroup['settings']['folder'] = ''): boolean => {
    return (
        (type?.toLowerCase() === DRAWABLE_TYPES.ROOFING && name.toLowerCase().includes('exterior')) ||
        type?.toLocaleLowerCase() === DRAWABLE_TYPES.EXTERIOR_ROOFING ||
        type?.toLocaleLowerCase() === DRAWABLE_TYPES.FLASHING ||
        type?.toLocaleLowerCase() === DRAWABLE_TYPES.ROOF_LINE
    )
}

export const isRoofingFraming = (type: DRAWABLE_TYPES, name: OpeningGroup['settings']['folder'] = ''): boolean => {
    return (
        (type?.toLowerCase() === DRAWABLE_TYPES.ROOFING && name.toLowerCase().includes('framing')) ||
        type?.toLowerCase() === DRAWABLE_TYPES.VALLEY_PLATE ||
        type?.toLowerCase() === DRAWABLE_TYPES.UNDER_VALLEY_SHEATHING ||
        type?.toLowerCase() === DRAWABLE_TYPES.FRAMING_ROOFING
    )
}

export const isFlashingGroup = (drawableSettingsName: string): boolean => {
    return drawableSettingsName?.toLowerCase()?.includes('flashing')
}

export const isSidingStarterGroup = (drawableType: string, drawableSettingsName: string): boolean => {
    return (
        (drawableType === DRAWABLE_TYPES.SIDING && drawableSettingsName?.toLowerCase()?.includes('starter')) ||
        drawableType === DRAWABLE_TYPES.SIDING_STARTER
    )
}

export const isWallGroup = (drawableType: string): boolean => {
    return drawableType === DRAWABLE_TYPES.SHEARWALL
}

export const isWildCard = (drawableType: string): boolean => {
    return (
        drawableType === DRAWABLE_TYPES.WILDCARD_AREA ||
        drawableType === DRAWABLE_TYPES.WILDCARD_COUNT ||
        drawableType === DRAWABLE_TYPES.WILDCARD_LINE
    )
}

// define the drawable tempType which will be used later to group drawables
export const addTempTypeToDrawables = (filteredDrawables: GeometryGroup[]): GeometryGroupWithTempType[] => {
    const groupsWithTempType: GeometryGroupWithTempType[] = []

    // this loop is added to add tempType, which is variable to just group the openings
    filteredDrawables.forEach((filteredDrawable: GeometryGroup) => {
        if (isEaveGableGroup(filteredDrawable.type, filteredDrawable.settings.name as string)) {
            groupsWithTempType.push({
                ...filteredDrawable,
                tempType: DRAWABLE_GROUPPING_NAMES.EAVE_GABLE,
            })
        } else if (isFoundationPilonGroup(filteredDrawable.type)) {
            groupsWithTempType.push({
                ...filteredDrawable,
                tempType: DRAWABLE_GROUPPING_NAMES.FOUNDATION_PILON,
            })
        } else if (isRoofBeam(filteredDrawable.type)) {
            groupsWithTempType.push({
                ...filteredDrawable,
                tempType: DRAWABLE_GROUPPING_NAMES.ROOFING_BEAM,
            })
        } else if (isJoistGroup(filteredDrawable.type)) {
            // in case, if joist type is selected, add tempType as joist, to group openings
            // under the JOIST group label
            groupsWithTempType.push({
                ...filteredDrawable,
                tempType: DRAWABLE_GROUPPING_NAMES.JOISTS,
            })
        } else if (isHeaderAndBeamGroup(filteredDrawable.type, filteredDrawable.settings.type)) {
            groupsWithTempType.push({
                ...filteredDrawable,
                tempType: DRAWABLE_GROUPPING_NAMES.HEADER_N_BEAM,
            })
        } else if (isFloorTrussSystemGroup(filteredDrawable.type)) {
            groupsWithTempType.push({
                ...filteredDrawable,
                tempType: DRAWABLE_GROUPPING_NAMES.FLOOR_TRUSS,
            })
        } else if (isPorchCeiling(filteredDrawable.type, filteredDrawable.settings.folder)) {
            groupsWithTempType.push({
                ...filteredDrawable,
                tempType: DRAWABLE_GROUPPING_NAMES.PORCH_CEILING,
            })
        } else if (isDeckOrPorchGroup(filteredDrawable)) {
            groupsWithTempType.push({
                ...filteredDrawable,
                tempType: DRAWABLE_GROUPPING_NAMES.DECK,
            })
        } else if (isRoofingExterior(filteredDrawable.type, filteredDrawable.settings?.folder)) {
            groupsWithTempType.push({
                ...filteredDrawable,
                tempType: DRAWABLE_GROUPPING_NAMES.EXTERIOR_ROOFING,
            })
        } else if (isRoofingFraming(filteredDrawable.type, filteredDrawable.settings?.folder)) {
            groupsWithTempType.push({
                ...filteredDrawable,
                tempType: DRAWABLE_GROUPPING_NAMES.FRAMING_ROOFING,
            })
        } else if (isFlashingGroup(filteredDrawable.settings?.name as string)) {
            groupsWithTempType.push({
                ...filteredDrawable,
                tempType: DRAWABLE_GROUPPING_NAMES.FLASHING,
            })
        } else if (isSidingStarterGroup(filteredDrawable.type, filteredDrawable.settings?.name as string)) {
            groupsWithTempType.push({
                ...filteredDrawable,
                tempType: DRAWABLE_GROUPPING_NAMES.SIDING_STARTER,
            })
        } else if (isWallGroup(filteredDrawable.type)) {
            groupsWithTempType.push({
                ...filteredDrawable,
                tempType: DRAWABLE_GROUPPING_NAMES.WALL,
            })
        } else if (isWildCard(filteredDrawable.type)) {
            groupsWithTempType.push({
                ...filteredDrawable,
                tempType: filteredDrawable.type,
            })
        } else if (
            filteredDrawable.type === DRAWABLE_TYPES.GARAGE_AREA ||
            filteredDrawable.type === DRAWABLE_TYPES.BASEMENT_FINISHED_CEILING
        ) {
            // Place the garage are under the floor area
            groupsWithTempType.push({
                ...filteredDrawable,
                tempType: DRAWABLE_TYPES.FLOOR_AREA.toLowerCase(),
            })
        } else {
            groupsWithTempType.push({
                ...filteredDrawable,
                tempType: filteredDrawable.type.toLowerCase(),
            })
        }
    })

    return groupsWithTempType
}
