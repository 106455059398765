import { UiSchema } from '@rjsf/core'

import { FormProperties } from '../imup/types'

export const uiSchema: UiSchema = {
    form_label: {
        'ui:title': 'Label',
    },
    'ui:order': ['form_label'],
}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    return {
        ...settings,
        ...formData,
        form_label: !formData.form_label && settings.form_label ? null : formData.form_label,
    }
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
