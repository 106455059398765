import PaperTool from '../paperTool/PaperTool'
import { Cursors, PaperToolConfig } from '../../../../../types'

/**
 * FreeRotate.tool.ts
 * Handles rotating images via a drag interaction
 */
export class FreeRotate extends PaperTool {
    static NAME = 'FREE_ROTATE'
    static CURSOR = Cursors.ROTATE

    private raster: paper.Raster | null = null
    private prevPoint: paper.Point | null = null
    private widthToRotateRatio = 360
    private degreesRotated = 0

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = FreeRotate.NAME
        this.cursor = FreeRotate.CURSOR
    }

    /**
     * onMouseDown
     * This function establishes the current "anchor" point from which the
     * rotation delta will be calculated
     * @param event
     */
    onMouseDown = (event: paper.ToolEvent): void => {
        this.prevPoint = event.point
        this.raster = this.paper.project.getItem({
            class: this.paper.Raster,
        }) as paper.Raster | null

        this.degreesRotated = 0

        if (this.raster) {
            this.widthToRotateRatio = this.raster?.bounds.width / 360
        }
    }

    /**
     * onMouseUp
     * This function triggers the redux updates necessary to commit the rotation
     * and resets the tool's properties
     * @param event
     */
    onMouseUp = (event: paper.ToolEvent) => {
        this.prevPoint = null
        this.mediator.mediate('tools', {
            imageRotateDegree: this.degreesRotated,
        })
        this.degreesRotated = 0
        this.widthToRotateRatio = 360
        this.mediator.mediate('common', {
            cursor: this.cursor,
            tooltip: { title: '', visible: false, color: '#000000' },
        })
    }

    /**
     * onMouseDrag
     * This function calculates the rotation delta and applies it locally to the raster
     * @param event
     */
    onMouseDrag = (event: paper.ToolEvent) => {
        if (this.prevPoint && this.raster) {
            const deltaX = event.point.x - this.prevPoint.x

            this.prevPoint = event.point
            // Want to round so we can only do 1 degree at a time
            const roundedRotateValue = Math.round(deltaX / this.widthToRotateRatio)

            this.degreesRotated += roundedRotateValue
            this.raster.rotate(roundedRotateValue)
            this.mediator.mediate('common', {
                tooltip: {
                    title: `${this.degreesRotated}°`,
                    visible: true,
                    color: '#000000',
                },
            })
        }
    }

    /**
     * getCurrentRotation
     * Returns the current rotation value
     * @returns
     */
    getCurrentRotation = (): number => {
        return this.degreesRotated
    }

    /**
     * cancel
     * Resets the rotation in progress and deactivates the tool
     * @returns
     */
    cancel = () => {
        this.raster && this.raster.rotate(-this.degreesRotated / 2)
        this.prevPoint = null
        this.mediator.mediate('tools', {
            imageRotateDegree: 0,
        })
        this.degreesRotated = 0
        this.widthToRotateRatio = 360
        this.prevPoint = null
        this.mediator.mediate('common', {
            cursor: this.cursor,
            tooltip: { title: '', visible: false, color: '#000000' },
        })
    }
}
