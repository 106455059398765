import { createAction } from '@reduxjs/toolkit'
import { all, call, ForkEffect, put, select, takeEvery } from 'redux-saga/effects'

import { IMasterSetPlanOption } from '../../../models/masterSetPlan'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { MasterSetPlanRegion, Select, Workspace } from '../../lib/toolBoxes/2D'
import {
    removeMasterSetPlanOptionById,
    selectMasterSetPlanToCreate,
    setActiveMasterSetPlanOption,
} from '../../slices/masterSetPlan'

export const deleteMasterSetPlanAction = createAction<{ itemId: number }>('deleteMasterSetPlan')

export function* handleMasterSetPlanDelete({ payload }: ReturnType<typeof deleteMasterSetPlanAction>) {
    const paperManager: PaperManager | null = yield call(managers.get2DManager)

    if (!paperManager) return

    const [workspaceTool, selectTool, masterSetPlanRegionTool]: [Workspace, Select, MasterSetPlanRegion] = yield call(
        paperManager.getTools,
        [Workspace.NAME, Select.NAME, MasterSetPlanRegion.NAME]
    )

    const masterSetOptionItem: paper.Path | null = yield call(workspaceTool.getItemWithPaperId, payload.itemId)

    if (!masterSetOptionItem) return

    const { masterSetPlanOptions: backupMasterSetPlanOptionsList }: ReturnType<typeof selectMasterSetPlanToCreate> =
        yield select(selectMasterSetPlanToCreate)

    // Deleting  without any in store is
    // not allowed
    if (backupMasterSetPlanOptionsList.length === 0) return

    const masterSetPlanOptionsToDelete: IMasterSetPlanOption | undefined = yield backupMasterSetPlanOptionsList.find(
        (option) => option.id === masterSetOptionItem.data.masterSetPlanId
    )

    // cant delete the item if
    // it is not associated with a master set option
    if (!masterSetPlanOptionsToDelete) return

    yield all([
        put(setActiveMasterSetPlanOption(null)),
        put(removeMasterSetPlanOptionById(masterSetOptionItem.data.masterSetPlanId)),
        call(masterSetPlanRegionTool.handleDeleteMasterSetPlanPath, masterSetOptionItem),
        call(selectTool.exitSelectMode),
    ])
}

export function* watchForDeleteMasterSetPlan(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(deleteMasterSetPlanAction.type, handleMasterSetPlanDelete)
}
