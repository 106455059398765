import { Coordinates2D, IMUP2DDrawableCutout } from '../imup/types'
import { Coordinate } from './activeDrawable'

interface IMasterSetPlanObject {
    name: string
    isOption: boolean
    scale?: string
    type: string
    coordinates: Coordinates2D
}

export interface IMasterSetPlanToCreate {
    id: number
    coordinates: Coordinates2D
}

export interface IMasterSetPlanToPost {
    plans: IMasterSetPlanObject[]
    building_id: number
}

export interface IMasterSetPlanOption {
    id: number
    name: string
    scale?: string
    type: string
    isOption: boolean
    coordinates: Coordinates2D
    document_chunk_id: number
}

export enum MASTER_SET_PLAN_ENUMS {
    TEMPORARY_MASTER_SET_PLAN_REGION = 'TEMPORARY_MASTER_SET_PLAN_REGION',
    MASTER_SET_PLAN_OPTION = 'MASTER_SET_PLAN_OPTION',
    MASTER_SET_PLAN_BASE_HOME = 'MASTER_SET_PLAN_BASE_HOME',
}

export interface IMaterialModificationConflict {
    id: number
    material_location_id: number
    coordinates: Coordinate[]
    floor_plan_id: number
    option_ids: number[]
    cutouts?: IMUP2DDrawableCutout[]
    shapeColor?: string
}
