import { createAction, createSelector } from '@reduxjs/toolkit'
import { all, call, ForkEffect, put, select, takeEvery } from 'redux-saga/effects'

import { createMasterSetPlans } from '../../../api/projects-api'
import { IMasterSetPlanOption, IMasterSetPlanToPost, MASTER_SET_PLAN_ENUMS } from '../../../models/masterSetPlan'
import { Project } from '../../../models/project'
import { RootState } from '../../../stores'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { MasterSetPlanRegion } from '../../lib/toolBoxes/2D'
import { selectDocumentChunksWBuildingID } from '../../slices/documents'
import { addMasterSetPlanOptionToStore, selectMasterSetPlanToCreate } from '../../slices/masterSetPlan'

export const createMasterSetPlanAction = createAction<{ masterSetPlanOptionType: MASTER_SET_PLAN_ENUMS }>(
    'createMasterSetPlanAction'
)

export const selectProjectActiveChunkState = createSelector(
    ({ IMUP: { documents, project } }: RootState) => {
        return {
            activeDocumentChunkId: documents.activeDocumentChunkId,
            project: project.project,
        }
    },
    (state) => state
)

export function* handleCreateMasterSetPlanAction({ payload }: ReturnType<typeof createMasterSetPlanAction>) {
    const paperManager: PaperManager | null = yield call(managers.get2DManager)

    if (!paperManager) return

    try {
        const { activeDocumentChunkId, project }: { activeDocumentChunkId: number | null; project: Project | null } =
            yield select(selectProjectActiveChunkState)

        if (activeDocumentChunkId === null || project === null) return

        const [masterSetPlanRegionTool] = yield call(paperManager.getTools, [MasterSetPlanRegion.NAME])

        const documentChunks: ReturnType<typeof selectDocumentChunksWBuildingID> = yield select(
            selectDocumentChunksWBuildingID
        )
        const buildingId = documentChunks?.find((d) => d.id === activeDocumentChunkId)?.buildingID

        const { masterSetPlanToCreate, masterSetPlanFormDataState }: ReturnType<typeof selectMasterSetPlanToCreate> =
            yield select(selectMasterSetPlanToCreate)

        const { name: selectedFormName, type: selectedFormType } = masterSetPlanFormDataState

        if (masterSetPlanToCreate.length && buildingId && selectedFormName && selectedFormType) {
            const MasterSetOptionsToPost: IMasterSetPlanToPost = {
                plans: masterSetPlanToCreate.map((m) => {
                    return {
                        name: selectedFormName,
                        isOption: payload.masterSetPlanOptionType === MASTER_SET_PLAN_ENUMS.MASTER_SET_PLAN_OPTION,
                        scale: masterSetPlanFormDataState.scale,
                        type: selectedFormType,
                        coordinates: m.coordinates,
                    }
                }),
                building_id: buildingId,
            }

            const responseMasterSetPlanOptions: IMasterSetPlanOption[] = yield call(
                createMasterSetPlans,
                project.id,
                activeDocumentChunkId,
                MasterSetOptionsToPost
            )

            if (responseMasterSetPlanOptions) {
                yield put(addMasterSetPlanOptionToStore(responseMasterSetPlanOptions))

                // draw new master set plan options
                yield all(
                    responseMasterSetPlanOptions.map((msOption) => {
                        return call(
                            masterSetPlanRegionTool.renderBaseHomeOptionPageRegion,
                            msOption.coordinates,
                            msOption.id,
                            msOption.isOption
                        )
                    })
                )
            }
        }
    } catch (error) {
        yield call(console.error, error)
    }
}

export function* watchForCreateMasterSetPlanAction(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(createMasterSetPlanAction.type, handleCreateMasterSetPlanAction)
}
