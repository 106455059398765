import { all, call, put, select, StrictEffect } from 'redux-saga/effects'

import { UPDATE_OPENING_GROUPS_SUCCESS } from '../../../actions/drawable'
import { deleteOpeningLocation } from '../../../api/projects-api'
import { OpeningAPI } from '../../../models/activeDrawable'
import { Project } from '../../../models/project'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Workspace } from '../../lib/toolBoxes/2D'
import {
    ActiveGeometryGroup,
    GeometricDrawable,
    selectActiveGeometryGroup,
    setActiveGroup,
} from '../../slices/geometry'

export function* deleteOpeningLocation2D({
    payload,
}: {
    payload: number
}): Generator<StrictEffect, void, PaperManager & Workspace & ActiveGeometryGroup & Project> {
    try {
        const manager: PaperManager = yield call(managers.get2DManager)

        if (!manager) return

        const workspaceTool: Workspace = yield call(manager.getTool, Workspace.NAME)

        const activeDrawableGroup: ActiveGeometryGroup = yield select(selectActiveGeometryGroup)
        const activeDrawable: GeometricDrawable | undefined = activeDrawableGroup?.openings.find(
            (opening) => opening.isActive
        )

        if (!activeDrawable) return

        const items: paper.Item[] = yield call(workspaceTool.getItemsWithDrawableId, activeDrawable.id)

        if (!items) return

        // try the API call. if there's an error -> undo the deletion actions to reset the drawable & paper state
        try {
            const updatedOpening: OpeningAPI = yield call(deleteOpeningLocation, activeDrawable.id, payload)

            yield all(items.map((item) => call(workspaceTool.removeItemWithPaperId.bind(workspaceTool), item.id)))

            const updatedActiveGeometryGroup: ActiveGeometryGroup = {
                ...activeDrawableGroup,
                openings: activeDrawableGroup.openings.map((opening) => {
                    const newOpening = opening.id === updatedOpening?.id ? updatedOpening : opening

                    return { ...newOpening, is_marked_done: activeDrawableGroup.is_marked_done }
                }),
                drawables: activeDrawableGroup.drawables.map((drawable) => {
                    const newOpening = drawable.id === updatedOpening?.id ? updatedOpening : drawable

                    return { ...newOpening, is_marked_done: activeDrawableGroup.is_marked_done }
                }),
            }

            yield put({
                type: UPDATE_OPENING_GROUPS_SUCCESS,
                payload: {
                    openingGroups: { newGroup: updatedActiveGeometryGroup, originalGroup: activeDrawableGroup },
                },
            })

            yield put(
                setActiveGroup({
                    activeDrawableGroup: updatedActiveGeometryGroup,
                    activeDrawableId: activeDrawable.id,
                })
            )
        } catch (e) {
            yield call(console.error, `Error on API deletion: ${e}`)

            yield all(items.map((item) => call(workspaceTool.addPaperItem, item))) // redraw the item on the blueprint

            put({
                type: UPDATE_OPENING_GROUPS_SUCCESS,
                payload: { openingGroups: { newGroup: activeDrawableGroup, originalGroup: activeDrawableGroup } },
            })
        }
    } catch (error) {
        yield call(console.error, error as any)
    }
}
