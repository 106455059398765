import React, { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import TagManager from 'react-gtm-module'
import { Provider } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { datadogRum } from '@datadog/browser-rum'

import AxiosErrorHandler from './AxiosError'
import { ErrorFallback } from './ErrorFallback'
import store from '../../stores'

import './App.scss'

const Home = React.lazy(() => import('../../containers/main/main'))

export const AppInsightContext = React.createContext({})

export class App extends React.Component<any, any> {
    constructor(props) {
        super(props)

        this.state = {
            appInsights: {},
        }
    }

    UNSAFE_componentWillMount() {
        const GTM_AUTH = import.meta.env.VITE_GTM_AUTH
        const GTM_PREVIEW = import.meta.env.VITE_GTM_PREVIEW

        const tagManagerArgs = {
            gtmId: 'GTM-T89L3SD',
            auth: GTM_AUTH,
            preview: GTM_PREVIEW,
        }

        TagManager.initialize(tagManagerArgs)

        const dataDogAppId = import.meta.env.VITE_DATA_DOG_APP_ID

        const dataDogClientToken = import.meta.env.VITE_DATA_DOG_CLIENT_TOKEN

        const dataDogEnv = import.meta.env.VITE_DATA_DOG_ENV

        const dataDogSampleRate = import.meta.env.VITE_DATA_DOG_SAMPLE_RATE

        const dataDogSessionSampleRate = import.meta.env.VITE_DATA_DOG_SESSION_REPLAY_SAMPLE_RATE

        const codeCommitId = import.meta.env.VITE_CODE_COMMIT_ID

        if (!dataDogAppId || !dataDogClientToken) return

        datadogRum.init({
            applicationId: dataDogAppId,
            clientToken: dataDogClientToken,
            site: 'datadoghq.com',
            service: 'digitizer',
            env: dataDogEnv,
            version: codeCommitId,
            sampleRate: parseInt(dataDogSampleRate || '0'),
            sessionReplaySampleRate: parseInt(dataDogSessionSampleRate || '0'),
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'allow',
            trackUserInteractions: true,
        })

        datadogRum.startSessionReplayRecording()
    }

    componentDidCatch(error: any, errorInfo: any) {
        // You can also log the error to an error reporting service
        console.error(error, errorInfo)
    }

    render() {
        return (
            <ErrorBoundary FallbackComponent={(e) => ErrorFallback(e)}>
                <AxiosErrorHandler />
                <Suspense fallback={<div>Loading...</div>}>
                    <Provider store={store}>
                        <Home />
                    </Provider>
                </Suspense>
            </ErrorBoundary>
        )
    }
}

export default withRouter(App)
