import '@babylonjs/core/Collisions/collisionCoordinator'
import '@babylonjs/core/Culling/ray'

import { VertexBuffer } from '@babylonjs/core/Buffers/buffer'
import { PBRMaterial } from '@babylonjs/core/Materials/PBR/pbrMaterial'
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial'
import { Mesh } from '@babylonjs/core/Meshes/mesh'
import { Scene } from '@babylonjs/core/scene'
import { FloatArray, Nullable } from '@babylonjs/core/types'
import has from 'lodash/has'

import { IMaterialGenerator, IModel } from '../../../../../types'

type MeshMaterialApplicationInput = {
    meshes: Mesh[]
    material: StandardMaterial | PBRMaterial
    textureXRatio: number
    textureYRatio: number
    uvsMap: Map<string, number[]>
}

/**
 * Abstract class containing basic helper functions for all material generators
 * material generators load and manage application of custom babylon material to meshes
 */
export abstract class AbstractMaterialGenerator implements IMaterialGenerator {
    private static readonly Z_OFFSET_PRIORITY_MESH_NAME = 'polygon-mesh'

    // Regex for hex color
    private static readonly HEX_PATTERN = '^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$'

    abstract initGenerator(scene: Scene, structure: IModel): void | Promise<void>

    /**
     * Applies a material to mesh with the given material.
     * Note: This method disposes the mesh's old material and disables the vertex color
     *
     * @param mesh whom the material will be applied
     * @param material to apply to mesh
     */
    applyMaterialOnMesh(mesh: Mesh, material: StandardMaterial | PBRMaterial): void {
        mesh.material && mesh.material.dispose()

        mesh.material = material

        // set polygon meshes materials to have zOffset priority, often overlaid on structure meshes
        if (mesh.name.includes(AbstractMaterialGenerator.Z_OFFSET_PRIORITY_MESH_NAME)) {
            mesh.material.zOffset = 1
        }

        // set unselectable mesh materials to have low priority zOffset relative to other materials
        if (has(mesh, 'metadata.isReflectedInTwoD') && !mesh.metadata.isReflectedInTwoD) {
            mesh.material.zOffset = -1
        }

        mesh.useVertexColors = false
    }

    /**
     * Applies a material to meshes with the given material.
     * Note: This method disposes the meshes' old material and disables the vertex color
     *
     * @param meshes whom the material will be applied
     * @param material to apply to meshes
     */
    applyMaterialOnMeshes(meshes: Mesh[], material: StandardMaterial | PBRMaterial): void {
        meshes.forEach((mesh) => {
            this.applyMaterialOnMesh(mesh, material)
        })
    }

    abstract applyMaterial(meshes?: Mesh[]): void

    /**
     * The uvs is adjusted by the given textureRatio
     *
     * @param mesh Mesh to adjust Uvs by given TexturesRatios
     * @param uvs Uvs of given Mesh
     * @param textureXRatio texture ration on the X axis
     * @param textureYRatio texture ration on the Y axis
     */
    adjustUvsToTextureRatios(
        mesh: Mesh,
        uvs: Nullable<FloatArray>,
        textureXRatio: number,
        textureYRatio: number
    ): void {
        if (uvs && uvs.length) {
            mesh.updateVerticesData(
                VertexBuffer.UVKind,
                uvs!.map((uv: number, index: number) => {
                    return index % 2 === 0 ? uv / textureXRatio : uv / textureYRatio
                })
            )
        }
    }

    /**
     * Applies a material on meshes that contains texture with the given material. The look of the material is adjusted by the given uvs and ratios
     * Note: This method disposes the meshes' old material and disables the vertex color
     *
     * @param input
     *
     */
    applyTextureMaterialOnMeshes(input: MeshMaterialApplicationInput): void {
        input.meshes.forEach((mesh) => {
            const uvs: Nullable<FloatArray> = []

            const meshUvs: number[] | undefined = input.uvsMap.get(mesh.id)

            if (meshUvs) {
                uvs.push(...meshUvs!)
                if (uvs && uvs.length) {
                    this.adjustUvsToTextureRatios(mesh, uvs, input.textureXRatio, input.textureYRatio)
                    this.applyMaterialOnMesh(mesh, input.material)
                }
            } else {
                this.applyMaterialOnMesh(mesh, input.material)
            }
        })
    }

    /**
     * Find meshes by id
     *
     * @export
     * @param {string[]} ids array of mesh id
     * @param {BABYLON.Scene} scene rendering scene
     * @returns {BABYLON.Mesh[]} array of meshes
     */
    findMeshes = (ids: string[], scene: Scene): Mesh[] => {
        return ids.reduce((meshes, id) => {
            const mesh = scene.getMeshByID(id)

            return mesh ? [...meshes, mesh as Mesh] : meshes
        }, [] as Mesh[])
    }

    /**
     * Test if a string is hex color string
     * @param hexString the string to test
     * @returns
     */
    static isHexColorValid(hexString: string): boolean {
        const regex = new RegExp(AbstractMaterialGenerator.HEX_PATTERN, 'i')

        return regex.test(hexString)
    }
}
