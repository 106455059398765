import { put } from 'redux-saga/effects'

import ModelUtils from '../../../components/IMUP3DComponent/babylon/ModelUtils'
import { updateRoofEdges } from '../../../slices/3D'
import {
    IBabylonLineWithMetadata,
    IRoofEdgeData,
    ModelType,
    RevitIdToConfigIdMap,
    ThreeDElementsResponse,
    ThreeDToTwoDRecord,
} from '../../../types'

export function* prepareRoofEdges(
    lineResponse: ThreeDElementsResponse,
    threeDToTwoDMappings: ThreeDToTwoDRecord,
    metadata: {
        modelType: ModelType
        storeyName: string
        isInterior: boolean
    },
    geometriesRevitIdToConfigIdMap: RevitIdToConfigIdMap
) {
    const lineDictionary: Record<string, IBabylonLineWithMetadata> = yield Object.keys(lineResponse).reduce(
        (lineDictionary, id) => {
            const parsedData: IRoofEdgeData = JSON.parse(lineResponse[id].data)

            lineDictionary[parsedData.customRevitId] = {
                path: [
                    { x: parsedData.point1.x, y: parsedData.point1.y, z: -parsedData.point1.z },
                    { x: parsedData.point2.x, y: parsedData.point2.y, z: -parsedData.point2.z },
                ],
                metaData: {
                    isReflectedInTwoD: !!threeDToTwoDMappings[parsedData.customRevitId],
                    ids: threeDToTwoDMappings[parsedData.customRevitId]
                        ? threeDToTwoDMappings[parsedData.customRevitId]
                        : [],
                    isInterior: metadata.isInterior,
                    storeyName: metadata.storeyName,
                    modelType: parsedData.singleEdgeType ? parsedData.singleEdgeType : metadata.modelType,
                    configuration_id:
                        geometriesRevitIdToConfigIdMap[
                            ModelUtils.extractGeometryIDFromBaseId(parsedData.customRevitIdBase)
                        ],
                },
            }

            return lineDictionary
        },
        {}
    )

    yield put(updateRoofEdges(lineDictionary))
}

export function* updateRoofEdgesMetaData(
    roofEdges: Record<string, IBabylonLineWithMetadata>,
    threeDToTwoDMappings: ThreeDToTwoDRecord
) {
    const updatedRoofEdges: Record<string, IBabylonLineWithMetadata> = {}

    Object.keys(roofEdges).forEach((id) => {
        updatedRoofEdges[id] = {
            ...roofEdges[id],
            metaData: { ...roofEdges[id]['metaData'], ids: threeDToTwoDMappings[id] ? threeDToTwoDMappings[id] : [] },
        }
    })

    yield put(updateRoofEdges(updatedRoofEdges))
}
