import isNull from 'lodash/isNull'
import { call, select, takeLatest } from 'redux-saga/effects'

import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Select } from '../../lib/toolBoxes/2D'
import { Overlay } from '../../lib/toolBoxes/2D/tools'
import { selectOpacityDict, selectOverlayColor, setOverlayChunkId } from '../../slices/2D'
import { NormalizedDocumentChunk, selectNormalizedDocumentChunks } from '../../slices/documents'

export function* handleInsertingNewImageOverlay(action: ReturnType<typeof setOverlayChunkId>) {
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (isNull(manager)) return

    const [overlayTool]: [Overlay] = yield call(manager.getTools, [Overlay.NAME])

    if (action.payload === null) {
        yield call(overlayTool.resetOverlayRasters)
        yield call(overlayTool.resetSelectedImagesAndCrossHairs)
        yield call(manager.useTool, Select.NAME)

        return
    }

    const normalizedChunks: NormalizedDocumentChunk[] = yield select(selectNormalizedDocumentChunks)
    const chunkOpacityDict: Record<number, number> = yield select(selectOpacityDict)
    const possibleChunk: NormalizedDocumentChunk | undefined = normalizedChunks.find(
        (docChunk) => docChunk.id === action.payload
    )
    const overlayColor = yield select(selectOverlayColor)

    if (possibleChunk) {
        const { src } = possibleChunk
        const chunkOpacity: number = isFinite(chunkOpacityDict[possibleChunk.id])
            ? chunkOpacityDict[possibleChunk.id] / 100
            : Overlay.DEFAULT_OPACITY

        yield call(overlayTool.insertOverlay, src, possibleChunk.id, chunkOpacity, overlayColor)
        yield call(overlayTool.activate)
    }
}

export function* watchForSelectingNewImageOverlay() {
    yield takeLatest(setOverlayChunkId.type, handleInsertingNewImageOverlay)
}
