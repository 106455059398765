import { createAction } from '@reduxjs/toolkit'
import { all, call, put, select, throttle } from 'redux-saga/effects'

import { selectDrawableLocations } from '../effects/deleteTemporaryDrawableGroups'
import { generateUpdateDrawableCoordinatesEndpointData, updateJoistDataForGroup } from './updateDrawableCoordinates'
import { updatedDrawableMeasurements } from '../../../actions/drawable'
import { UpdateOpeningGroupApiResponse } from '../../../api/api-helper'
import { bulkUpdateOpeningLocationData } from '../../../api/takeoff-api'
import { Coordinate } from '../../../models/activeDrawable'
import { ActiveFloor } from '../../../models/activeFloor'
import { NEW_IMUP_JOIST_TYPES_GROUP } from '../../../shared/constants/drawable-types'
import { isHeaderAndBeamGroup } from '../../../shared/services/drawable-groups-service'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Label, Select, Workspace } from '../../lib/toolBoxes/2D'
import { updateDrawableLocationCoordinates } from '../../slices/2D'
import { updateToolbarMessage } from '../../slices/common'
import { selectDrawableActiveFloor } from '../../slices/documents'
import { Geometry, selectGeometries } from '../../slices/geometry'
import {
    IMUP2DCoordinatesToUpdate,
    IMUP2DDrawableCutout,
    IMUP2DDrawableLocation,
    MeasurementsToUpdate,
    SAGA_THROTTLE_TIMER,
    SAVING_MESSAGE,
} from '../../types'

export type BulkUpdateOpeningLocationCoordinatesPayload = (IMUP2DCoordinatesToUpdate & {
    opening_id: number
    opening_group_id: number
    opening_location_id: number
})[]

export const bulkUpdateOpeningLocationCoordinatesAction = createAction<BulkUpdateOpeningLocationCoordinatesPayload>(
    'bulkUpdateOpeningLocationCoordinates'
)

export function* handleBulkUpdateOpeningLocationCoordinates({
    payload,
}: ReturnType<typeof bulkUpdateOpeningLocationCoordinatesAction>) {
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (!manager) return

    yield put(updateToolbarMessage(SAVING_MESSAGE))

    const [workspace, selectTool, label]: [Workspace, Select, Label] = yield call(manager.getTools, [
        Workspace.NAME,
        Select.NAME,
        Label.NAME,
    ])
    const geometries: Geometry[] = yield select(selectGeometries)

    const locations: IMUP2DDrawableLocation[] = yield select(selectDrawableLocations)
    const activeFloor: ActiveFloor = yield select(selectDrawableActiveFloor)
    const items: paper.Item[] = yield call(workspace.getAllDrawableItems)

    const coordinatesAndProjectIdAndRegionId: {
        coordinatesData: IMUP2DCoordinatesToUpdate
        projectId: number
        regionId: number | null
    }[] = yield all(
        payload.map((actionInput) => {
            const coordinates: IMUP2DCoordinatesToUpdate = {
                coordinates: actionInput.coordinates,
                cutouts: actionInput.cutouts,
                measurements: actionInput.measurements,
            }
            const geo = geometries.find((geo) => geo.id === actionInput.opening_location_id)

            if (geo) {
                if (isHeaderAndBeamGroup(geo.type, geo.settings.type)) {
                    const potentialPathItems = selectTool.getMultiSelectedItems()

                    const pathItem = potentialPathItems?.find(
                        (potentialPathItem) =>
                            potentialPathItem.className === 'Path' &&
                            potentialPathItem.data.drawable_id === actionInput.opening_id
                    )

                    if (pathItem) {
                        const pathParent = selectTool.getMultiSelectedItemParent(pathItem.id)

                        if (pathParent && pathParent.parent !== null) {
                            label.updateLabelPosition(pathParent as paper.Group, pathItem as paper.Path)
                        }
                    }
                }
            }

            return call(
                generateUpdateDrawableCoordinatesEndpointData,
                coordinates,
                items.filter(
                    (item) => item.data.opening_location_id === actionInput.opening_location_id
                )?.[0] as paper.PathItem,
                false,
                locations.filter((loc) => loc.opening_location_id === actionInput.opening_location_id)?.[0],
                activeFloor,
                workspace,
                { settings: geo?.settings, type: geo?.type }
            )
        })
    )

    const apiInputData: {
        opening_id: number
        opening_location_id: number
        coordinates: Coordinate[]
        measurements: MeasurementsToUpdate
        cutouts?: IMUP2DDrawableCutout[]
        region_id?: number | null
    }[] = yield coordinatesAndProjectIdAndRegionId.map((data, i) => {
        return {
            opening_id: payload[i].opening_id,
            opening_location_id: payload[i].opening_location_id,
            coordinates: data.coordinatesData.coordinates,
            measurements: data.coordinatesData.measurements,
            cutouts: data.coordinatesData.cutouts,
            region_id: data.regionId,
        }
    })

    const response: UpdateOpeningGroupApiResponse[] = yield call(
        bulkUpdateOpeningLocationData,
        coordinatesAndProjectIdAndRegionId[0].projectId,
        apiInputData
    )

    const joistLinesUpdateData: { groupId: number; projectId: number }[] = []

    yield all(
        response.flatMap((newAndOldGroup, i) => {
            const openingId = payload[i].opening_id
            const openingLocationId = payload[i].opening_location_id
            // After API success, update drawableLocations 2D slice and
            // update opening & opening group settings (quantity/linear total)
            const newDrawable = newAndOldGroup.newGroup.openings.find((drawable) => drawable.id === openingId)

            const newQuantity = newDrawable?.settings.quantity
            const newLinearTotal = newDrawable?.settings.linear_total
            const newCutouts = payload[i].cutouts
            const regionId = coordinatesAndProjectIdAndRegionId[i].regionId

            // update the quantity & linear total inside the activeDrawableGroup and drawable groups
            const groupMeasurements = {
                quantity: newAndOldGroup.newGroup.settings.quantity,
                linear_total: newAndOldGroup.newGroup.settings.linear_total,
            }

            const opening = newAndOldGroup.newGroup.openings.find((o) => o.id === openingId)
            const openingMeasurements = {
                area: opening?.settings.area,
                quantity: opening?.settings.quantity ?? null,
                linear_total: opening?.settings.linear_total ?? null,
            }

            const shoudUpdateJoistLines = Boolean(
                NEW_IMUP_JOIST_TYPES_GROUP.includes(newAndOldGroup.newGroup.type) &&
                    newAndOldGroup.newGroup.settings.direction &&
                    newAndOldGroup.newGroup.settings.oc_spacing
            )

            if (shoudUpdateJoistLines) {
                joistLinesUpdateData.push({
                    groupId: newAndOldGroup.newGroup.id,
                    projectId: newAndOldGroup.newGroup.project_id,
                })
            }

            return [
                put(
                    updateDrawableLocationCoordinates({
                        opening_location_id: openingLocationId,
                        coordinates: payload[i].coordinates,
                        linear_total: newLinearTotal ?? null,
                        quantity: newQuantity ?? null,
                        cutouts: newCutouts,
                        region_id: regionId,
                    })
                ),
                put(
                    updatedDrawableMeasurements({
                        activeDrawableId: openingId,
                        groupMeasurements,
                        openingMeasurements,
                        cutouts: newCutouts,
                        coordinates: payload[i].coordinates,
                        updatedLocationID: openingLocationId,
                    })
                ),
            ]
        })
    )

    yield all(
        joistLinesUpdateData.map(({ groupId, projectId }) => {
            return call(updateJoistDataForGroup, projectId, groupId)
        })
    )

    yield put(updateToolbarMessage(null))
}

export function* watchForBuildBulkUpdateOpeningLocationCoordinates() {
    yield throttle(
        SAGA_THROTTLE_TIMER,
        bulkUpdateOpeningLocationCoordinatesAction.type,
        handleBulkUpdateOpeningLocationCoordinates
    )
}
