import { createAction } from '@reduxjs/toolkit'
import cloneDeep from 'lodash/cloneDeep'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Select, Workspace } from '../../lib/toolBoxes/2D'
import { selectMapDrawableLocationsByOpeningLocationId, setAltSelectedItems } from '../../slices/2D'
import {
    selectMaterialModificationConflicts,
    updateMaterialModificationConflictToSave,
} from '../../slices/masterSetPlan'
import { IMUP2DDrawableLocation } from '../../types'

export const deleteModificationAction = createAction<paper.Item[]>('deleteModification')

export function* handleDeleteModification(action: ReturnType<typeof deleteModificationAction>) {
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (!manager) return

    const [selectTool, workspaceTool]: [Select, Workspace] = yield call(manager.getTools, [Select.NAME, Workspace.NAME])

    const { materialModificationConflicts }: ReturnType<typeof selectMaterialModificationConflicts> = yield select(
        selectMaterialModificationConflicts
    )

    const materialModificationConflictsIdsSet: Set<number> = new Set(
        action.payload.map((item) => item.data.materialModificationConflict.id)
    )

    const materialConflictsList = materialModificationConflicts.filter((m) =>
        materialModificationConflictsIdsSet.has(m.id)
    )

    const mapDrawableLocations: ReturnType<typeof selectMapDrawableLocationsByOpeningLocationId> = yield select(
        selectMapDrawableLocationsByOpeningLocationId
    )

    // update coordinates to original state
    const materialConflictsIoDelete = materialConflictsList.map((m) => {
        const assignedLocation: IMUP2DDrawableLocation | undefined = mapDrawableLocations.get(m.material_location_id)
        const updatedMatirialConflict = cloneDeep(m)

        if (assignedLocation) {
            updatedMatirialConflict.coordinates = assignedLocation.coordinates
        }

        return updatedMatirialConflict
    })

    yield put(updateMaterialModificationConflictToSave(materialConflictsIoDelete))

    // reset alt selected items
    yield put(setAltSelectedItems(null))

    // exit selected mode, remove handlers
    yield call(selectTool.exitSelectMode)

    const itemsToDelete: paper.Item[] | null = yield call(workspaceTool.getItemsWithCriteria, 'data', (data) =>
        materialModificationConflictsIdsSet.has(data?.materialModificationConflict?.id)
    )

    if (!itemsToDelete) return

    // remove items from blueprint
    yield all(itemsToDelete.map((item) => call(item.remove)))
}

export function* watchForDeleteModification() {
    yield takeEvery(deleteModificationAction.type, handleDeleteModification)
}
