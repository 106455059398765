export const sortASCByNumberOrText = (numberOrTextA: number | string, numberOrTextB: number | string): number => {
    if (typeof numberOrTextA === 'string' && typeof numberOrTextB === 'string') {
        if (numberOrTextA > numberOrTextB) {
            return 1
        }

        return numberOrTextA < numberOrTextB ? -1 : 0
    }

    if (typeof numberOrTextA === 'number' && typeof numberOrTextB === 'string') {
        return -1 // numbers are considered less than strings
    }

    if (typeof numberOrTextA === 'string' && typeof numberOrTextB === 'number') {
        return 1 // strings are considered greater than numbers
    }

    if (numberOrTextA > numberOrTextB) {
        return 1
    }

    return numberOrTextA < numberOrTextB ? -1 : 0
}
