import { AISuggestion } from '../../../../models/aiClassifications'
import { IMaterialModificationConflict } from '../../../../models/masterSetPlan'
import { IToolObject } from '../../../../models/tool'
import { ACTIVE_OPACITY_VALUE, INACTIVE_OPACITY_VALUE } from '../../../../shared/constants/colors'
import { capitalizeEachWord } from '../../../../utils/stringFormatters'
import { flash2D } from '../../../lib/animations'
import { IMUP2DDrawableLocation } from '../../../types'

type MetadataObject = IMUP2DDrawableLocation | AISuggestion | IToolObject | IMaterialModificationConflict

export default function addMetadataToPath(path: paper.Path, scaleFactor: string, metadata: MetadataObject): void {
    // Common style metadata
    path.data.opacityChangeVal = -0.02
    path.data.originalOpacityValue = path.fillColor?.alpha ?? path.strokeColor?.alpha ?? 1.0
    path.data.opacityValue = path.fillColor?.alpha ?? path.strokeColor?.alpha ?? 1.0
    path.data.originalStrokeColor = path.strokeColor
    path.data.originalStrokeWidth = path.strokeWidth
    path.data.activeOpacityValue = ACTIVE_OPACITY_VALUE
    path.data.inactiveOpacityValue = INACTIVE_OPACITY_VALUE

    // Animation metadata
    path.data.onFrameHandler = flash2D.bind(path)
    path.data.shapeHasChanged = false

    // Set scale factor
    path.data.scale = scaleFactor

    // Handle based on actual type
    if (isAISuggestion(metadata)) {
        // AI Suggestion metadata
        path.data.aiSuggestion = metadata
        path.data.shapeType = metadata.shape_type
    } else if (isToolObject(metadata)) {
        // Tool object metadata
        path.data.toolObject = metadata
        path.data.shapeType = metadata.type
        path.data.toolName = metadata.type
        path.data.isToolObject = true
    } else if (isLocation(metadata)) {
        // Location metadata (IMUP2DDrawableLocation)
        const {
            opening_group_id,
            drawable_id,
            drawing_type,
            label,
            settings,
            opening_location_id,
            shapeType,
            isActive,
            document_chunk_id,
            additionalData,
        } = metadata as IMUP2DDrawableLocation

        path.data.opening_group_id = opening_group_id
        path.data.drawable_id = drawable_id
        path.data.drawing_type = drawing_type
        path.data.document_chunk_id = document_chunk_id
        path.data.shapeType = shapeType
        path.data.title = `${label} ${capitalizeEachWord(settings.name.replace(/_/g, ' '))}`
        path.data.opening_location_id = opening_location_id
        path.data.isActive = isActive
        path.data.additionalData = additionalData
    } else if (isMaterialModificationConflict(metadata)) {
        path.data.materialModificationConflict = metadata
        path.data.shapeType = metadata.shape_type
    }
}

const isAISuggestion = (metadata: MetadataObject): metadata is AISuggestion => {
    return (metadata as AISuggestion).shape_type !== undefined
}

const isToolObject = (metadata: MetadataObject): metadata is IToolObject => {
    return (metadata as IToolObject).type !== undefined
}

const isLocation = (metadata: MetadataObject): metadata is IMUP2DDrawableLocation => {
    return (
        (metadata as IMUP2DDrawableLocation).opening_group_id !== undefined &&
        (metadata as IMUP2DDrawableLocation).opening_location_id !== undefined
    )
}

const isMaterialModificationConflict = (metadata: MetadataObject): metadata is AISuggestion => {
    return (metadata as IMaterialModificationConflict).material_location_id !== undefined
}
