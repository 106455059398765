import { DRAWABLE_TYPES, IMUP_WINDOW_AND_DOOR_TYPES } from '../constants/drawable-types'
import { FILTER_CARD_OPTIONS } from '../constants/filter-cards.constants'
import { isWildCard } from './drawable-groups-service'
import { DRAWABLE_GROUPPING_NAMES } from '../../components/markup/utils/constants/drawables-sort-order'
import { ActiveDrawable, DrawableCoordinate, GroupWithSettings, OpeningGroup } from '../../models/activeDrawable'
import { ActiveFloor } from '../../models/activeFloor'
import { capitalizeEachWord, prepareDrawableGroupTempType } from '../../utils/stringFormatters'

/** Schedule Code of Widow&Doors should be capitalized */
export const capitalizeScheduleCode = (groupName: string, drawableType: DRAWABLE_TYPES): string => {
    if (IMUP_WINDOW_AND_DOOR_TYPES.includes(drawableType) && groupName.includes('-')) {
        const nameAndCode = groupName.split('-')

        return nameAndCode[0] + groupName.replace(nameAndCode[0], '').toUpperCase()
    }

    return groupName
}
/**
 * Function which return title case on all words but not on lowers
 * @param str
 */
export const titleCase = (str: string): string => {
    // words that should keep lowercase
    const lowers = [
        'a',
        'an',
        'the',
        'and',
        'but',
        'or',
        'for',
        'nor',
        'as',
        'at',
        'by',
        'for',
        'from',
        'in',
        'into',
        'near',
        'of',
        'on',
        'onto',
        'to',
        'with',
    ]

    const splitStr = str.toLowerCase().split(' ')

    for (let i = 0; i < splitStr.length; i++) {
        if (!lowers.includes(splitStr[i])) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
        }
    }

    return splitStr.join(' ')
}

/**
 * Change "gable length" and "eave length" if drawableName contain it
 * Remove "l/f" from drawableName if exists
 * @param drawable
 */
export const handleDrawableName = (drawable): string => {
    const {
        type,
        settings: { name, folder, description },
    } = drawable

    if (!name) return ''

    const namesToChange = ['gable length', 'eave length']
    const removeFromName = ['l/f']

    let updatedName = name

    namesToChange.forEach((nameToChange) => {
        if (updatedName !== nameToChange && updatedName.includes(nameToChange)) {
            updatedName = nameToChange
        }
    })

    removeFromName.forEach((stringToRemove) => {
        if (updatedName.includes(stringToRemove)) {
            updatedName = updatedName.replace(stringToRemove, '')
        }
    })

    // Append Location to name for items that can be exterior or framing
    if (
        type === DRAWABLE_TYPES.ROOFING &&
        (name.startsWith('pv ') || name.startsWith('ev ') || name.startsWith('eave') || name.startsWith('gable'))
    ) {
        updatedName = (folder.startsWith('exteriors') ? 'Exteriors ' : 'Framing ') + updatedName
    }

    if (isWildCard(type)) {
        return `${name} ${description ?? ''}`
    }

    return capitalizeScheduleCode(capitalizeEachWord(updatedName), type)
}

export const hasDrawables = (drawableGroups: OpeningGroup[], page: ActiveFloor): boolean => {
    const groups = drawableGroups.filter((group) => group.openings.find((opening) => opening.floor_hash === page.hash))
    const isFound = groups.find((group) =>
        group.openings.find((o) => o.opening_locations.find((l) => l.document_chunk_id === page.document_chunk.id))
    )

    return !!isFound
}

export const prepareDrawableGroupTitle = (type: string): string => {
    const preparedType = type?.trim()?.toUpperCase()
    const drawableGroupName = DRAWABLE_GROUPPING_NAMES[preparedType]

    if (drawableGroupName) {
        return capitalizeEachWord(prepareDrawableGroupTempType(DRAWABLE_GROUPPING_NAMES[preparedType]))
    }

    return type
}

export const handleCheckedDrawables = (checkedDrawables): { [key: number]: boolean } => {
    // to avoid duplication of drawable id's, we check if opening label(opening-DRAWABLE_ID) exists on a key,
    // if exists we check if DRAWABLE_ID exists, if yes, we remove opening-DRAWABLE_ID, because it the same value
    // if DRAWABLE_ID not exists, that's mean we have grouped by Opening, and our keys will be opening-DRAWABLE_ID
    // to avoid wrong drawable id number, we add new key value with correct key and remove with the opening prefix.
    if (typeof checkedDrawables === 'object') {
        Object.keys(checkedDrawables).forEach((drawableSelected) => {
            if (drawableSelected.includes('opening') && checkedDrawables[drawableSelected.replace('opening-', '')]) {
                delete checkedDrawables[drawableSelected]
            } else if (
                drawableSelected.includes('opening') &&
                !checkedDrawables[drawableSelected.replace('opening-', '')]
            ) {
                checkedDrawables[drawableSelected.replace('opening-', '')] = checkedDrawables[drawableSelected]
                delete checkedDrawables[drawableSelected]
            }
        })
    }

    return checkedDrawables
}

export const handleLocationErrorMessage = (checkedDrawables): boolean => {
    if (typeof checkedDrawables === 'object') {
        return !Object.values(checkedDrawables).some((drawableSelected) => drawableSelected)
    }

    return checkedDrawables.some((drawableSelected) => drawableSelected)
}

export const getDrawablesOutOfGroups = (groups: OpeningGroup[] | DrawableCoordinate[]): ActiveDrawable[] => {
    const openings: ActiveDrawable[] = []

    groups.forEach((drawableGroup) => {
        drawableGroup.openings.forEach((opening) => openings.push(opening))
    })

    return openings
}

export const filterDrawableGroupsPerActiveTab = (groups: OpeningGroup[]): OpeningGroup[] => {
    return groups.filter((gr) => gr.drawablesPerTab && gr.drawablesPerTab.length)
}

/**
 * Filter either Geometry Groups or Older opening groups by their status
 * @param drawableGroups the groups to filter
 * @param filterCardValue the status that is currently being filtered on
 * @returns
 */
export function filterDrawableGroupsByFilter<T extends GroupWithSettings>(
    drawableGroups: T[],
    filterCardValue: string
): T[] {
    if (filterCardValue === FILTER_CARD_OPTIONS.doneCards.value) {
        return drawableGroups.filter(
            (drawableFilterCopy: GroupWithSettings) => drawableFilterCopy.settings?.isMarkedDone
        )
    } else if (filterCardValue === FILTER_CARD_OPTIONS.notDoneCards.value) {
        return drawableGroups.filter(
            (drawableFilterCopy: GroupWithSettings) => !drawableFilterCopy.settings?.isMarkedDone
        )
    }

    // if All selected just return drawableGroups
    return drawableGroups
}
