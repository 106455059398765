import { createAction, PayloadAction } from '@reduxjs/toolkit'
import isUndefined from 'lodash/isUndefined'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { postRotatedImages } from '../../../api/projects-api'
import { DocumentChunk } from '../../../models/documentChunk'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Image, Select, Workspace } from '../../lib/toolBoxes/2D'
import { selectActiveDocumentChunk, updateSingleDocumentChunk } from '../../slices/documents'
import { setImageRotationInProgress } from '../../slices/loading'

export const rotateImageLocalAction = createAction<{ degrees: number }>('rotateImageLocal')
export const rotateImageRemoteAction = createAction<{ degrees: number } | undefined>('rotateImageRemote')

export interface RotateImageResponse {
    message: string
    updatedImagesData: DocumentChunk[]
}

export function* handleRotateImageLocally(action: PayloadAction<{ degrees: number }>) {
    const manager: PaperManager = yield call(managers.get2DManager)

    if (!manager) return

    const imageTool: Image = yield call(manager.getTool, Image.NAME)

    yield call(imageTool.rotateImage, action.payload.degrees)
}

export function* handleRotateImageRemote(action: PayloadAction<{ degrees: number } | undefined>) {
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (!manager) return

    const imageTool: Image = yield call(manager.getTool, Image.NAME)

    try {
        const currentRotation = action.payload?.degrees || imageTool.getCurrentRotation()

        if (currentRotation !== 0) {
            yield put(setImageRotationInProgress(true))

            const currentDocumentChunk: DocumentChunk = yield select(selectActiveDocumentChunk)

            if (currentDocumentChunk) {
                const rotateResponse: RotateImageResponse = yield call(postRotatedImages, [
                    { ...currentDocumentChunk, rotateDegree: currentRotation },
                ])

                const updatedChunk = rotateResponse.updatedImagesData[0]

                // Rotate degree doesnt matter in the store but it is included as part of the response payload
                if (!isUndefined(updatedChunk['rotateDegree'])) {
                    delete updatedChunk['rotateDegree']
                }

                // Update the src URLs in the store with the new images
                yield put(updateSingleDocumentChunk(updatedChunk))

                const workspaceTool: Workspace = yield call(manager.getTool, Workspace.NAME)

                // Get the old raster image
                const raster: paper.Raster | null = yield call(workspaceTool.getPlanRaster)

                if (raster) {
                    // Remove old raster image
                    yield call([raster, 'remove'])

                    // Insert new raster image from response
                    yield call(imageTool.insertImage, updatedChunk.src)
                }

                yield call(imageTool.normalizeRotation)
                yield put(setImageRotationInProgress(false))
            } else {
                throw new Error('Error: No active document chunk')
            }
        }
    } catch (error) {
        // Undo the local rotation if the request was unsuccessful
        yield call(imageTool.undoRotate)
        yield call(console.error, error)
        yield put(setImageRotationInProgress(false))
    }

    yield call(manager.useTool, Select.NAME)
}

export function* watchForRotateImageLocal() {
    yield takeEvery(rotateImageLocalAction.type, handleRotateImageLocally)
}

export function* watchForRotateImageRemote() {
    yield takeLatest(rotateImageRemoteAction.type, handleRotateImageRemote)
}
