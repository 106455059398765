import { IModel, IStorey, ModelType } from '../../../types'

/**
 * Finds elements with the specified type
 * @param elementType the element's type
 * @param children to find the elements of type elementType
 * @returns the elements with type elementType
 */
function findElementsByType(elementType: string, children: IModel[]): IModel[] {
    const elements: IModel[] = []

    if (!children) return elements
    for (const child of children) {
        if (child.type.toUpperCase().includes(elementType.toUpperCase())) {
            elements.push(child)
        } else if (child.children) {
            const otherElements = findElementsByType(elementType, child.children)

            if (otherElements.length) {
                elements.push(...otherElements)
            }
        }
    }

    return elements
}

/**
 * Finds all the model's children recursively
 * @param source the source model to search
 * @returns the list of children
 */
function findAllChildrenFromModel(parent: IModel): IModel[] {
    const result: IModel[] = [...parent.children]

    parent.children.forEach((child) => {
        result.push(...findAllChildrenFromModel(child))
    })

    return result
}

/**
 * Finds elements by type in the given storeys
 * @param storeys
 * @param types to find
 * @returns nodes of the given types in the storeys
 */
function findElementsByTypeInStoreys(storeys: IStorey[], types: ModelType[]): IModel[] {
    const modelNodes: IModel[] = []

    storeys.forEach((storey) => {
        types.forEach((type) => {
            const nodes: IModel[] = findElementsByType(type, storey.children)

            modelNodes.push(...nodes.map((node) => ({ ...node, storeyName: storey.name })))
        })
    })

    return modelNodes
}

/**
 * Finds all the models ids of the specified type including their children recursively
 * @param source the source model to search
 * @param type the element's type
 * @param id specific element id to filter
 * @returns the id of each element with the specified type
 */
function findElementIdsByType(source: IModel[], type: string, id?: string): string[] {
    const ids: string[] = []
    const structures = !id
        ? findElementsByType(type, source)
        : findElementsByType(type, source).filter((s) => s.guid === id)

    ids.push(...structures.map((item) => item.guid))

    structures.forEach((structure) => {
        ids.push(...findAllChildrenFromModel(structure).map((item) => item.guid))
    })

    return ids
}

function generateConfigurationIdFromModel(model: IModel): string | null {
    if (model.designOptions?.revitId) {
        return `${model.designOptions.revitId}`
    }

    return null
}

function revitIdToConfigurationIdMap(root: IModel) {
    return findAllChildrenFromModel(root).reduce((mapping, node) => {
        const id = node.revitId

        if (id !== null) {
            mapping[id] = generateConfigurationIdFromModel(node)
        }

        return mapping
    }, {} as Record<string, string | null>)
}

function extractGeometryIDFromBaseId(customRevitIdBase: string | null) {
    if (!customRevitIdBase) return ''

    return customRevitIdBase.split(/-/g)[1]
}

const exportedFunctions = {
    findElementsByType,
    findElementIdsByType,
    findElementsByTypeInStoreys,
    generateConfigurationIdFromModel,
    revitIdToConfigurationIdMap,
    extractGeometryIDFromBaseId,
}

export default exportedFunctions
