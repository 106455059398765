import { createAction } from '@reduxjs/toolkit'
import { call, ForkEffect, put, select, takeEvery } from 'redux-saga/effects'

import { selectProject } from './createDrawableLocation'
import { duplicateDocumentChunk } from '../../../api/projects-api'
import { DocumentChunk } from '../../../models/documentChunk'
import { DocumentMapping } from '../../../models/documentMapping'
import { Project } from '../../../models/project'
import {
    initializeDocumentChunks,
    initializeProjectDocumentsFromChunks,
    selectDocumentChunks,
    selectDocumentMappings,
    updateDocumentMappings,
} from '../../slices/documents'

export const duplicateDocumentChunkAction = createAction<{ documentChunkId: number }>('duplicateDocumentChunk')

export function* handleDuplicateDocumentChunk({ payload }: ReturnType<typeof duplicateDocumentChunkAction>) {
    const documentChunks: DocumentChunk[] = yield select(selectDocumentChunks)
    const documentMappings: DocumentMapping[] = yield select(selectDocumentMappings)
    const project: Project = yield select(selectProject)

    try {
        const { document_chunk_mappings: documentMapping, ...documentChunk } = yield call(
            duplicateDocumentChunk,
            project.id,
            payload.documentChunkId
        )

        yield put(updateDocumentMappings([...documentMappings, documentMapping[0]]))

        yield put(initializeDocumentChunks([...documentChunks, documentChunk]))
        yield put(initializeProjectDocumentsFromChunks([...documentChunks, documentChunk]))
    } catch (e) {
        yield call(console.error, `Error on API duplication: ${e}`)

        yield put(updateDocumentMappings(documentMappings))

        yield put(initializeDocumentChunks(documentChunks))
        yield put(initializeProjectDocumentsFromChunks(documentChunks))
    }
}

export function* watchForDuplicateDocumentChunk(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(duplicateDocumentChunkAction.type, handleDuplicateDocumentChunk)
}
