import store from '../../../../stores'
import { setAltSelectedItems, updateSelectedPaperId } from '../../../slices/2D'
import { setActiveToolObjectId, updateToolObjectCoordinates } from '../../../slices/tools'
import { IMUP2DCoordinatesToUpdate } from '../../../types'

function saveToolObject(): (coordinatesToUpdate: IMUP2DCoordinatesToUpdate) => void {
    return function saveToolObject(this: paper.Path, coordinatesToUpdate) {
        store.dispatch(updateToolObjectCoordinates(coordinatesToUpdate))
    }
}

function selectToolObject(): () => void {
    return function selectDrawable(this: paper.Path) {
        store.dispatch(setActiveToolObjectId(this.data.toolObject.id))
        store.dispatch(updateSelectedPaperId(this.id))
    }
}

function deselectToolObject(): () => void {
    return function deselectDrawable(this: paper.Path) {
        store.dispatch(setActiveToolObjectId(null))
        store.dispatch(updateSelectedPaperId(null))

        // reset multiple selected items
        store.dispatch(setAltSelectedItems(null))
    }
}

export default function addSelectFunctionalityToToolObjects(item: paper.PathItem): void {
    item.data.onSave = saveToolObject().bind(item)
    item.data.onSelect = selectToolObject().bind(item)
    item.data.onDeselect = deselectToolObject().bind(item)
}
