import isEmpty from 'lodash/isEmpty'
import Paper from 'paper'

import AbstractToolBox from '../AbstractToolBox'
import * as Tools from './tools'
import { PaperToolBoxConfig, PaperToolConfig } from '../../../types'

/**
 * PaperToolBox.ts
 * Class responsible for setting up all tools in ./tools and connecting to Paper.js context
 */
export class PaperToolBox extends AbstractToolBox {
    private paper: typeof Paper

    constructor(config: PaperToolBoxConfig) {
        super(config)
        this.paper = config.paper
        Object.values(Tools)
            .filter((Tool) =>
                config.tools && !isEmpty(config.tools) ? config.tools.includes((Tool as any).NAME) : true
            )
            .forEach(this.set)
    }

    public set = (Tool: typeof Tools.PaperTool): void => {
        const config: PaperToolConfig = { mediator: this.mediator, paper: this.paper }
        const tool = new Tool(config)

        this.registerTool(tool)
    }
}

export default PaperToolBox
