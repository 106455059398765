import { DRAWABLE_UNITS_OF_MEASURE } from '../imup/types'
import { DRAWING_TYPES } from '../shared/constants/drawable-types'

export const getUnitOfMeasureByDrawingType = (drawingType) => {
    switch (drawingType) {
        case DRAWING_TYPES.SECTION:
        case DRAWING_TYPES.RADIUS:
            return DRAWABLE_UNITS_OF_MEASURE.line
        case DRAWING_TYPES.AREA:
            return DRAWABLE_UNITS_OF_MEASURE.area
        default:
            return DRAWABLE_UNITS_OF_MEASURE.count
    }
}
