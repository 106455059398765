import { createAction } from '@reduxjs/toolkit'
import { call, fork, put, select, StrictEffect, takeLatest } from 'redux-saga/effects'

import { handleCameraAndGroupsVisibility } from './handleCameraAndGroupsVisibility'
import {
    calculateMeshIdsToHighlight,
    selectAndHighlightRequiredMeshes,
    selectionPredicateGenerator,
} from './selectMesh'
import { setActiveDrawableGroupFromID } from '../../../actions/drawable'
import { ActiveDrawable } from '../../../models/activeDrawable'
import { DRAWABLE_TYPES } from '../../../shared/constants/drawable-types'
import managers from '../../lib/managers'
import { BabylonManager } from '../../lib/managers/BabylonManager'
import { Select } from '../../lib/toolBoxes/3D'
import { openingToMeshIdMappingSelector, updateSelectedOpeningGroup } from '../../slices/3D'
import { GeometryGroup, selectDrawableGroupsGeometries } from '../../slices/geometry'
import { TwoDSelectedItem } from '../../types'

export const selectOpeningGroup = createAction<{ singleRecord: TwoDSelectedItem; meshId: string }>('selectOpeningGroup')

export function* updateSelectedOpeningGroupAndHighlightRequiredMeshes(
    singleRecord: TwoDSelectedItem,
    meshId: string
): Generator<
    StrictEffect | (GeometryGroup | undefined) | ActiveDrawable,
    void,
    GeometryGroup[] & (GeometryGroup | undefined) & string[] & (BabylonManager | null) & ActiveDrawable[] & Select
> {
    // Update the currently selected opening group id and highlight the sibling meshes
    const openingIdsToMeshIdsMap: Record<string, string> | null = yield select(openingToMeshIdMappingSelector)

    yield put(updateSelectedOpeningGroup({ id: Number(singleRecord.groupId) }))
    yield put(setActiveDrawableGroupFromID(0, Number(singleRecord.openingId), Number(singleRecord.groupId), 1))

    const drawableGroups: GeometryGroup[] = yield select(selectDrawableGroupsGeometries)

    const activeDrawableGroup: GeometryGroup | undefined = drawableGroups.find(
        (group) => group.id === Number(singleRecord.groupId)
    )

    if (!activeDrawableGroup) return

    const siblingMeshIds: string[] = yield call(
        calculateMeshIdsToHighlight,
        activeDrawableGroup,
        meshId,
        openingIdsToMeshIdsMap
    )

    const manager: BabylonManager | null = yield call(managers.get3DManager)

    if (!manager) return

    const selectTool: Select = yield call(manager.getTool, Select.NAME)

    yield call(selectAndHighlightRequiredMeshes, siblingMeshIds, false, manager)
    yield call(selectTool.setSelectionPredicate, selectionPredicateGenerator(Number(singleRecord.groupId)))

    if (
        activeDrawableGroup.type === DRAWABLE_TYPES.ROOF_SYSTEM ||
        activeDrawableGroup.type === DRAWABLE_TYPES.FLOOR_SYSTEM
    ) {
        yield call(handleCameraAndGroupsVisibility, activeDrawableGroup)
    }
}

function* handleSelectedOpeningGroup({
    payload: { singleRecord, meshId },
}: ReturnType<typeof selectOpeningGroup>): Generator<StrictEffect, void, unknown> {
    yield fork(updateSelectedOpeningGroupAndHighlightRequiredMeshes, singleRecord, meshId)
}

export function* watchSelectedOpeningGroup(): Generator<StrictEffect, void, unknown> {
    yield takeLatest(selectOpeningGroup.type, handleSelectedOpeningGroup)
}
