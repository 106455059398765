import { createAction } from '@reduxjs/toolkit'
import { all, call, put, takeEvery } from 'redux-saga/effects'

import { deleteTemporaryDrawableGroups } from './deleteTemporaryDrawableGroups'
import {
    filterDrawableGroups,
    resetDrawableGroups,
    setActiveDrawable,
    setActiveDrawableGroup,
    setLocationErrorMessage,
} from '../../../actions/drawable'
import {
    resetNewCreatedDrawables,
    setFlag,
    setJoistDirection,
    setJoistDirectionOther,
    unsetActiveDrawableId,
} from '../../slices/2D'
import { toggleFlagForm } from '../../slices/materialFlags'
import { DRAW_TOOL_ACTION } from '../../types'

export const closeMaterialFormAction = createAction('closeMaterialForm')

export function* handleCloseMaterialForm() {
    try {
        yield call(deleteTemporaryDrawableGroups)

        yield all([
            put(unsetActiveDrawableId()),
            put(resetNewCreatedDrawables()),
            put(toggleFlagForm({ toggleForm: false })), // close the flag form when the form is closed
            put(setFlag(DRAW_TOOL_ACTION.NEW_OPENING)),
            put(setJoistDirectionOther(null)),
            put(setJoistDirection(null)),
            put(setActiveDrawableGroup(null)),
            put(setActiveDrawable(null, 0)),
            put(setLocationErrorMessage('')),
            put(resetDrawableGroups()),
            put(filterDrawableGroups()),
        ])
    } catch (error) {
        yield call(console.error, error)
    }
}

export function* watchForCloseMaterialForm() {
    yield takeEvery(closeMaterialFormAction.type, handleCloseMaterialForm)
}
