import PaperTool from '../paperTool/PaperTool'
import { Cursors, LineType, PaperToolConfig, Side } from '../../../../../types'

/**
 * Pitch.tool.tsx
 * Calculates distances between three points
 */
export class Pitch extends PaperTool {
    static NAME = 'PITCH'
    static CURSOR = Cursors.CROSSHAIR

    private static readonly PITCH_NUMBER = 12
    private static readonly PITCH_NUMBER_LENGTH = 144
    private static readonly PITCH_TRIANGLE_COLOR = '#2158a6'
    private static readonly PITCH_TRIANGLE_STROKE_WIDTH = 3
    private static readonly PITCH_TRIANGLE_JUSTIFICATION = 'justification'
    private static readonly PITCH_TRIANGLE_FONT_SIZE = 30
    private static readonly SPACE_FOR_PITCH_TRIANGLE = 400

    private path: paper.Path | null = null
    private points: paper.Group[] = []
    private pitchText: paper.PointText[] = []

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = Pitch.NAME
        this.cursor = Pitch.CURSOR
    }

    /**
     * Removes all generated paper items on cancellation
     */
    cancel = () => {
        this.path?.remove()
        // remove lingering points
        this.points.forEach((point: paper.Group) => {
            point.remove()
        })
        // remove the text
        this.pitchText.forEach((text: paper.PointText) => {
            text.remove()
        })

        // reset local vars
        this.path = null
        this.points = []
        this.pitchText = []
    }

    /**
     * Determinate the line direction based on the line points
     * @param point1DeltaX
     * @param point1DeltaY
     * @param point2DeltaX
     * @param point2DeltaY
     */
    private checkLineDirection = (
        point1DeltaX: number,
        point1DeltaY: number,
        point2DeltaX: number,
        point2DeltaY: number
    ): LineType => {
        const x: number = Math.abs(point1DeltaX - point2DeltaX)
        const y: number = Math.abs(point1DeltaY - point2DeltaY)

        return x > y ? LineType.HORIZONTAL : LineType.VERTICAL
    }

    /**
     * Function which set the LineType and scaled length
     * @param hypotenuse
     * @param curves
     */
    private prepareCurves = (hypotenuse: paper.Curve, curves: paper.Curve[]) => {
        return curves.map((line: paper.Curve) => {
            return {
                ...line,
                direction:
                    line.index === hypotenuse.index
                        ? LineType.DIAGONAL
                        : this.checkLineDirection(line.point1.x, line.point1.y, line.point2.x, line.point2.y),
                scaledLength: line.length / this.scaleFactor,
            }
        })
    }

    /**
     * Formula to calculate the Pitch number
     * @param verticalLine
     * @param horizontalLine
     */
    private calculatePitch = (verticalLine: number, horizontalLine: number): number => {
        return Math.round((verticalLine * Pitch.PITCH_NUMBER) / horizontalLine)
    }

    /**
     * Determinate on which side the pitch triangle will be displayed based on hypotenuse points
     * Possible sides: left, right
     * @param point1
     * @param point2
     */
    private calculatePitchDrawSide = (point1, point2): Side => {
        // based on the line points, we can know on which side we can draw pitch triangle
        return (point1.y > point2.y && point1.x < point2.x) || (point1.y < point2.y && point1.x > point2.x)
            ? Side.LEFT
            : Side.RIGHT
    }

    /**
     * Depend on which side we need to draw a pitch triangle, we should add additional space
     * @param pitchDrawSide
     * @param x
     * @param space
     */
    private calculatePositionBasedOnSide = (pitchDrawSide: Side, x: number, space: number): number => {
        return pitchDrawSide === Side.LEFT ? x - space : x + space
    }

    /**
     * Calculate the initial position of pitch triangle based on initial triangle and calculated
     * pitch triangle hypotenuse length
     * @param hypotenuse
     * @param pitchTriangleHypotenuseLineLength
     */
    private calculateInitialPitchTrianglePosition = (
        hypotenuse: paper.Curve,
        pitchTriangleHypotenuseLineLength: number
    ): number => {
        const { point1, point2, length: hypotenuseLength } = hypotenuse

        return (point1.y > point2.y && point1.x < point2.x) || (point1.y > point2.y && point1.x > point2.x)
            ? hypotenuseLength / 2 + pitchTriangleHypotenuseLineLength / 2
            : hypotenuseLength / 2 - pitchTriangleHypotenuseLineLength / 2
    }

    /**
     * Calculate pitch triangle points and make a lines
     * @param hypotenuse
     * @param pitchDrawSide
     * @param scale
     * @param pitchTriangleVerticalLineLength
     * @param pitchTriangleHypotenuseLineLength
     */
    private pitchTrianglePoints = (
        hypotenuse: paper.Curve,
        pitchDrawSide: Side,
        scale: number,
        pitchTriangleVerticalLineLength: number,
        pitchTriangleHypotenuseLineLength: number
    ): paper.Point[] => {
        // initial point is half of draw triangle hypotenuse plus half scaled hypotenuse divided
        // used to make an initial point for pitch triangle
        const initialPoint = hypotenuse.getPointAt(
            this.calculateInitialPitchTrianglePosition(hypotenuse, pitchTriangleHypotenuseLineLength * scale)
        )

        // initial horizontal point of pitch triangle
        const point1 = {
            ...initialPoint,
            x: this.calculatePositionBasedOnSide(pitchDrawSide, initialPoint.x, Pitch.SPACE_FOR_PITCH_TRIANGLE * scale),
            y: initialPoint.y - Pitch.SPACE_FOR_PITCH_TRIANGLE * scale,
        } as paper.Point

        // based on the PITCH_NUMBER_LENGTH set the second horizontal point
        // and create a line between point1 and point2
        const point2 = {
            ...point1,
            x: this.calculatePositionBasedOnSide(pitchDrawSide, point1.x, Pitch.PITCH_NUMBER_LENGTH),
            y: point1.y,
        } as paper.Point

        // based on pitch triangle vertical line length, set the next point
        // vertical line will be drawn
        const point3 = {
            ...point2,
            x: point2.x,
            y: point2.y + pitchTriangleVerticalLineLength,
        } as paper.Point

        // point1 in the end is needed to draw point at the start and make hypotenuse line
        return [point1, point2, point3, point1]
    }

    /**
     * Display the static pitch number (12) in the center of horizontal pitch triangle
     * @param centerHorizontalLine
     */
    private pitchTriangleStaticNumberText = (centerHorizontalLine: paper.Point): void => {
        const pitchStaticNumberText = new this.paper.PointText(
            new this.paper.Point({
                ...centerHorizontalLine,
                y: centerHorizontalLine.y - 10,
            })
        )

        pitchStaticNumberText.justification = Pitch.PITCH_TRIANGLE_JUSTIFICATION
        pitchStaticNumberText.fillColor = new this.paper.Color(Pitch.PITCH_TRIANGLE_COLOR)
        pitchStaticNumberText.content = String(Pitch.PITCH_NUMBER)
        pitchStaticNumberText.fontSize = Pitch.PITCH_TRIANGLE_FONT_SIZE

        this.pitchText.push(pitchStaticNumberText)
    }

    /**
     * Display calculated pitch value in the center of vertical pitch triangle
     * @param centerVerticalLine
     * @param pitchDrawSide
     * @param pitchValue
     */
    private pitchTrianglePitchNumberText = (
        centerVerticalLine: paper.Point,
        pitchDrawSide: Side,
        pitchValue: number
    ): void => {
        const calculatedPitchNumberText = new this.paper.PointText(
            new this.paper.Point({
                ...centerVerticalLine,
                x: this.calculatePositionBasedOnSide(pitchDrawSide, centerVerticalLine.x, 30),
            })
        )

        calculatedPitchNumberText.justification = Pitch.PITCH_TRIANGLE_JUSTIFICATION
        calculatedPitchNumberText.fillColor = new this.paper.Color(Pitch.PITCH_TRIANGLE_COLOR)
        calculatedPitchNumberText.content = String(pitchValue)
        calculatedPitchNumberText.fontSize = Pitch.PITCH_TRIANGLE_FONT_SIZE
        calculatedPitchNumberText.locked = true

        this.pitchText.push(calculatedPitchNumberText)
    }

    /**
     * Display the static pitch number and pitch calculated value
     * @param curves
     * @param pitchDrawSide
     * @param pitchValue
     */
    private drawPitchTriangleText = (curves: paper.Curve[], pitchDrawSide: Side, pitchValue: number): void => {
        this.pitchTriangleStaticNumberText(curves[0].getPointAt(curves[0].length / 2))

        this.pitchTrianglePitchNumberText(curves[1].getPointAt(curves[1].length / 2), pitchDrawSide, pitchValue)
    }

    onMouseDrag = (event: paper.ToolEvent): void => {
        this.toolPanning(event)
    }

    onMouseUp = (): void => {
        this.setState('common', { cursor: Pitch.CURSOR }) // resets the cursor in case panning was done
    }

    onMouseDown = (event: paper.ToolEvent): void => {
        if (this.isPanningClick(event)) return

        // create a new segment if segment doesn't exist
        if (!this.path) {
            this.path = new this.paper.Path()
            this.path.strokeColor = new this.paper.Color(this.measureStrokeColor)
            this.path.strokeWidth = this.measureStrokeWidth
            this.path.strokeScaling = false // maintain the same stroke width regardless of zoom; see: http://paperjs.org/reference/style/#strokescaling
            this.path.locked = true
        }

        // if there are more than 3 points, clear the path and linesLength array
        if (this.path.segments.length > 1) {
            this.path.remove()
            this.points.forEach((group) => group.remove())
            this.pitchText.forEach((group) => group.remove())
            this.path = null

            return
        }

        this.points.push(this.constructCrosshairMarker(event.point))
        this.path.add(event.point)

        // when hypotenuse is drawn calculate the bottom point and create a triangle
        if (this.path.curves.length === 1) {
            const { point1, point2 } = this.path.curves[0]

            const bottomPoint = point1.y > point2.y ? { x: point2.x, y: point1.y } : { x: point1.x, y: point2.y }

            this.points.push(this.constructCrosshairMarker(bottomPoint as paper.Point))

            // draw bottom point in triangle
            this.path.add(bottomPoint as paper.Point)

            // draw last line to make a triangle
            this.path.add({ x: this.path.segments[0].point.x, y: this.path.segments[0].point.y } as paper.Point)
        }

        // when we have all lines to calculate the pitch triangle
        if (this.path.curves.length === 3) {
            const hypotenuse = this.path.curves[0]

            // preparedCurves used to get the line type and scaled length
            const preparedCurves = this.prepareCurves(hypotenuse, this.path.curves)

            const verticalLine = preparedCurves.find((line) => line.direction === LineType.VERTICAL)
            const horizontalLine = preparedCurves.find((line) => line.direction === LineType.HORIZONTAL)

            if (verticalLine && horizontalLine) {
                // clear previous lines
                this.path.remove()
                this.path = null
                this.points.forEach((group) => group.remove())

                const scale = horizontalLine.scaledLength / Pitch.PITCH_NUMBER_LENGTH
                const pitchValue = this.calculatePitch(verticalLine.scaledLength, horizontalLine.scaledLength)
                const pitchDrawSide = this.calculatePitchDrawSide(hypotenuse.point1, hypotenuse.point2)

                // based on pitch value, we can get the length of vertical line in pitch triangle
                // when hypotenuse line was draw vertical and pitch value is too big, instead draw long line,
                // make small triangle, same behaviour for the horizontal line
                const pitchTriangleVerticalLineLength =
                    pitchValue >= 30 || pitchValue === 0 ? 90 : Pitch.PITCH_NUMBER * pitchValue

                // calculate the pitch triangle hypotenuse length
                const pitchTriangleHypotenuseLineLength = Math.sqrt(
                    Math.pow(Pitch.PITCH_NUMBER_LENGTH, 2) + Math.pow(pitchTriangleVerticalLineLength, 2)
                )

                // create a pitch triangle based on calculated points
                this.path = new this.paper.Path(
                    this.pitchTrianglePoints(
                        hypotenuse,
                        pitchDrawSide,
                        scale,
                        pitchTriangleVerticalLineLength,
                        pitchTriangleHypotenuseLineLength
                    )
                )
                this.path.strokeColor = new this.paper.Color(Pitch.PITCH_TRIANGLE_COLOR)
                this.path.strokeWidth = Pitch.PITCH_TRIANGLE_STROKE_WIDTH
                this.path.locked = true

                // display pitch triangle text
                this.drawPitchTriangleText(this.path.curves, pitchDrawSide, pitchValue)
            }
        }
    }
}

export default Pitch
