import { PathTool } from '../path/Path.tool'
import { convertAbsoluteDistanceToFormattedString } from '../../../../../../components/markup/utils/helpers'
import { DRAWING_TYPES } from '../../../../../../shared/constants/drawable-types'
import { applyScaleFactorToPathLength } from '../../../../../../utils/calculations/scaleConversion/scaleConversion'
import { Coordinates2D, MouseButtonCodes, PaperToolConfig, Vector2D } from '../../../../../types'

export class RadiusLine extends PathTool {
    static NAME = 'RADIUS LINE'

    private static readonly NUM_POINTS_IN_LINE = 3
    private lineAndCircleColor: paper.Color = new this.paper.Color('blue')
    private linePoints: Coordinates2D = []
    private temporaryPointObjects: paper.Group[] = []
    private temporaryPath: paper.Path | null = null

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = RadiusLine.NAME
    }

    private cleanUpPoints = (): void => {
        this.temporaryPointObjects.forEach((path) => {
            path.remove()
        })
        this.temporaryPointObjects = []
        this.linePoints = []
    }

    /**
     * on cancel, if there is
     * any stray single points
     * clear those points
     */
    cancel = (): void => {
        this.cleanUpPoints()
        this.temporaryPath?.remove() // remove the temporary drawing path
        this.setState('common', { cursor: this.cursor, tooltip: { title: '', visible: false, color: '#000000' } })
    }

    /**
     * on mouse move draw a new line that represents what the created line would look like
     * this helps the user understand where the line is
     */
    public onMouseMove = (event: paper.ToolEvent): void => {
        if (this.linePoints.length) {
            // remove the previous line that is no longer relevant
            this.temporaryPath?.remove() // remove the temporary drawing path

            const newPoint = event.point

            if (this.linePoints.length === RadiusLine.NUM_POINTS_IN_LINE - 1) {
                this.temporaryPath = new this.paper.Path([
                    this.linePoints[0],
                    this.temporaryPointObjects[1].position,
                    newPoint,
                ])
            } else {
                this.temporaryPath = new this.paper.Path([this.temporaryPointObjects[0].position, newPoint])
            }

            this.temporaryPath.strokeColor = this.lineAndCircleColor
            this.temporaryPath.strokeWidth = this.strokeWidth
            this.mediator.mediate('common', {
                tooltip: {
                    title: `${convertAbsoluteDistanceToFormattedString(
                        applyScaleFactorToPathLength({
                            pxValue: this.temporaryPath.length,
                            scaleFactor: this.scaleFactor,
                            dpi: this.getActiveDocumentChunk()?.dpi ?? null,
                            xCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_x ?? 1,
                            yCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_y ?? 1,
                            coordinates: this.linePoints,
                            pdfScale: this.getActiveDocumentChunk()?.pdf_scale ?? 1,
                        })
                    )}`,
                    visible: true,
                    color: '#000000',
                },
            })
        }
    }

    /**
     * On mouse up change the cursor back to the default
     * of the tool
     */
    onMouseUp = () => {
        this.setState('common', { cursor: this.cursor, tooltip: { title: '', visible: false, color: '#000000' } })
    }

    /**
     * On mouse drag if it is right click
     * or middle click then pan
     * @param event the paper mouse event
     * @returns
     */
    onMouseDrag = (event) => {
        this.toolPanning(event)
    }

    /**
     * Draw points on mouse down and once 3 points are
     * drawn create a path drawable and trigger the event
     * that will create a new drawable location from that
     * line, after that event is fired cleanup the points
     * previously drawn
     * @param event
     */
    public onMouseDown = (event: paper.ToolEvent): void => {
        // indicate pan is active on right click
        const clickCode = event['event']['button']

        if (this.isPanningClick(event)) return
        //this.path.add(newPoint)

        if (clickCode === MouseButtonCodes.Left) {
            this.temporaryPointObjects.push(this.renderPoint(event.point))

            const newPoint = event.point

            this.linePoints.push([newPoint.x, newPoint.y])
            if (this.linePoints.length === RadiusLine.NUM_POINTS_IN_LINE) {
                this.temporaryPath?.remove()
                // Radius Line coordinates are stored in the following order: start, center, end
                const newPath = this.createArc(this.linePoints[0], this.linePoints[1], this.linePoints[2])

                newPath.strokeColor = this.lineAndCircleColor
                newPath.strokeWidth = this.strokeWidth
                if (newPath.data) newPath.data.shapeType = DRAWING_TYPES.RADIUS
                this.cleanUpPoints()
                this.setState('2D', { drawablesToCreate: [newPath.id] })
            } else {
                this.setScaleFromPointClick(newPoint)
            }
        }
    }

    /**
     * Creates a new paper Arc-Path from a collection of 2D Coords: start, center, end points
     * @param startPoint
     * @param centerPoint
     * @param endPoint
     * @returns
     */
    public createArc = (startPoint: Vector2D, centerPoint: Vector2D, endPoint: Vector2D): paper.Path => {
        const newPath = new this.paper.Path()
        // Move the path to the starting point of the arc

        newPath.moveTo(new this.paper.Point(startPoint))
        // Draw the arc
        newPath.arcTo(new this.paper.Point(centerPoint), new this.paper.Point(endPoint))

        return newPath
    }
}
