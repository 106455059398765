import { createAction } from '@reduxjs/toolkit'
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects'

import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Workspace } from '../../lib/toolBoxes/2D'
import { setRegionLinesVisibility } from '../../slices/tools'
import { REGION_ENUMS } from '../../types'

export const toggleRegionLinesVisibilityAction = createAction<boolean>('toggleRegionLinesVisibility')

export function* handleToggleRegionLinesVisibility({
    payload: isRegionLinesVisible,
}: ReturnType<typeof toggleRegionLinesVisibilityAction>) {
    const paperManager: PaperManager | null = yield call(managers.get2DManager)

    if (!paperManager) return

    const workspaceTool: Workspace = yield call(paperManager.getTool, Workspace.NAME)

    yield put(setRegionLinesVisibility(isRegionLinesVisible))

    const regionPaths: paper.Item[] = yield call(
        workspaceTool.getItemsWithCriteria,
        'data',
        (data) => data.shapeType === REGION_ENUMS.TYPE
    )

    // check if the state is different from previous
    if (regionPaths[0]?.visible !== isRegionLinesVisible) {
        regionPaths.forEach((r) => {
            // hide/show region lines
            r.visible = isRegionLinesVisible

            // hide/show labels
            const labelItem = workspaceTool.getItemWithPaperId<paper.TextItem>(r.data.labelId)

            if (labelItem) {
                labelItem.visible = isRegionLinesVisible
            }
        })
    }
}

export function* watchForToggleRegionLinesVisibility(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(toggleRegionLinesVisibilityAction.type, handleToggleRegionLinesVisibility)
}
