import { UiSchema } from '@rjsf/core'
import { JSONSchema7 } from 'json-schema'

import { TOOL_TYPE_ENUMS } from '../imup/types'
import { AzureFeatureFlag } from '../models/azureFeatureFlags'
import { DRAWABLE_TYPES } from '../shared/constants/drawable-types'
import { advanced_settings_helper } from '../utils/formSchema/formSchema-helper-functions'

// TODO: split advanced settings ui schema for each material
// each key is a section with fields
const advancedSettingsFieldKeys = [
    'studs',
    'top_plates',
    'bottom_plates',
    'wall_blocking',
    'wall_bracing',
    'treated_plates',
    'fire_blocking',
    'joist',
    'floor_truss',
    'rimboard',
    'rafters',
    'post',
    'ledger',
    'blocking',
    'rafter_tail',
    'fascia_boards_1',
    'fascia_boards_2',
    'ladder_framing',
    'bracing',
    'beam',
    'ledger_enhancement',
    'sub_fascia',
    'top_plate',
    'bottom_plate',
    'side_plate',
]

// TODO: keep it as function since collapse/expand need new point in a memory
// keep dry, fire_rated and finger_jointed since it's currently used to collapse/expand advanced settings section
const advancedSettingsFields = () => {
    return {
        species: {
            'ui:placeholder': 'Select Species',
        },
        grade: {
            'ui:placeholder': 'Select Grade',
        },
        thickness: {
            'ui:placeholder': 'Select Thickness',
        },
        dry: {},
        fire_rated: {},
        finger_jointed: {},
    }
}

const advancedSettings: UiSchema = {
    advanced_settings: {
        'ui:widget': 'hidden',
        ...advancedSettingsFieldKeys.reduce((acc, field) => {
            acc[field] = {
                'ui:field': 'customTitle',
                ...advancedSettingsFields(),
            }

            return acc
        }, {} as Record<string, UiSchema>),
    },
}

/**
 * This will add missing properties to UI schemas , that we could hide them
 *
 * @param completeUISchema
 * @param completeSchema
 */
const updateUISchemaWithConditionalProperties = (completeUISchema: UiSchema, completeSchema: JSONSchema7): UiSchema => {
    advanced_settings_helper.addConditionalProperties(completeUISchema, completeSchema)

    // add missing properties
    for (const prop in completeSchema.properties) {
        if (!completeUISchema[prop]) {
            completeUISchema[prop] = {}
        }
    }

    return completeUISchema
}

/**
 * This will add advanced settings to UI schemas if applicable
 *
 * @param uiSchema
 * @param type
 * @param azureFeatureFlags
 * @param completeSchema
 */
export const addAdvancedSettingsToSchemaByType = (
    uiSchema: UiSchema,
    type: DRAWABLE_TYPES | TOOL_TYPE_ENUMS,
    azureFeatureFlags: AzureFeatureFlag[],
    completeSchema: any
): UiSchema => {
    if (advanced_settings_helper.isAdvancedSettingsEnabled(type, azureFeatureFlags)) {
        let completeUISchema = {
            ...uiSchema,
            ...advancedSettings,
        }

        //add missing properties from schema to UI schema
        completeUISchema = updateUISchemaWithConditionalProperties(completeUISchema, completeSchema)

        return completeUISchema
    }

    return uiSchema
}

/**
 * This will remove all advanced settings data from formData is the conditions in schema are not allow to see advanced settings
 *
 * @param formData
 * @param schema
 */
export const removeAdvancedSettingsDataIfConditionTurnedOff = (formData, schema) => {
    const res = advanced_settings_helper.getVisibleProperties(formData, schema)

    for (const property in advancedSettings) {
        if (!res[property]) {
            delete formData[property]
        }
    }

    return formData
}
