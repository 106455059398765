import isNull from 'lodash/isNull'
import { call, takeLatest } from 'redux-saga/effects'

import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Overlay } from '../../lib/toolBoxes/2D/tools'
import { updateOverlayColor } from '../../slices/2D'

export function* handleUpdateOverlayColor(action: ReturnType<typeof updateOverlayColor>) {
    const { id, color } = action.payload
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (isNull(manager)) return

    const [overlayTool]: [Overlay] = yield call(manager.getTools, [Overlay.NAME])

    yield call(overlayTool.changeOverlayColor, id, color)
}

export function* watchForSelectingNewImageOverlayColor() {
    yield takeLatest(updateOverlayColor.type, handleUpdateOverlayColor)
}
