import { ActionReducerMapBuilder, createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import isArray from 'lodash/isArray'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'

import { UpdateDrawableMeasurements } from '../sagas/2D/updateDrawableCoordinates'
import { NormalizedDocumentChunk, selectActiveFloorId } from './documents'
import {
    ACTIVATE_ONLY_CLICKED_DRAWABLE_GROUP_BY_TYPE,
    DELETE_OPENING_GROUPS,
    FETCH_OPENING_GROUPS_SUCCESS,
    SET_ACTIVE_DRAWABLE_GROUP,
    TOGGLE_ACTIVE_DRAWABLE,
    TOGGLE_DRAWABLE_GROUP,
    TOGGLE_DRAWABLE_GROUPS_BY_TYPE,
    TOGGLE_DRAWABLE_GROUPS_VISIBILITY,
    UPDATE_DRAWABLES_MEASUREMENTS,
    UPDATE_JOIST_LINES,
    UPDATE_OPENING_GROUPS_SUCCESS,
} from '../../actions/drawable'
import { UpdateOpeningGroupApiResponse } from '../../api/api-helper'
import { Opening, Opening_location, OpeningAPI, OpeningGroupAPI } from '../../models/activeDrawable'
import { DRAWABLE_TYPES } from '../../shared/constants/drawable-types'
import IndexableObject from '../../shared/constants/general-enums/indexableObject'
import { PROJECT_PAGE_TAB_VALUES } from '../../shared/constants/project-settings-names'
import { RootState } from '../../stores'

/** TYPES AND INTERFACES */

export type Geometry = Omit<Opening, 'id' | 'additional_data' | 'opening_group_id' | 'outputs'> &
    Omit<Opening_location, 'document_chunk'> & {
        geometryGroupId: number
        openingAdditionalData: Record<string, any>
        isActive?: boolean
        openingSettings: Record<string, any>
        is_marked_done: boolean | null
    }

export type GeometryActiveDictionary = Record<number, Record<number, boolean>>

export type GeometricDrawable = Omit<OpeningAPI, 'outputs'> & { isActive?: boolean; is_marked_done: boolean | null }

export type GeometryGroupWithTempType = GeometryGroup & { tempType?: string }

export type ActiveGeometryGroup = Omit<OpeningGroupAPI, 'openings'> & {
    drawables: GeometricDrawable[]
    openings: GeometricDrawable[]
}

export type GeometryGroup = Omit<OpeningGroupAPI, 'openings'> & {
    drawablesPerTab: Omit<OpeningAPI, 'outputs'>[]
    openings: GeometricDrawable[]
    isActive: Record<number, boolean>
}

export type GeometryState = {
    angle: number
    width: number
    height: number
    activeGeometryGroupID: number | null
    geometryGroupActiveRecord: GeometryActiveDictionary
    geometries: Geometry[]
    allGroupIds: number[]
    groupToCreate: ActiveGeometryGroup | null
}

export const initialGeometryState: GeometryState = {
    angle: 0,
    width: 0,
    height: 0,
    activeGeometryGroupID: null,
    geometryGroupActiveRecord: {},
    geometries: [],
    allGroupIds: [],
    groupToCreate: null,
}

/** OLD STORE ACTIONS TO HOOK INTO */

export const gotOpeningGroups = createAction<OpeningGroupAPI[]>(FETCH_OPENING_GROUPS_SUCCESS)
export const setActiveGroup = createAction<{ activeDrawableGroup: any | []; activeDrawableId?: number | number[] }>(
    SET_ACTIVE_DRAWABLE_GROUP
)
export const updateGeometries = createAction<{ openingGroups: UpdateOpeningGroupApiResponse }>(
    UPDATE_OPENING_GROUPS_SUCCESS
)

export const deleteGeometries = createAction<{ openingGroupsToDelete: OpeningGroupAPI[] }>(DELETE_OPENING_GROUPS)

export const updateGeometryMeasurements = createAction<UpdateDrawableMeasurements>(UPDATE_DRAWABLES_MEASUREMENTS)
export const toggleGeometryGroup = createAction<{ drawables: any | []; activeChunkId: number | undefined }>(
    TOGGLE_DRAWABLE_GROUP
)
export const toggleGeometryGroupByType = createAction<{ type: any[]; activeChunkId: number | undefined }>(
    TOGGLE_DRAWABLE_GROUPS_BY_TYPE
)
export const activateSingleGeometryGroupByType = createAction<{ type: any[]; activeChunkId: number | undefined }>(
    ACTIVATE_ONLY_CLICKED_DRAWABLE_GROUP_BY_TYPE
)
export const resetGeometryGroupVisibility = createAction<{ isShowAllTabs: boolean }>(TOGGLE_DRAWABLE_GROUPS_VISIBILITY)
export const toggleActiveGeometries = createAction<{ drawable_id: number[] | number }>(TOGGLE_ACTIVE_DRAWABLE)
export const updateGeometryJoistLines = createAction<{ res: OpeningGroupAPI }>(UPDATE_JOIST_LINES)

/** UTILITY FUNCTIONS */

const convertOpeningsAndLocationsToGeometries = (openingGroups: OpeningGroupAPI[]): Geometry[] => {
    return openingGroups.flatMap((openingGroup) =>
        openingGroup.openings.flatMap((opening) => {
            return opening.opening_locations.map((loc) => {
                const newGeometry: Geometry = {
                    location: loc.location,
                    document_chunk_id: loc.document_chunk_id,
                    coordinates: loc.coordinates,
                    cutouts: loc.cutouts,
                    additional_data: loc.additional_data,
                    src: loc.src,
                    floor_hash: loc.floor_hash,
                    id: loc.id,
                    opening_id: loc.opening_id,
                    region_id: loc.region_id,
                    three_d_identifier: loc.three_d_identifier,
                    project_id: opening.project_id,
                    openingAdditionalData: opening.additional_data,
                    geometryGroupId: openingGroup.id,
                    label: opening.label,
                    order: opening.order,
                    settings: openingGroup.settings,
                    remarks: openingGroup.remarks,
                    configuration: openingGroup.configuration,
                    color: openingGroup.color?.length === 0 ? null : openingGroup.color,
                    type: openingGroup.type,
                    openingSettings: opening.settings,
                    is_marked_done: openingGroup.is_marked_done,
                    ai_suggestion_id: opening.ai_suggestion_id,
                }

                return newGeometry
            })
        })
    )
}

const convertGeometriesToGeometricDrawable = (
    documentChunks: NormalizedDocumentChunk[] | null,
    geometries: Geometry[]
): { drawables: GeometricDrawable[]; groupSettings: Record<string, any> } => {
    if (isNull(documentChunks)) return { drawables: [], groupSettings: {} }

    let groupSettings = {}
    const groupedOpenings: Record<number, number> = {}

    const geometriesHash = {}

    for (const geometry of geometries) {
        if (!(geometry.opening_id in geometriesHash)) {
            geometriesHash[geometry.opening_id] = []
        }
        geometriesHash[geometry.opening_id].push(geometry)
    }

    const geometricDrawables = geometries.reduce((drawables, geometry) => {
        if (!groupedOpenings[geometry.opening_id]) {
            const geometriesInOpening = geometriesHash[geometry.opening_id]

            groupSettings = geometriesInOpening[0].settings
            const isUndefinedActive = geometriesInOpening.some((geo) => isUndefined(geo.isActive))

            const geoDrawable: GeometricDrawable = {
                id: geometry.opening_id,
                additional_data: geometry.openingAdditionalData,
                type: geometry.type,
                remarks: geometry.remarks,
                color: geometry.color,
                configuration: geometry.configuration,
                floor_hash: geometry.floor_hash,
                project_id: geometry.project_id,
                label: geometry.label,
                order: geometry.order,
                settings: geometry.openingSettings as any,
                opening_group_id: geometry.geometryGroupId,
                opening_locations: geometriesInOpening.map((geo) => {
                    const chunk = documentChunks.find((doc) => doc.id === geo.document_chunk_id)

                    return {
                        id: geo.id,
                        document_chunk_id: geo.document_chunk_id,
                        document_chunk: chunk
                            ? {
                                  id: chunk.id,
                                  page: chunk.page,
                                  project_document_id: chunk.project_document_id,
                                  src: chunk.src,
                              }
                            : null,
                        coordinates: geo.coordinates,
                        cutouts: geo.cutouts,
                        additional_data: geo.additional_data,
                        location: geo.location,
                        floor_hash: geo.floor_hash,
                        region_id: geo.region_id,
                        opening_id: geo.opening_id,
                        three_d_identifier: geo.three_d_identifier,
                    } as Opening_location
                }),
                is_marked_done: geometry.is_marked_done,
                ai_suggestion_id: geometry.ai_suggestion_id,
            }

            if (!isUndefinedActive) {
                geoDrawable.isActive = geometriesInOpening.every((geo) => geo.isActive === true)
            }

            drawables.push(geoDrawable)
            groupedOpenings[geometry.opening_id] = geometry.opening_id
        }

        return drawables
    }, [] as GeometricDrawable[])

    return { drawables: geometricDrawables, groupSettings: groupSettings }
}

/** OLD ACTIONS HANDLERS */

function handleGotOpeningGroups(state: GeometryState, { payload }: PayloadAction<OpeningGroupAPI[]>) {
    state.geometries = convertOpeningsAndLocationsToGeometries(
        payload.filter((group) => group.type !== DRAWABLE_TYPES.HIGHLIGHTER && group.type !== DRAWABLE_TYPES.NOTE)
    )
    state.allGroupIds = [...new Set(state.geometries.map((geo) => geo.geometryGroupId))]
}

function handleSetActiveGroup(
    state: GeometryState,
    { payload }: PayloadAction<{ activeDrawableGroup: any | []; activeDrawableId?: number | number[] }>
) {
    if (isArray(payload.activeDrawableGroup)) {
        state.activeGeometryGroupID = null
    } else {
        state.activeGeometryGroupID = payload.activeDrawableGroup.id

        state.geometries = state.geometries.map((geo) => {
            geo.isActive = payload.activeDrawableId
                ? geo.opening_id === payload.activeDrawableId ||
                  (isArray(payload.activeDrawableId) && payload.activeDrawableId.includes(geo.opening_id))
                : geo.geometryGroupId === payload.activeDrawableGroup.id

            return geo
        })
    }
}

function handleUpdateGeometries(
    state: GeometryState,
    { payload }: PayloadAction<{ openingGroups: UpdateOpeningGroupApiResponse }>
) {
    const openingIdsAffected: Set<number> = new Set()
    const geoOpeningIdsAlreadyAddedByNewGeometries: Record<string, boolean> = {}

    // Opening Locations Geometries (new)
    const newGeometries: Geometry[] = convertOpeningsAndLocationsToGeometries([payload.openingGroups.newGroup])

    payload.openingGroups.newGroup.openings.forEach((opening) => {
        geoOpeningIdsAlreadyAddedByNewGeometries[opening.id] = true
    })

    // In the case that an opening was merged into another group then original
    // drawable group will not exist
    payload.openingGroups.originalGroup!.openings.forEach((opening) => {
        openingIdsAffected.add(opening.id)
    })
    // add all opening ids from newGroup
    newGeometries.forEach((g) => openingIdsAffected.add(g.opening_id))

    const filteredGeometries = state.geometries.filter((geo) => !openingIdsAffected.has(geo.opening_id))

    state.geometries = filteredGeometries.concat([...newGeometries])

    state.allGroupIds = [...new Set(state.geometries.map((geo) => geo.geometryGroupId))]
}

function handleDeleteGeometries(
    state: GeometryState,
    { payload }: PayloadAction<{ openingGroupsToDelete: OpeningGroupAPI[] }>
) {
    const newGeometries: Geometry[] = convertOpeningsAndLocationsToGeometries(payload.openingGroupsToDelete)

    const geometriesIdsToRemove = newGeometries.map((geo) => geo.geometryGroupId)

    state.geometries = state.geometries.filter((geo) => !geometriesIdsToRemove.includes(geo.geometryGroupId))
    state.allGroupIds = [
        ...new Set(
            state.geometries
                .filter((geo) => !geometriesIdsToRemove.includes(geo.geometryGroupId))
                .map((geo) => geo.geometryGroupId)
        ),
    ]
}

function handleUpdateGeometryMeasurements(
    state: GeometryState,
    { payload }: PayloadAction<UpdateDrawableMeasurements>
) {
    state.geometries = state.geometries.map((geo) => {
        const isGeoMatching = geo.id === payload.updatedLocationID || geo.opening_id === payload.activeDrawableId

        if (!isGeoMatching) return geo

        // Update opening settings
        geo.openingSettings = {
            ...geo.openingSettings,
            quantity: payload.openingMeasurements.quantity,
            linear_total: payload.openingMeasurements.linear_total,
        }

        // Update group measurements
        geo.settings.quantity = payload.groupMeasurements.quantity ? Number(payload.groupMeasurements.quantity) : 0
        geo.settings.linear_total = payload.groupMeasurements.linear_total
            ? Number(payload.groupMeasurements.linear_total)
            : 0

        // Update coordinates and cutouts if location ID matches
        if (geo.id === payload.updatedLocationID) {
            geo.coordinates = payload.coordinates
            geo.cutouts = payload.cutouts || geo.cutouts || []
        }

        return geo
    })
}

function handleToggleGeometryGroup(
    state: GeometryState,
    { payload }: PayloadAction<{ drawables: any | []; activeChunkId: number | undefined }>
) {
    if (!isArray(payload.drawables) && payload.activeChunkId) {
        const previousStateExists =
            state.geometryGroupActiveRecord[payload.drawables.id] &&
            state.geometryGroupActiveRecord[payload.drawables.id][payload.activeChunkId] !== undefined

        state.geometryGroupActiveRecord = {
            ...state.geometryGroupActiveRecord,
            [payload.drawables.id]: {
                ...state.geometryGroupActiveRecord[payload.drawables.id],
                [payload.activeChunkId]: previousStateExists
                    ? !state.geometryGroupActiveRecord[payload.drawables.id][payload.activeChunkId]
                    : false,
            },
        }
    }
}

function handleToggleGeometryGroupsByType(
    state: GeometryState,
    { payload }: PayloadAction<{ type: any[]; activeChunkId: number | undefined }>
) {
    const docId = payload.activeChunkId

    if (docId) {
        const geometryGroupIds: number[] = payload.type.map((group) => group.id)
        const isAllActive: boolean = geometryGroupIds.every((geoGroupId) => {
            return (
                state.geometryGroupActiveRecord[geoGroupId] &&
                (state.geometryGroupActiveRecord[geoGroupId][docId] ||
                    isUndefined(state.geometryGroupActiveRecord[geoGroupId][docId]))
            )
        })

        geometryGroupIds.forEach((groupID) => {
            state.geometryGroupActiveRecord = {
                ...state.geometryGroupActiveRecord,
                [groupID]: {
                    ...state.geometryGroupActiveRecord[groupID],
                    [docId]: !isAllActive,
                },
            }
        })
    }
}

function handleActivatingSingleGeometryGroup(
    state: GeometryState,
    { payload }: PayloadAction<{ type: any[]; activeChunkId: number | undefined }>
) {
    const docId = payload.activeChunkId

    if (docId) {
        const geometryGroupIds: number[] = payload.type.map((group) => group.id)
        const allGeometryGroupIds: number[] = state.allGroupIds.filter((id) => !geometryGroupIds.includes(id))

        geometryGroupIds.forEach((groupID) => {
            state.geometryGroupActiveRecord = {
                ...state.geometryGroupActiveRecord,
                [groupID]: {
                    ...state.geometryGroupActiveRecord[groupID],
                    [docId]: true,
                },
            }
        })

        allGeometryGroupIds.forEach((groupId) => {
            state.geometryGroupActiveRecord = {
                ...state.geometryGroupActiveRecord,
                [groupId]: {
                    ...state.geometryGroupActiveRecord[groupId],
                    [docId]: false,
                },
            }
        })
    }
}

function handleResetGeometryGroupVisibility(
    state: GeometryState,
    { payload }: PayloadAction<{ isShowAllTabs: boolean }>
) {
    if (payload.isShowAllTabs) {
        state.geometryGroupActiveRecord = {}
    }
}

function handleToggleActiveGeometries(
    state: GeometryState,
    { payload }: PayloadAction<{ drawable_id: number[] | number }>
) {
    if (!isArray(payload.drawable_id)) {
        state.geometries = state.geometries.map((geo) => {
            if (geo.opening_id === payload.drawable_id) {
                geo.isActive = geo.isActive === undefined || !geo.isActive
            }

            return geo
        })
    } else {
        const geometriesTargeted = [...state.geometries].filter((geo) =>
            (payload.drawable_id as number[]).includes(geo.opening_id)
        )

        if (geometriesTargeted.length === 0) return
        const isAllInActive = geometriesTargeted.every((geo) => geo.isActive === false)

        state.geometries = state.geometries.map((geo) => {
            if ((payload.drawable_id as number[]).includes(geo.opening_id)) {
                return { ...geo, isActive: isAllInActive }
            }

            return geo
        })
    }
}

function handleUpdateGeometryJoistLines(state: GeometryState, { payload }: PayloadAction<{ res: OpeningGroupAPI }>) {
    const geometries = convertOpeningsAndLocationsToGeometries([payload.res])
    const openingIds = payload.res.openings.map((opening) => opening.id)
    const filteredGeometries = state.geometries.filter((geo) => !openingIds.includes(geo.opening_id))

    state.geometries = [...filteredGeometries, ...geometries]
}

const extraReducers = (builder: ActionReducerMapBuilder<GeometryState>): void => {
    builder.addCase(gotOpeningGroups, handleGotOpeningGroups)
    builder.addCase(setActiveGroup, handleSetActiveGroup)
    builder.addCase(updateGeometries, handleUpdateGeometries)
    builder.addCase(deleteGeometries, handleDeleteGeometries)
    builder.addCase(updateGeometryMeasurements, handleUpdateGeometryMeasurements)
    builder.addCase(toggleGeometryGroup, handleToggleGeometryGroup)
    builder.addCase(toggleGeometryGroupByType, handleToggleGeometryGroupsByType)
    builder.addCase(activateSingleGeometryGroupByType, handleActivatingSingleGeometryGroup)
    builder.addCase(resetGeometryGroupVisibility, handleResetGeometryGroupVisibility)
    builder.addCase(toggleActiveGeometries, handleToggleActiveGeometries)
    builder.addCase(updateGeometryJoistLines, handleUpdateGeometryJoistLines)
}

const geometrySlice = createSlice({
    name: 'geometry',
    initialState: initialGeometryState,
    reducers: {
        setAngle(state, action: PayloadAction<number>) {
            state.angle = action.payload
        },
        setWidth(state, action: PayloadAction<number>) {
            state.width = action.payload
        },
        setHeight(state, action: PayloadAction<number>) {
            state.height = action.payload
        },
        createBlankGroup(state, action: PayloadAction<ActiveGeometryGroup | null>) {
            state.groupToCreate = action.payload
        },
        updateGeometricLocationAdditionalData(
            state,
            action: PayloadAction<{ additionalData: IndexableObject; id: number }>
        ) {
            state.geometries = state.geometries.map((geo) => {
                if (geo.id === action.payload.id) {
                    return { ...geo, additional_data: action.payload.additionalData }
                }

                return geo
            })
        },
        updateGeometriesRcmBuildingId(
            state,
            action: PayloadAction<{ rcmBuildingId: string; documentChunkId: number }>
        ) {
            const { rcmBuildingId, documentChunkId } = action.payload

            // update geometry rcm_building id when document chunk was moved to another building
            state.geometries = state.geometries.map((geo) => {
                if (geo.document_chunk_id === documentChunkId) {
                    return {
                        ...geo,
                        openingSettings: {
                            ...geo.openingSettings,
                            rcm_building_id: rcmBuildingId,
                        },
                        settings: {
                            ...geo.settings,
                            rcm_building_id: rcmBuildingId,
                        },
                    }
                }

                return geo
            })
        },
        deleteGeometriesByIds(state, { payload }: PayloadAction<number[]>) {
            const restGeometries = state.geometries.filter((geometry) => !payload.includes(geometry.opening_id))
            const restAllGroupIds = restGeometries.map((geo) => geo.geometryGroupId)

            state.geometries = restGeometries
            state.allGroupIds = restAllGroupIds
        },
    },
    extraReducers,
})

export default geometrySlice

export const {
    setAngle,
    setWidth,
    setHeight,
    createBlankGroup,
    updateGeometricLocationAdditionalData,
    updateGeometriesRcmBuildingId,
    deleteGeometriesByIds,
} = geometrySlice.actions

/** SELECTORS */

export const selectAngle = createSelector(
    (state: RootState) => state.IMUP.geometry.angle,
    (angle) => angle
)

export const selectLengths = createSelector(
    (state: RootState) => {
        return { height: state.IMUP.geometry.height, width: state.IMUP.geometry.width }
    },
    (lengths) => lengths
)

export const selectGeometries = createSelector(
    (state: RootState) => state.IMUP.geometry.geometries,
    (geometries) => geometries
)

export const selectActiveGeometryGroup = createSelector(
    [
        (state: RootState) => state.IMUP.geometry.geometries,
        (state: RootState) => state.IMUP.documents.documentChunks,
        (state: RootState) => selectActiveFloorId(state),
        (state: RootState) => state.IMUP.geometry.activeGeometryGroupID,
    ],
    (geometries, documentChunks, activeChunkId, activeGroupId): ActiveGeometryGroup | null => {
        try {
            if (isNull(activeGroupId)) {
                return null
            }
            const geometriesInGroup = geometries.filter((geo) => geo.geometryGroupId === activeGroupId)

            if (geometriesInGroup.length === 0) return null

            const { drawables, groupSettings } = convertGeometriesToGeometricDrawable(documentChunks, geometriesInGroup)

            if (drawables.length === 0) return null

            return {
                openings: drawables,
                drawables:
                    activeChunkId !== null
                        ? drawables.filter((geoDrawable) =>
                              geoDrawable.opening_locations.find((loc) => loc.document_chunk_id === activeChunkId)
                          )
                        : drawables,
                id: drawables[0].opening_group_id,
                color: drawables[0].color,
                type: drawables[0].type,
                settings: groupSettings as any,
                remarks: drawables[0].remarks,
                configuration: drawables[0].configuration,
                project_id: drawables[0].project_id,
                is_marked_done: drawables[0].is_marked_done,
            }
        } catch (e) {
            console.error(e)

            return null
        }
    }
)

export const selectDrawableGroupsGeometries = createSelector(
    [
        (_: RootState, isIgnorePageFilter?: boolean) => isIgnorePageFilter,
        (state: RootState) => state.IMUP.geometry.geometries,
        (state: RootState) => state.IMUP.documents.documentChunks,
        (state: RootState) => state.IMUP.geometry.geometryGroupActiveRecord,
        (state: RootState) => selectActiveFloorId(state),
        (state: RootState) => state.IMUP.geometry.allGroupIds,
        (state: RootState) => state.drawable.isShowAllTabs,
    ],
    (
        isIgnorePageFilter,
        allGeometries,
        documentChunks,
        geometryGroupActiveRecord,
        activeChunkId,
        allGroupIds,
        isShowAllTabs
    ) => {
        const geometriesHash = {}

        for (const geometries of allGeometries) {
            if (!(geometries.geometryGroupId in geometriesHash)) {
                geometriesHash[geometries.geometryGroupId] = []
            }
            geometriesHash[geometries.geometryGroupId].push(geometries)
        }
        try {
            return allGroupIds.reduce((groups, groupId) => {
                const geometriesInGroup = geometriesHash[groupId]

                const { drawables, groupSettings } = convertGeometriesToGeometricDrawable(
                    documentChunks,
                    geometriesInGroup
                )

                if (drawables.length === 0) return [] as GeometryGroup[]

                const drawablesPerTab =
                    activeChunkId !== null || !isIgnorePageFilter
                        ? drawables.filter((geoDrawable) =>
                              geoDrawable.opening_locations.find((loc) => loc.document_chunk_id === activeChunkId)
                          )
                        : drawables

                if (isShowAllTabs || drawablesPerTab.length > 0) {
                    groups.push({
                        openings: drawables,
                        drawablesPerTab: drawablesPerTab,
                        id: drawables[0].opening_group_id,
                        color: drawables[0].color,
                        type: drawables[0].type,
                        settings: groupSettings as any,
                        remarks: drawables[0].remarks,
                        configuration: drawables[0].configuration,
                        project_id: drawables[0].project_id,
                        isActive: geometryGroupActiveRecord[groupId] ?? {},
                        is_marked_done: drawables[0].is_marked_done,
                    })
                }

                return groups
            }, [] as GeometryGroup[])
        } catch (e) {
            console.error(e)

            return []
        }
    }
)

export const selectDrawableGroupsGeometriesHash = createSelector(
    [
        (_: RootState, shouldIgnorePagesFilter?: boolean) => shouldIgnorePagesFilter,
        (state: RootState) => state.IMUP.geometry.geometries,
        (state: RootState) => state.IMUP.documents.documentChunks,
        (state: RootState) => state.IMUP.geometry.geometryGroupActiveRecord,
        (state: RootState) => selectActiveFloorId(state),
        (state: RootState) => state.IMUP.geometry.allGroupIds,
        (state: RootState) => state.drawable.isShowAllTabs,
    ],
    (
        shouldIgnorePagesFilter,
        allGeometries,
        documentChunks,
        geometryGroupActiveRecord,
        activeChunkId,
        allGroupIds,
        isShowAllTabs
    ) => {
        const geometriesHash = {}

        for (const geometries of allGeometries) {
            if (!(geometries.geometryGroupId in geometriesHash)) {
                geometriesHash[geometries.geometryGroupId] = []
            }
            geometriesHash[geometries.geometryGroupId].push(geometries)
        }

        try {
            return allGroupIds.reduce((groups, groupId) => {
                const geometriesInGroup = geometriesHash[groupId]

                const { drawables, groupSettings } = convertGeometriesToGeometricDrawable(
                    documentChunks,
                    geometriesInGroup
                )

                if (drawables.length === 0) return [] as GeometryGroup[]

                const drawablesPerTab =
                    activeChunkId !== null
                        ? drawables.filter((geoDrawable) =>
                              geoDrawable.opening_locations.find((loc) => loc.document_chunk_id === activeChunkId)
                          )
                        : drawables

                if (isShowAllTabs || drawablesPerTab.length > 0 || shouldIgnorePagesFilter) {
                    groups[drawables[0].opening_group_id] = {
                        openings: drawables,
                        drawablesPerTab: drawablesPerTab,
                        id: drawables[0].opening_group_id,
                        color: drawables[0].color,
                        type: drawables[0].type,
                        settings: groupSettings as any,
                        remarks: drawables[0].remarks,
                        configuration: drawables[0].configuration,
                        project_id: drawables[0].project_id,
                        isActive: geometryGroupActiveRecord[groupId] ?? {},
                        is_marked_done: drawables[0].is_marked_done,
                    }
                }

                return groups
            }, {} as Record<number, GeometryGroup>)
        } catch (e) {
            console.error(e)

            return {}
        }
    }
)

export const selectDrawableGeometries = createSelector(
    [
        (state: RootState) => state.IMUP.geometry.geometries,
        (state: RootState) => state.IMUP.documents.documentChunks,
        (state: RootState) => state.IMUP.geometry.geometryGroupActiveRecord,
        (state: RootState) => selectActiveFloorId(state),
        (state: RootState) => state.IMUP.navigation.activeTab,
    ],
    (allGeometries, documentChunks, geometryGroupActiveRecord, activeChunkId, activeTab) => {
        try {
            const groupActiveRecord = geometryGroupActiveRecord
            const { drawables } = convertGeometriesToGeometricDrawable(documentChunks, allGeometries)

            if (isNull(activeChunkId) || activeTab === PROJECT_PAGE_TAB_VALUES.PLANS) {
                return drawables
            }

            return drawables.filter((geoDrawable) => {
                const isDrawableOnPage = geoDrawable.opening_locations[0].document_chunk_id === activeChunkId
                const isGroupNotInRecord = isUndefined(groupActiveRecord[geoDrawable.opening_group_id])
                const isGroupActive =
                    !isGroupNotInRecord && groupActiveRecord[geoDrawable.opening_group_id][activeChunkId!]

                return isDrawableOnPage && (isGroupNotInRecord || isGroupActive)
            })
        } catch (e) {
            console.error(e)

            return []
        }
    }
)

export const selectActiveGroupToCreate = createSelector(
    (state: RootState) => {
        return state.IMUP.geometry.groupToCreate
    },
    (group) => group
)

export const selectActiveGeometryGroupId = createSelector(
    (state: RootState) => state.IMUP.geometry.activeGeometryGroupID,
    (id) => id
)
