import { createAction } from '@reduxjs/toolkit'
import isUndefined from 'lodash/isUndefined'
import { call, put, select, StrictEffect } from 'redux-saga/effects'

import { OpeningGroupAPI } from './../../../models/activeDrawable'
import { SET_DRAWABLES, setActiveDrawableGroup } from '../../../actions/drawable'
import { createOpeningGroupMaterial } from '../../../api/projects-api'
import { Building } from '../../../models/projectBuilding'
import { determineDefaultBundleForNewGroup } from '../../../shared/services/drawable-type-service'
import { selectActiveBuildingFromActiveFloor } from '../../slices/buildings'
import { createBlankGroup, GeometryGroup } from '../../slices/geometry'
import { CreateDrawableGroupActionInput, Drawables } from '../../types'

export const setDrawables = createAction<Drawables>(SET_DRAWABLES)

export function* handleCreateDrawableForGroup(
    input: CreateDrawableGroupActionInput
): Generator<StrictEffect, void, GeometryGroup[] & OpeningGroupAPI & Building> {
    try {
        const activeBuilding: Building | undefined = yield select(selectActiveBuildingFromActiveFloor)

        if (isUndefined(activeBuilding)) return

        const newDrawableGroup: OpeningGroupAPI = yield call(
            createOpeningGroupMaterial,
            input.projectId,
            undefined,
            {
                ...input.settings,
                name: `temporary_${input.type}_group`,
                rcm_building_id: activeBuilding.rcm_id,
                bundle_name: determineDefaultBundleForNewGroup(input.type),
            },
            input.type
        )

        const drawableGroupWBlankOpenings = {
            ...newDrawableGroup,
            openings: [],
            drawables: [],
        }

        yield put(createBlankGroup(drawableGroupWBlankOpenings))
        yield put(setActiveDrawableGroup({ ...drawableGroupWBlankOpenings, drawablesPerTab: [], isActive: {} }))
    } catch (e) {
        yield call(console.error, e)
    }
}
