import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { MsalProvider } from '@azure/msal-react'

import { msalInstance } from './authConfig'
import App from './components/app/App'
import { ConfigureInterceptors } from './interceptors/request-interceptor'

import './index.css'

// Cant use JSONSchema7 as enum names is not supported
// https://github.com/rjsf-team/react-jsonschema-form/issues/2663#issuecomment-1106698186
declare module 'json-schema' {
    export interface JSONSchema7 {
        enumNames?: string[]
    }
}

ConfigureInterceptors()

ReactDOM.render(
    <Router>
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    </Router>,
    document.getElementById('root')
)
