import { FormValidation, UiSchema } from '@rjsf/core'

import { FormProperties } from '../imup/types'

const onValidate = (formData: any, errors: FormValidation) => {}

const onSubmit = (settings, formData) => {
    return { ...settings, ...formData, label: !formData.label && settings.label ? null : formData.label }
}

export const uiSchema: UiSchema = {
    location: {
        'ui:widget': 'hidden',
    },
}
const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
