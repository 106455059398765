import SelectTool from '../select/Select.tool'
import { Workspace } from '../workspace/Workspace.tool'
import { WHITE } from '../../../../../../shared/constants/colors'
import { DRAWABLE_TYPES } from '../../../../../../shared/constants/drawable-types'
import { LineType, MouseButtonCodes, PaperToolConfig, TOOL_TYPE_ENUMS } from '../../../../../types'

/**
 * Label.tool.tsx
 * Creates a label -- mainly used for joist lines
 */
export class Label extends SelectTool {
    static NAME = 'LABEL'
    private static LABEL_TEXT_FONT_SIZE_MULTIPLIER = 0.8
    private static LABEL_TEXT_FONT_SIZE_SMALL = 12
    private static LABEL_TEXT_FONT_SIZE_LARGE = 24
    private static RECTANGLE_HORIZONTAL_PADDING = 10
    private static RECTANGLE_VERTICAL_PADDING = 5
    private static MINIMAL_LENGTH_DIFFERENCE = 40
    public static LABEL_RECTANGLE_GROUP_CHILD_INDEX = 1

    private readonly LABEL_SHADOW = {
        color: new this.paper.Color(0, 0, 0),
        blur: 5,
        offset: new this.paper.Point(2, 3),
    }
    private workspaceTool: Workspace

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = Label.NAME
        this.workspaceTool = new Workspace(config)
    }

    /**
     * The calculatePathDirection function determines
     * whether a given path is more horizontal or vertical
     * by calculating the angle between the start and end points of the path.
     * It returns LineType.HORIZONTAL if the angle is closer to 0° or 180°,
     * and LineType.VERTICAL if the angle is closer to 90°.
     *
     * @param path
     * @private
     */
    private calculatePathDirection(path: paper.Path): string {
        const startPoint = path.firstSegment.point
        const endPoint = path.lastSegment.point

        // Calculate the angle in radians, then convert to degrees
        const angleInRadians = Math.atan2(endPoint.y - startPoint.y, endPoint.x - startPoint.x)
        const angleInDegrees = angleInRadians * (180 / Math.PI)

        const normalizedAngle = Math.abs(angleInDegrees % 180)

        return normalizedAngle < 45 || normalizedAngle > 135 ? LineType.HORIZONTAL : LineType.VERTICAL
    }

    /**
     * The getRectanglePosition function calculates the position of a rectangle relative to a path,
     * adjusting its placement based on the path's orientation and a raster's dimensions if available.
     * Otherwise, it centers the rectangle on the path.
     *
     * @param path
     * @param rectWidth
     * @param rectHeight
     * @param drawingType
     * @private
     */
    private getRectanglePosition(
        path: paper.Path,
        rectWidth: number,
        rectHeight: number,
        drawingType?: DRAWABLE_TYPES | TOOL_TYPE_ENUMS
    ): paper.Point {
        const raster: paper.Raster | null = this.workspaceTool.getPlanRaster()
        const { center } = path.bounds
        const isDisplayOnSide = path.length - Label.MINIMAL_LENGTH_DIFFERENCE < rectWidth

        // drawingType can be removed to apply this logic to all lines with labels
        if (raster && isDisplayOnSide && drawingType === TOOL_TYPE_ENUMS.MEASUREMENT) {
            const lineDirection = this.calculatePathDirection(path)
            const offset = new this.paper.Point(rectWidth / 2, rectHeight / 2)

            if (lineDirection === LineType.HORIZONTAL) {
                const verticalGap = 40
                const verticalOffset = raster.height / 2 > path.position.y ? verticalGap : -verticalGap

                return center.subtract(offset.add(new this.paper.Point(0, verticalOffset)))
            }

            if (lineDirection === LineType.VERTICAL) {
                const horizontalOffset = raster.width / 2 > path.position.x ? rectWidth : -rectWidth

                return center.subtract(offset.add(new this.paper.Point(horizontalOffset, 0)))
            }
        }

        return center.subtract(new this.paper.Point(rectWidth / 2, rectHeight / 2))
    }

    /**
     * The getLabelFontSize function returns the appropriate font size for a label based on the provided drawingType.
     * If the type is TOOL_TYPE_ENUMS.MEASUREMENT, it returns a large font size; otherwise, it returns a small font size.
     *
     * @param drawingType
     * @private
     */
    private getLabelFontSize(drawingType: DRAWABLE_TYPES | TOOL_TYPE_ENUMS): number {
        switch (drawingType) {
            case TOOL_TYPE_ENUMS.MEASUREMENT:
                return Label.LABEL_TEXT_FONT_SIZE_LARGE
            default:
                return Label.LABEL_TEXT_FONT_SIZE_SMALL
        }
    }

    /**
     * The insertLabel function creates a labeled rectangle for a given path,
     * calculates its dimensions based on the label text, and groups the path, rectangle, and text together,
     * adding an interactive click handler.
     *
     * @param path
     * @param title
     * @returns paper.Group A group that contains the original path, a label box and a text label centered on the path.
     */
    public insertLabel = (path: paper.Path, title: string | number): paper.Group => {
        const drawingType = path.data?.drawing_type
        const labelFontSize = this.getLabelFontSize(drawingType)

        // Create a temporary text object to measure its dimensions
        const tempText = new this.paper.PointText({
            content: title.toString(),
            fontSize: Label.LABEL_TEXT_FONT_SIZE_MULTIPLIER * labelFontSize,
        })

        // Calculate rectangle dimensions based on text size with padding
        const rectWidth = tempText.bounds.width + Label.RECTANGLE_HORIZONTAL_PADDING
        const rectHeight = tempText.bounds.height + Label.RECTANGLE_VERTICAL_PADDING

        tempText.remove() // Clean up the temporary text

        const rectanglePosition = this.getRectanglePosition(path, rectWidth, rectHeight, drawingType)

        // Create the rectangle
        const rect = new this.paper.Path.Rectangle({
            point: rectanglePosition,
            size: new this.paper.Size(rectWidth, rectHeight),
            fillColor: WHITE,
            shadowColor: this.LABEL_SHADOW.color,
            shadowBlur: this.LABEL_SHADOW.blur,
            shadowOffset: this.LABEL_SHADOW.offset,
            locked: [DRAWABLE_TYPES.HEADER, TOOL_TYPE_ENUMS.MEASUREMENT].includes(drawingType),
        })

        // Create the label text and position it within the rectangle
        const pointText = new this.paper.PointText({
            content: title.toString(),
            fontSize: Label.LABEL_TEXT_FONT_SIZE_MULTIPLIER * labelFontSize,
            locked: true,
            position: rect.bounds.center,
        })

        // Bring rectangle and text to the front
        rect.bringToFront()
        pointText.bringToFront()

        // Add onClick handler for the rectangle
        rect.onClick = (event: paper.ToolEvent) => {
            if (event['event']['button'] === MouseButtonCodes.Left) {
                const { drawing_type, toolName } = path.data

                if (drawing_type === DRAWABLE_TYPES.HEADER || toolName === TOOL_TYPE_ENUMS.MEASUREMENT) {
                    this.toggleItemOnClick(path)
                }
            }
        }

        // Group the path, rectangle, and label text together
        return new this.paper.Group([path, rect, pointText])
    }

    public updateLabelPosition(labelGroup: paper.Group, path?: paper.Path) {
        const labelText = labelGroup.children[path ? 1 : 2]
        const labelRect = labelGroup.children[path ? 0 : 1]
        const pathItem = path || (labelGroup.children[0] as paper.Path)
        const rectangleCenter = this.getRectanglePosition(
            pathItem,
            labelRect.bounds.width - labelRect.bounds.width / 2,
            labelRect.bounds.height - labelRect.bounds.height / 2,
            pathItem.data.drawing_type
        )

        labelRect.position = rectangleCenter

        labelText.position = labelRect.bounds.center

        pathItem.sendToBack()
    }
}

export default Label
