import { UiSchema } from '@rjsf/core'

import { FormProperties } from '../imup/types'

export const uiSchema: UiSchema = {
    'ui:order': ['type', 'name', 'scale'],
    name: {
        'ui:title': 'Name',
        'ui:placeholder': 'Select Name',
    },
    type: {
        'ui:title': 'Type',
        'ui:placeholder': 'Select Type',
    },
    scale: {
        'ui:title': 'Scale',
        'ui:placeholder': 'Select Scale',
    },
}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    return {
        ...settings,
        ...formData,
    }
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
