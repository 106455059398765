import { call, put, StrictEffect } from 'redux-saga/effects'

import { GeometryInputMap, GeometryResultMap } from './prepare3DModel'
import { prepareGeometryMetadata } from './prepareGeometryMetadata'
import ModelUtils from '../../../components/IMUP3DComponent/babylon/ModelUtils'
import { fetchModelStructureFailure, updateStructureGeometries } from '../../../slices/3D'
import { IModel, MODEL_TYPES_TO_LOAD_IN_VIEWER, ThreeDToTwoDRecord } from '../../../types'
import SagaActionEnabledError from '../../utils/sagaBaseError'

export function* prepareGeometries(
    structure: IModel,
    geometries: GeometryInputMap,
    threeDToTwoDMappings: ThreeDToTwoDRecord
): Generator<StrictEffect, void, IModel[] & GeometryResultMap> {
    try {
        const modelNodes: IModel[] = yield call(prepareModelNodes, structure)
        const geometryMap: GeometryResultMap = yield call(
            prepareGeometryMetadata,
            geometries,
            modelNodes,
            threeDToTwoDMappings
        )

        yield put(updateStructureGeometries(geometryMap))
    } catch (error) {
        throw new SagaActionEnabledError(fetchModelStructureFailure, (error as any).message)
    }
}

/**
 * Prepares model nodes splitting each structure by storey of the house
 * @param structure the model structural elements
 * @returns structures organized by storey in the building
 */
export const prepareModelNodes = (structure: IModel): IModel[] =>
    ModelUtils.findElementsByTypeInStoreys(structure.children, MODEL_TYPES_TO_LOAD_IN_VIEWER)
