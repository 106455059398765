import { all, call, select, StrictEffect } from 'redux-saga/effects'

import { GeometricDrawable, selectActiveGeometryGroupId, selectDrawableGeometries } from './../../slices/geometry'
import { formatItemWithSelectedStatus } from './handleToggleActiveDrawable'
import { ActiveDrawable, OpeningGroup } from '../../../models/activeDrawable'
import { getActiveDrawableGroup, getDrawables } from '../../../reducers/drawable'
import { RootState } from '../../../stores'
import { isNonAreaJoistLine } from '../../../utils/project/project-helper-functions'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Select, Workspace } from '../../lib/toolBoxes/2D'

export function selectDrawables({ drawable }: RootState): ActiveDrawable[] {
    return getDrawables(drawable)
}

export function selectActiveDrawableGroup({ drawable }: RootState): OpeningGroup {
    return getActiveDrawableGroup(drawable)
}

export function* handleToggleActiveDrawableGroups2D(): Generator<
    StrictEffect | number[],
    void,
    PaperManager & Workspace & Select & GeometricDrawable[] & paper.Item[] & (number | null) & number[] & paper.Item
> {
    try {
        const manager: PaperManager = yield call(managers.get2DManager)

        if (!manager) return

        const workspaceTool: Workspace = yield call(manager.getTool, Workspace.NAME)
        const selectTool: Select = yield call(manager.getTool, Select.NAME)

        const allDrawables: GeometricDrawable[] = yield select(selectDrawableGeometries)

        const activeDrawableGroupId: number | null = yield select(selectActiveGeometryGroupId)

        if (!activeDrawableGroupId) return

        // Get all inactive drawables
        // Drawables are set to inactive via the toggle actions that trigger this saga
        const inactiveDrawableIds: number[] = yield allDrawables.reduce((idArray, drawable) => {
            return drawable.opening_group_id !== activeDrawableGroupId ? [...idArray, drawable.id] : idArray
        }, [] as number[])

        // Hide the drawables that are inactive or not part of the active group (if there is an active group)
        const visibilityTest = (item: paper.Item): boolean => {
            return !(
                inactiveDrawableIds.includes(item.data.drawable_id) ||
                (activeDrawableGroupId && item.data.opening_group_id !== activeDrawableGroupId) ||
                (isNonAreaJoistLine(item) && item.data.opening_group_id !== activeDrawableGroupId)
            )
        }

        const allPaperDrawables: paper.Item[] = yield call(
            workspaceTool.getItemsWithCriteria,
            'data',
            function (data: any) {
                return data?.drawable_id || data?.aiSuggestion?.id
            }
        )

        yield all(
            allPaperDrawables.map((item) =>
                call(
                    (item: paper.Item, visTest: (item: paper.Item) => boolean) => {
                        item.visible = visTest(item)
                    },
                    item,
                    visibilityTest
                )
            )
        )

        // We now need to update the opacity of all drawables based on their isActive status
        yield all(
            allDrawables.map((drawable) => call(formatItemWithSelectedStatus, workspaceTool, selectTool, drawable))
        )
    } catch (error) {
        yield call(console.error, (error as any).message)
    }
}
