import '@babylonjs/core/Collisions/collisionCoordinator'
import '@babylonjs/core/Culling/ray'

import { AbstractMesh } from '@babylonjs/core/Meshes/abstractMesh'
import { Mesh } from '@babylonjs/core/Meshes/mesh'
import { Scene } from '@babylonjs/core/scene'
import has from 'lodash/has'
import omit from 'lodash/omit'
import { all, call, CallEffect, fork, put, select, SelectEffect, StrictEffect } from 'redux-saga/effects'

import { createLineMeshes, translateModel } from './build3DModel'
import { calculateJoistDataFromDrawableGroups } from './data-prep/prepareJoistLinesData'
import { handleSetActiveDrawable3D } from './handleSetActiveDrawable'
import { setActiveDrawable } from '../../../actions/drawable'
import { OpeningGroup } from '../../../models/activeDrawable'
import managers from '../../lib/managers'
import { BabylonManager } from '../../lib/managers/BabylonManager'
import { CustomMaterial, Label, Workspace } from '../../lib/toolBoxes/3D'
import {
    geometriesSelector,
    joistLinesDataSelector,
    State3D,
    translationInfoSelector,
    updateJoistLines,
} from '../../slices/3D'
import { IBabylonLineWithMetadata, IModelTranslationInfo, JOIST_LINES_SUFFIX, MaterialOptionsType } from '../../types'

export function* createLabelsFromMeshesInScene(
    scene: Scene,
    labelTool: Label,
    filterPredicate?: (mesh: Mesh) => boolean
) {
    yield all(
        scene.rootNodes.flatMap((root) =>
            root.getChildMeshes().reduce((prev, current) => {
                if (
                    has(current, 'metadata.label') &&
                    (!filterPredicate || filterPredicate(current as unknown as Mesh))
                ) {
                    prev.push(call(labelTool.create, current.metadata.label, current as unknown as Mesh))
                }

                return prev
            }, [] as CallEffect<void>[])
        )
    )
}

/**
 * Create a predicate function that will flag meshes that are joists
 * and belong to a particular opening
 * @param openingId the opening id
 * @returns
 */
export const joistLinesWithOpeningIdPredicateFunctionGenerator = (
    openingId: number
): ((mesh: AbstractMesh) => boolean) => {
    return (mesh: AbstractMesh) => mesh.id.includes(JOIST_LINES_SUFFIX) && mesh.id.includes(`${openingId}`)
}

/**
 * Generate a predicate function that will flag all joist lines
 * @returns
 */
export const joistLinesPredicateFunctionGenerator = (): ((mesh: AbstractMesh) => boolean) => {
    return (mesh: AbstractMesh) => mesh.id.includes(JOIST_LINES_SUFFIX)
}

export function* cleanupJoistLinesMeshesAndLabels(
    manager: BabylonManager,
    openingIds?: number[]
): Generator<StrictEffect, string[], [Workspace, Label] & string[]> {
    const [workspaceTool, labelTool]: [Workspace, Label] = yield call(manager.getTools, [Workspace.NAME, Label.NAME])

    yield call(labelTool.clearLabels)

    // Either delete joist lines of a particular opening ids
    // all joist lines
    const deletedIds: string[] = openingIds
        ? yield all(
              openingIds.map((openingId) =>
                  call(
                      workspaceTool.deleteMeshesInCurrentScene,
                      joistLinesWithOpeningIdPredicateFunctionGenerator(openingId)
                  )
              )
          )
        : yield call(workspaceTool.deleteMeshesInCurrentScene, joistLinesPredicateFunctionGenerator())

    return deletedIds
}

export function* filterJoistLinesData(
    openingIDs: number[]
): Generator<SelectEffect, Record<string, IBabylonLineWithMetadata>, Record<string, IBabylonLineWithMetadata> | null> {
    const joistLinesData: Record<string, IBabylonLineWithMetadata> | null = yield select(joistLinesDataSelector)

    if (!joistLinesData) return {}

    return Object.keys(joistLinesData).reduce((prev, cur) => {
        const curIncludesId = openingIDs.map((id) => cur.includes(`${id}`)).filter((res) => res).length !== 0

        if (curIncludesId) {
            prev[cur] = joistLinesData[cur]
        }

        return prev
    }, {} as Record<string, IBabylonLineWithMetadata>)
}

export function* createAndLabelJoistLines(
    joistLinesData: Record<string, IBabylonLineWithMetadata>,
    manager: BabylonManager
): Generator<StrictEffect, void, [CustomMaterial, Label] & IModelTranslationInfo & Mesh[] & Scene> {
    const [materialTool, labelTool]: [CustomMaterial, Label] = yield call(manager.getTools, [
        CustomMaterial.NAME,
        Label.NAME,
    ])

    const meshes: Mesh[] = yield call(createLineMeshes, joistLinesData)

    const translationsRequiredToCenter: IModelTranslationInfo = yield select(translationInfoSelector)

    if (!translationsRequiredToCenter) return
    yield all(meshes.map((mesh) => fork(translateModel, mesh, translationsRequiredToCenter)))
    yield call(materialTool.applyMaterialOptionsToMeshes, MaterialOptionsType.DRAWABLE_COLORS, meshes)

    const scene: Scene = yield call(manager.getScene)

    yield call(createLabelsFromMeshesInScene, scene, labelTool)
}

export function* processJoistLinesDataUpdate(
    newDrawableGroup: OpeningGroup
): Generator<
    StrictEffect,
    void,
    (BabylonManager | null) & Pick<State3D, 'geometries'> & Record<string, IBabylonLineWithMetadata> & string[]
> {
    try {
        const manager: BabylonManager = yield call(managers.get3DManager)

        if (!manager) return

        const { geometries }: Pick<State3D, 'geometries'> = yield select(geometriesSelector)
        const joistLinesData: Record<string, IBabylonLineWithMetadata> = yield select(joistLinesDataSelector)
        const changedOpeningIds = newDrawableGroup.openings.map((opening) => opening.id)

        const filteredJoistLines = Object.keys(joistLinesData).reduce((prev, cur) => {
            const containsOpeningId = changedOpeningIds.some((openingId) => cur.includes(String(openingId)))

            if (!containsOpeningId) {
                prev[cur] = joistLinesData[cur]
            }

            return prev
        }, {} as Record<string, IBabylonLineWithMetadata>)

        const deletedIds: string[] = yield call(cleanupJoistLinesMeshesAndLabels, manager, changedOpeningIds)

        const newJoistData: Record<string, IBabylonLineWithMetadata> = yield call(
            calculateJoistDataFromDrawableGroups,
            [newDrawableGroup],
            geometries
        )

        const compiledJoistData = !!filteredJoistLines ? { ...filteredJoistLines, ...newJoistData } : newJoistData

        omit(compiledJoistData, deletedIds)

        yield put(updateJoistLines(compiledJoistData))

        yield call(handleSetActiveDrawable3D, { payload: { drawable: null } } as ReturnType<typeof setActiveDrawable>)
    } catch (error) {
        yield call(console.error, (error as any).message)
    }
}
