import { PayloadAction } from '@reduxjs/toolkit'
import { call, select, StrictEffect, takeEvery } from 'redux-saga/effects'

import { restoreProjectDocumentChunks } from '../../../api/projects-api'
import { restoreDocumentChunks } from '../../slices/documents'
import { selectProjectId } from '../../slices/project'

export function* handleRestoreDocumentChunks(
    action: PayloadAction<{ documentChunksIdsToRestore: number[] }>
): Generator<StrictEffect, void> {
    const { documentChunksIdsToRestore } = action.payload

    try {
        const projectId: any = yield select(selectProjectId)

        if (!projectId) return

        yield call(restoreProjectDocumentChunks, projectId, documentChunksIdsToRestore)
    } catch (error) {
        yield call(console.error, (error as any).message)
    }
}

export function* watchForDocumentChunksRestore() {
    yield takeEvery(restoreDocumentChunks.type, handleRestoreDocumentChunks)
}
