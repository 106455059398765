import { createSelector } from '@reduxjs/toolkit'
import { all, call, put, select, StrictEffect } from 'redux-saga/effects'

import { calculateAreaMeasurementsForMaterial, calculateLFMeasurementOfMaterial } from './createDrawableLocation'
import { UPDATE_OPENING_GROUPS_SUCCESS } from '../../../actions/drawable'
import { addOpeningLocation } from '../../../api/projects-api'
import { Coordinate, OpeningAPI } from '../../../models/activeDrawable'
import { ActiveFloor } from '../../../models/activeFloor'
import { DRAWABLE_TYPES, DRAWING_TYPES } from '../../../shared/constants/drawable-types'
import { RootState } from '../../../stores'
import {
    applyScaleFactorToPathArea,
    applyScaleFactorToPathLength,
} from '../../../utils/calculations/scaleConversion/scaleConversion'
import { buildCoordinatesFromPaperItem } from '../../../utils/coordinates/buildCoordinates'
import { defineLocationRegion } from '../../../utils/coordinates/defineLocationRegion'
import { defineLocationType } from '../../../utils/coordinates/defineLocationType'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Workspace } from '../../lib/toolBoxes/2D'
import { selectDrawableActiveFloor } from '../../slices/documents'
import {
    ActiveGeometryGroup,
    GeometricDrawable,
    selectActiveGeometryGroup,
    setActiveGroup,
} from '../../slices/geometry'

export const selectScaleFactor = createSelector(
    (state: RootState) => state.IMUP['2D'].scaleFactor,
    (scaleFactor) => scaleFactor
)

export function* createOpeningLocation({
    payload,
}: {
    payload: number
}): Generator<
    StrictEffect | Coordinate[],
    void,
    PaperManager &
        Workspace &
        ActiveGeometryGroup &
        GeometricDrawable &
        (DRAWABLE_TYPES | null) &
        ActiveFloor &
        OpeningAPI
> {
    try {
        const manager: PaperManager = yield call(managers.get2DManager)

        if (!manager) return

        const workspaceTool: Workspace = yield call(manager.getTool, Workspace.NAME)
        const scaleFactor: number = yield select(selectScaleFactor)

        const activeDrawableGroup: ActiveGeometryGroup | null = yield select(selectActiveGeometryGroup)
        const activeDrawable: GeometricDrawable | undefined = activeDrawableGroup?.openings.find(
            (opening) => opening.isActive
        )

        if (!activeDrawable) return

        const item: paper.Item | null = yield call(workspaceTool.getItemWithPaperId, payload)
        const items: paper.Item[] = yield call(workspaceTool.getItemsWithDrawableId, activeDrawable.id)

        if (!item) return

        const activeFloor: ActiveFloor = yield select(selectDrawableActiveFloor)

        const documentChunkId = activeFloor.document_chunk.id
        const dpi: number | null | undefined = activeFloor.document_chunk.dpi ?? null
        const xCalibrationFactor: number = activeFloor.document_chunk.calibration_factor_x
        const yCalibrationFactor: number = activeFloor.document_chunk.calibration_factor_y
        const pdfScale: number = activeFloor.document_chunk.pdf_scale ?? 1
        const path = item as paper.Path
        const coordinates: Coordinate[] = buildCoordinatesFromPaperItem(item, path)

        // Create opening_location additional data
        let quantity = 1

        if (path.data.shapeType === DRAWING_TYPES.SECTION) {
            quantity = calculateLFMeasurementOfMaterial(
                applyScaleFactorToPathLength({
                    pxValue: path.length,
                    scaleFactor,
                    pdfScale,
                    xCalibrationFactor,
                    yCalibrationFactor,
                    coordinates,
                    dpi,
                }),
                activeDrawableGroup ? { type: activeDrawableGroup.type, settings: activeDrawableGroup.settings } : {}
            )
        } else if (path.data.shapeType === DRAWING_TYPES.AREA) {
            quantity = calculateAreaMeasurementsForMaterial(
                applyScaleFactorToPathArea({
                    pxValue: path.area,
                    scaleFactor,
                    pdfScale,
                    xCalibrationFactor,
                    yCalibrationFactor,
                    dpi,
                }),
                activeDrawableGroup ? { type: activeDrawableGroup.type, settings: activeDrawableGroup.settings } : {}
            )
        }

        const additional_data = {
            type: defineLocationType(item, activeDrawable.type),
            qty: quantity,
            linear_total: applyScaleFactorToPathLength({
                pxValue: path.length,
                scaleFactor,
                pdfScale,
                xCalibrationFactor,
                yCalibrationFactor,
                coordinates,
                dpi,
            }),
        }

        // Define if location exists within a region
        const regions = activeFloor.document_chunk.regions
        const regionId = defineLocationRegion(path, regions, workspaceTool)

        // Call API to update opening and add new opening_location
        try {
            const updatedDrawable: OpeningAPI = yield call(
                addOpeningLocation,
                activeDrawable.id,
                coordinates,
                documentChunkId,
                activeDrawable.project_id,
                regionId,
                additional_data
            )

            // Remove all drawable location because
            // back-end opening update api updates opening_locations id
            yield all(items.map((item) => call(workspaceTool.removeItemWithPaperId.bind(workspaceTool), item.id)))

            // Add new location to the store
            let newActiveDrawableGroup: ActiveGeometryGroup | null = null

            if (activeDrawableGroup) {
                newActiveDrawableGroup = { ...activeDrawableGroup }
                const activeIndex = newActiveDrawableGroup.openings?.findIndex((o) => o.id === activeDrawable.id)

                const drawableWithDoneState = { ...updatedDrawable, is_marked_done: activeDrawableGroup.is_marked_done }

                if (newActiveDrawableGroup && newActiveDrawableGroup.openings && activeIndex >= 0) {
                    newActiveDrawableGroup.openings[activeIndex] = drawableWithDoneState
                }

                if (newActiveDrawableGroup && newActiveDrawableGroup.drawables && activeIndex >= 0) {
                    newActiveDrawableGroup.drawables[activeIndex] = drawableWithDoneState
                }
            }

            yield all([
                put({
                    type: UPDATE_OPENING_GROUPS_SUCCESS,
                    payload: {
                        openingGroups: {
                            newGroup: { ...newActiveDrawableGroup },
                            originalGroup: activeDrawableGroup,
                        },
                    },
                }),
                call(item.remove.bind(item)),
            ])

            yield put(
                setActiveGroup({
                    activeDrawableGroup: newActiveDrawableGroup,
                    activeDrawableId: activeDrawable.id,
                })
            )
        } catch (e) {
            yield call(console.error, `Error on API opening_location addition: ${e}`)

            // redraw the item on the blueprint
            yield all(items.map((item) => call(workspaceTool.addPaperItem, item)))

            put({
                type: UPDATE_OPENING_GROUPS_SUCCESS,
                payload: { openingGroups: { newGroup: activeDrawableGroup, originalGroup: activeDrawableGroup } },
            })
        }
    } catch (error) {
        yield call(console.error, error)
    }
}
