import { all, call } from 'redux-saga/effects'

import { associateTypeWithLocation } from './associateTypeWithLocation'
import { OpeningAPI, OpeningGroup, OpeningGroupAPI } from '../../../../models/activeDrawable'
import { ActiveGeometryGroup, GeometryGroup } from '../../../slices/geometry'
import { LocationWithGroupId } from '../../../types'

export function* convertOpeningGroupsToDrawableLocations(
    openingGroups: OpeningGroupAPI[] | OpeningGroup[] | GeometryGroup[] | ActiveGeometryGroup[]
) {
    // Flatten API response to openings with opening group ID
    const openingsWithGroupId: OpeningAPI[] = yield openingGroups.flatMap((openingGroup) =>
        openingGroup.openings.map((opening) => ({ ...opening }))
    )

    return yield call(convertOpeningsWithGroupIdsToDrawableLocations, openingsWithGroupId)
}

export function* convertOpeningsWithGroupIdsToDrawableLocations(openingsWithGroupIds: OpeningAPI[]) {
    // Flatten openings with group ID to opening_locations with necessary context of opening and group ID
    const locationsWithGroupId: LocationWithGroupId[] = yield openingsWithGroupIds.flatMap((openingWithGroupId) =>
        openingWithGroupId.opening_locations.map((location, _index, openingLocations) => ({
            ...location,
            drawableType: openingWithGroupId.type,
            groupId: openingWithGroupId.opening_group_id,
            lengthOfOpeningLocations: openingLocations.length,
            drawable: openingWithGroupId,
            settings: openingWithGroupId.settings,
            label: `${openingWithGroupId.order} - `,
            isActive: undefined,
            additional_data: { ...location.additional_data, ...openingWithGroupId.additional_data },
            ai_suggestion_id: openingWithGroupId.ai_suggestion_id,
        }))
    )
    // Associate drawing type with each opening location based on current active floor

    return yield all(
        locationsWithGroupId.map((locationWithGroupId) => call(associateTypeWithLocation, locationWithGroupId))
    )
}
