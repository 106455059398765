import { applyScaleFactorToPathArea } from '../scaleConversion/scaleConversion'

/**
 * Calculate area of a polygon based on the shoeLace method
 * @param input
 */
export const calculateAreaFromCoords = (input: {
    coords: number[]
    scaleFactor: number
    pdfScale: number
    xCalibrationFactor: number
    yCalibrationFactor: number
    dpi: number | null
}) => {
    // Based on https://gist.github.com/tnraro/f6c0bf3daa711721d3ce0dea1c37124a
    // Uses the shoelace method for calculating areas of non self intersecting
    // polygons
    // wiki graphic https://en.wikipedia.org/wiki/Shoelace_formula#/media/File:Shoelace3.png
    // https://en.wikipedia.org/wiki/Shoelace_formula
    const { coords, scaleFactor, dpi, xCalibrationFactor, yCalibrationFactor, pdfScale } = input
    const numberOfVertices = coords.length

    let sum = 0

    for (let i = 0; i < numberOfVertices; i += 2) {
        sum += coords[i] * coords[(i + 3) % numberOfVertices] - coords[i + 1] * coords[(i + 2) % numberOfVertices]
    }

    return applyScaleFactorToPathArea({
        pxValue: Math.abs(sum * 0.5),
        scaleFactor,
        dpi,
        xCalibrationFactor,
        yCalibrationFactor,
        pdfScale,
    })
}
