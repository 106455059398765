import { createAction } from '@reduxjs/toolkit'
import isNull from 'lodash/isNull'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { closeToolFormAction } from './handleCloseToolForm'
import { createToolObject, updateProjectContextMarkups } from '../../../api/projects-api'
import { Project } from '../../../models/project'
import { Region } from '../../../models/region'
import { IToolObject, IToolObjectPost, IToolObjectSettings } from '../../../models/tool'
import { GRAY_400 } from '../../../shared/constants/colors'
import { defineLocationRegion } from '../../../utils/coordinates/defineLocationRegion'
import managers from '../../lib/managers'
import { Color, PathTool, Workspace } from '../../lib/toolBoxes/2D'
import { selectDrawablesToCreateByTool } from '../../slices/2D'
import { formError } from '../../slices/forms'
import { selectAllRegions } from '../../slices/region'
import { addNewToolObjects, selectActiveToolObject } from '../../slices/tools'
import { Coordinates2D, TOOL_TYPE_ENUMS } from '../../types'
import { selectProject } from '../2D/createDrawableLocation'
import drawToolByType from '../2D/drawToolByType'
import { selectMarkupState } from '../2D/markupDocument'

export const submitToolFormAction = createAction<{ formData: FormData }>('submitToolForm')

export function* handleToolFormSubmit({ payload }: ReturnType<typeof submitToolFormAction>) {
    try {
        const paperManager = yield call(managers.get2DManager)

        if (!paperManager) return

        // get tools from manager
        const [colorTool, pathTool, workspaceTool] = yield call(paperManager.getTools, [
            Color.NAME,
            PathTool.NAME,
            Workspace.NAME,
        ])

        const { lineOpacity }: ReturnType<typeof selectMarkupState> = yield select(selectMarkupState)

        const project: Project = yield select(selectProject)

        const currentToolObject: IToolObject | null = yield select(selectActiveToolObject)

        if (!isNull(currentToolObject)) {
            // when we update the tool object
            const updatedToolObjectData: IToolObject = {
                ...currentToolObject,
                settings: payload.formData as IToolObjectSettings,
            }

            yield call(
                updateProjectContextMarkups,
                project.id,
                currentToolObject.document_chunk_id,
                updatedToolObjectData
            )
        } else {
            // when we create the tool object
            const drawablesToCreateByToolIds: number[] = yield select(selectDrawablesToCreateByTool)

            if (!drawablesToCreateByToolIds.length) return

            // retrieve all tool items
            const items: paper.Item[] = yield all(
                drawablesToCreateByToolIds.map((id) => call(workspaceTool.getItemWithPaperId, id))
            )

            const regions: Region[] = yield select(selectAllRegions)

            const newToolObjects: IToolObjectPost[] = items.map((item) => {
                const itemPath = item as paper.Path

                // find the region we are drawing in
                const region_id = defineLocationRegion(itemPath, regions, workspaceTool)

                const toolObject: IToolObjectPost = {
                    region_id,
                    type: item.data.shapeType as TOOL_TYPE_ENUMS,
                    document_chunk_id: item.data.document_chunk_id,
                    coordinates: itemPath.segments.map((segment) => [
                        segment.point.x,
                        segment.point.y,
                    ]) as Coordinates2D,
                    settings: payload.formData as IToolObjectSettings,
                    color: itemPath.strokeColor?.toString() || GRAY_400,
                }

                return toolObject
            })

            // TODO: avoid using look, use endpoint to send multiple items at once
            const newTools: IToolObject[] = yield all(
                newToolObjects.map((tObj) => createToolObject(project.id, tObj.document_chunk_id, tObj))
            )

            // add new created tools into a tool object
            yield put(addNewToolObjects(newTools))

            if (newTools.length) {
                // handle drawing all new tools objects not Material
                yield all(
                    newTools.map((toolObj) =>
                        call(drawToolByType, toolObj, colorTool, pathTool, workspaceTool, lineOpacity)
                    )
                )
            }
        }
        // close the tool form
        yield put(closeToolFormAction())
    } catch (err) {
        if (err instanceof Error) {
            yield put(formError(err.message))
        } else {
            console.error('An unknown error occurred:', err)
        }
    }
}

export function* watchForToolFormSubmit() {
    yield takeLatest(submitToolFormAction.type, handleToolFormSubmit)
}
