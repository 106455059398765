import { call, fork, select, StrictEffect, takeEvery } from 'redux-saga/effects'

import { deleteOpeningLocation } from '../../slices/2D'
import { selectActiveMode } from '../../slices/common'
import { VIEW_MODE } from '../../types'
import { deleteOpeningLocation2D } from '../2D/deleteOpeningLocation'

export function* handleDeleteOpeningLocation(
    action: ReturnType<typeof deleteOpeningLocation>
): Generator<StrictEffect, void, VIEW_MODE> {
    try {
        const activeMode: VIEW_MODE = yield select(selectActiveMode)

        switch (activeMode) {
            case VIEW_MODE.Markup2D:
                yield fork(deleteOpeningLocation2D, action)
                break
            case VIEW_MODE.Markup3D:
                break
            default:
                break
        }
    } catch (error) {
        yield call(console.error, (error as any).message)
    }
}

export function* watchForDeleteOpeningLocation(): Generator<StrictEffect, void, unknown> {
    yield takeEvery(deleteOpeningLocation.type, handleDeleteOpeningLocation)
}
