import { call, select } from 'redux-saga/effects'

import { createDrawableLocation2D } from './createDrawableLocation'
import { createOpeningLocation } from './createOpeningLocation'
import { createDrawableLocation, getCreationFlag } from '../../slices/2D'
import { DRAW_TOOL_ACTION } from '../../types'

export function* createPaperJSItem({ payload }: ReturnType<typeof createDrawableLocation>) {
    const flag = yield select(getCreationFlag)

    switch (flag) {
        case DRAW_TOOL_ACTION.NEW_OPENING:
            yield call(createDrawableLocation2D, { payload: payload })
            break
        case DRAW_TOOL_ACTION.NEW_OPENING_LOCATION:
            yield call(createOpeningLocation, { payload: payload })
            break
    }
}
