import { distinctUntilChanged, map, takeWhile } from 'rxjs'

import { PaperToolBox } from '../toolBoxes/2D'
import AbstractManager from './AbstractManager'
import { IMUPState, PaperManagerConfig, VIEW_MODE } from '../../types'

export class PaperManager extends AbstractManager {
    protected toolBox: PaperToolBox

    constructor(config: PaperManagerConfig) {
        super(config)
        this.toolBox = new PaperToolBox(config)
        // setup to listen to tool changes and activate on change
        this.mediator
            .get$()
            .pipe(
                takeWhile((state: IMUPState) => state.common.activeMode === VIEW_MODE.Markup2D),
                map((state: IMUPState) => state.tools.activeTool),
                distinctUntilChanged()
            )
            .subscribe((activeTool) => {
                !!this.toolBox.tools[activeTool] && this.useTool(activeTool)
            })
    }
}

export default PaperManager
