import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
    FlagAPIResponse,
    FlagAxiosAPIResponse,
    FlagOpeningLinksAPIUpdatePayload,
    FlagOption,
    FlagOptions,
    NormalizedFlag,
} from '../../models/flags'
import { RootState } from '../../stores'

export type MaterialFlagsState = {
    flags: NormalizedFlag[]
    activeFlag: NormalizedFlag | null
    flagOptions: FlagOptions
    isOpenFlagForm: boolean
    isTaskInEditMode: boolean
}

export const initialMaterialFlagsState: MaterialFlagsState = {
    flags: [],
    activeFlag: null,
    flagOptions: {
        types: [],
        statuses: [],
        severities: [],
        categories: [],
        subCategories: [],
    },
    isOpenFlagForm: false,
    isTaskInEditMode: false,
}

const getOptionData = (options: FlagOption[], id: number | null | undefined) => {
    if (!id || !options?.length) return null

    return options.find((option) => option.id === id)
}

const normalizeFlags = (flags: FlagAPIResponse[], flagOptions: FlagOptions): NormalizedFlag[] => {
    return flags.map((flag: FlagAPIResponse) => {
        const updatedFlag = {
            ...flag,
            type: getOptionData(flagOptions!.types, flag.type_id),
            status: getOptionData(flagOptions!.statuses, flag.status_id),
            severity: getOptionData(flagOptions!.severities, flag.severity_id),
            category: getOptionData(flagOptions!.categories, flag.category_id),
            sub_category: getOptionData(flagOptions!.subCategories, flag.sub_category_id),
        }

        // clean up unneeded fields
        delete updatedFlag.type_id
        delete updatedFlag.status_id
        delete updatedFlag.severity_id
        delete updatedFlag.category_id
        delete updatedFlag.sub_category_id

        return updatedFlag
    })
}

// FLAGS
const handleSetFlags = (state: MaterialFlagsState, { payload }: PayloadAction<{ flags: FlagAPIResponse[] }>) => {
    state.flags = normalizeFlags(payload.flags, state.flagOptions)
}

const handleAddNewFlag = (state: MaterialFlagsState, { payload }: PayloadAction<{ flag: FlagAPIResponse }>) => {
    const normalizedFlag = normalizeFlags([payload.flag], state.flagOptions)

    state.flags = [...state.flags, ...normalizedFlag]
}

const handleRemoveFlag = (state: MaterialFlagsState, { payload }: PayloadAction<{ flag_ids: number[] }>) => {
    state.flags = state.flags.filter((flag) => !payload.flag_ids.includes(flag.id))
}

const handleUnlinkMaterialFromFlag = (
    state: MaterialFlagsState,
    { payload }: PayloadAction<{ flag_id: number; opening_link_id: number }>
) => {
    const { flag_id, opening_link_id } = payload

    const updatedFlags = state.flags.map((flag) => {
        if (flag.id === flag_id) {
            return {
                ...flag,
                opening_links: flag.opening_links.filter((link) => link.id !== opening_link_id),
            }
        }

        return flag
    })

    const activeFlagUpdate = updatedFlags.find((flag) => flag.id === flag_id)

    if (activeFlagUpdate && state.activeFlag?.id === flag_id) {
        state.activeFlag = activeFlagUpdate
    }

    state.flags = updatedFlags
}

const handleSetActiveFlag = (state: MaterialFlagsState, { payload }: PayloadAction<{ flag_id: number }>) => {
    state.activeFlag = state.flags.find((flag) => flag.id === payload.flag_id) || null
}

const handleSetTaskEditMode = (state: MaterialFlagsState, { payload }: PayloadAction<{ isEditMode: boolean }>) => {
    state.isTaskInEditMode = payload.isEditMode
}

const handleResetActiveFlag = (state: MaterialFlagsState) => {
    state.activeFlag = null
}

const handleUpdateFlagStatus = (state: MaterialFlagsState, { payload }: PayloadAction<FlagAxiosAPIResponse>) => {
    const { id, status_id, description, category_id, sub_category_id } = payload.updatedFlag

    const updatedFlags = state.flags.map((flag) => {
        if (flag.id === id) {
            return {
                ...flag,
                status: getOptionData(state.flagOptions!.statuses, status_id),
                category: getOptionData(state.flagOptions!.categories, category_id),
                sub_category: getOptionData(state.flagOptions!.subCategories, sub_category_id),
                description,
            }
        }

        return flag
    })

    if (state.activeFlag && state.activeFlag.id === id) {
        state.activeFlag = updatedFlags.find((flag) => flag.id === id) || null
    }

    state.flags = updatedFlags
}

const handleUpdateFlagOpeningCoordinates = (
    state: MaterialFlagsState,
    { payload }: PayloadAction<{ newOpeningLinkData: FlagOpeningLinksAPIUpdatePayload[] }>
) => {
    const { newOpeningLinkData } = payload

    const updatedFlags = state.flags.map((flag) => {
        const foundNewLinkData = newOpeningLinkData.find((link) => link.flag_id === flag.id)

        if (foundNewLinkData) {
            return {
                ...flag,
                opening_links: flag.opening_links.map((link) => {
                    if (link.id === foundNewLinkData.id) {
                        return {
                            ...link,
                            coordinates: foundNewLinkData.coordinates,
                        }
                    }

                    return link
                }),
            }
        }

        return flag
    })

    state.flags = updatedFlags
}

const handleSetFlagOptions = (state: MaterialFlagsState, { payload }: PayloadAction<{ options: any }>) => {
    state.flagOptions = {
        types: payload.options.types,
        statuses: payload.options.statuses?.items || [],
        severities: payload.options.severities?.items || [],
        categories: payload.options.categories?.items || [],
        subCategories: payload.options.subCategories?.items || [],
    }
}

// ACTIONS
const handleToggleFlagForm = (state: MaterialFlagsState, { payload }: PayloadAction<{ toggleForm: boolean }>) => {
    state.isOpenFlagForm = payload.toggleForm
}

const reducers = {
    setFlags: handleSetFlags,
    addNewFlag: handleAddNewFlag,
    removeFlag: handleRemoveFlag,
    setActiveFlag: handleSetActiveFlag,
    resetActiveFlag: handleResetActiveFlag,
    updateFlagStatus: handleUpdateFlagStatus,
    updateFlagOpeningCoordinates: handleUpdateFlagOpeningCoordinates,
    unlinkFlagFromMaterial: handleUnlinkMaterialFromFlag,

    setOptions: handleSetFlagOptions,
    toggleFlagForm: handleToggleFlagForm,
    setTaskEditMode: handleSetTaskEditMode,
}

const materialFlagsSlice = createSlice({
    name: 'materialFlags',
    initialState: initialMaterialFlagsState,
    reducers,
})

export const {
    setFlags,
    addNewFlag,
    removeFlag,
    setActiveFlag,
    resetActiveFlag,
    updateFlagStatus,
    updateFlagOpeningCoordinates,
    unlinkFlagFromMaterial,

    setOptions,
    toggleFlagForm,
    setTaskEditMode,
} = materialFlagsSlice.actions

export const selectMaterialFlags = createSelector(
    (state: RootState) => state.IMUP.materialFlags.flags,
    (flags) => flags
)

export const selectMaterialActiveFlag = createSelector(
    (state: RootState) => state.IMUP.materialFlags.activeFlag,
    (activeFlag) => activeFlag
)

export const selectFlagOptions = createSelector(
    (state: RootState) => state.IMUP.materialFlags.flagOptions,
    (flagOptions) => flagOptions
)

export const selectIsFlagFormOpen = createSelector(
    (state: RootState) => state.IMUP.materialFlags.isOpenFlagForm,
    (isOpenFlagForm) => isOpenFlagForm
)

export const selectIsTaskInEditMode = createSelector(
    (state: RootState) => state.IMUP.materialFlags.isTaskInEditMode,
    (isTaskInEditMode) => isTaskInEditMode
)

export default materialFlagsSlice
