import { UiSchema } from '@rjsf/core'

import { FormProperties } from '../imup/types'

const OvenRangeType = 'Oven/Range'
const VentilationOverRange = 'Ventilation over range'

export const uiSchema: UiSchema = {
    'ui:order': ['type', 'ventilation_over_range', 'oven_range', 'height', 'width', 'depth'],
    type: {
        'ui:title': 'Type',
        'ui:placeholder': 'Select Type',
    },
    ventilation_over_range: {
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    oven_range: {
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    height: {
        'ui:field': 'FeetAndInchesField',
    },
    width: {
        'ui:field': 'FeetAndInchesField',
    },
    depth: {
        'ui:field': 'FeetAndInchesField',
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }

    if (newSettings.type?.toLowerCase() !== OvenRangeType.toLowerCase()) {
        newSettings.oven_range = null
    }

    if (newSettings.type?.toLowerCase() !== VentilationOverRange.toLowerCase()) {
        newSettings.ventilation_over_range = null
    }

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
