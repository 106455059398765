import Base from '../paperTool/PaperTool'
import { Coordinate } from '../../../../../../models/activeDrawable'
import { DRAWABLE_TYPES } from '../../../../../../shared/constants/drawable-types'
import { ItemScale, PaperToolConfig } from '../../../../../types'

/**
 * Comment.tool.ts
 * Allows user to insert comments with labels to a 2D plan
 */
export class Comment extends Base {
    static NAME = 'COMMENT'

    private static readonly PIN_PATH: string =
        'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z'
    private static readonly SCALE_UP_VALUE: number = 1.1
    private static readonly SCALE_DOWN_VALUE: number = 0.9
    private readonly PIN_SHADOW = {
        color: new this.paper.Color(0, 0, 0),
        blur: 12,
        offset: new this.paper.Point(5, 5),
    }
    private static readonly PIN_COLOR: string = '#0071e5'
    private static readonly COMMENT_BACKGROUND_COLOR: string = '#ffe59a'
    private static readonly COMMENT_RESET_COLOR: string = '#ffffff'

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = Comment.NAME
    }

    createComment = (coordinate: Coordinate, commentText: string): paper.Item | null => {
        // This path describes the Material Icons Map Pin SVG
        const commentPin = new this.paper.CompoundPath(Comment.PIN_PATH)

        commentPin.fillColor = new this.paper.Color(Comment.PIN_COLOR)
        commentPin.position = new this.paper.Point(coordinate[0], coordinate[1])
        commentPin.scale(ItemScale.COMMENT * this.scaleFactor) // scale the pins to be manageable
        commentPin.shadowColor = this.PIN_SHADOW.color
        commentPin.shadowBlur = this.PIN_SHADOW.blur
        commentPin.shadowOffset = this.PIN_SHADOW.offset

        commentPin.data.commentText = commentText
        commentPin.data.drawing_type = DRAWABLE_TYPES.NOTE

        this.attachHoverEvents(commentPin)

        return commentPin
    }

    private attachHoverEvents(item: paper.Item) {
        item.onMouseEnter = () => {
            item.scale(Comment.SCALE_UP_VALUE)
            this.mediator.mediate('common', {
                tooltip: { visible: true, title: item.data.commentText, color: Comment.COMMENT_BACKGROUND_COLOR },
            })
        }

        item.onMouseLeave = () => {
            item.scale(Comment.SCALE_DOWN_VALUE)
            this.mediator.mediate('common', {
                tooltip: { visible: false, title: '', color: Comment.COMMENT_RESET_COLOR },
            })
        }
    }
}

export default Comment
