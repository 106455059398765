import isString from 'lodash/isString'
import moment from 'moment'

const YODA_TIME = 'YYYY-MM-DD H:mm:ss'

export const getFormattedDatetime = (projectCreatedAt: string | number, format: string): string => {
    const correctFormat =
        isString(projectCreatedAt) && String(projectCreatedAt).includes('/')
            ? projectCreatedAt.replaceAll('/', '-')
            : projectCreatedAt

    return moment.utc(correctFormat).local().format(format)
}

export const getTimeAgo = (time: string) => {
    return moment(getFormattedDatetime(time, YODA_TIME)).fromNow()
}
