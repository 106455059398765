import { TypedUseSelectorHook, useSelector } from 'react-redux'
import equal from 'fast-deep-equal'

import { RootState } from '../stores'

export const useAppSelector: TypedUseSelectorHook<RootState> = (selector) =>
    useSelector(selector, (tLeft, tRight) => {
        if (tLeft === tRight) return true

        return equal(tLeft, tRight)
    })
