import { UiSchema } from '@rjsf/core'

import { FormProperties } from '../imup/types'

export const uiSchema: UiSchema = {
    sheathing_type: {
        'ui:title': 'Shearwall Sheathing Type',
    },
}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    return {
        ...settings,
        ...formData,
    }
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
