import SelectTool from '../select/Select.tool'
import { FlagEnum } from '../../../../../../models/flags'
import { Coordinates2D, PaperToolConfig, Vector2D } from '../../../../../types'

/**
 * Flag.tool.tsx
 * Creates a flag -- used for adding task and notes to page and materials
 */
export class Flag extends SelectTool {
    static NAME = 'FLAG'

    private static FLAG_TASK_COLOR = '#FF0000'
    private static FLAG_NOTE_COLOR = '#194185'

    private static FLAG_SQUARE_RADIUS = 5

    private static FLAG_SQUARE_SIZE_HEIGHT = 50

    // size of the square when content is placeholder (#) or not more when content length is 2
    private static FLAG_SQUARE_SIZE_BASE_WIDTH = 50
    // space left when the content of the box is less than 3 lengths
    private static FLAG_CONTENT_SPACE_LEFT = 17

    // size of the square when content length is 3
    private static FLAG_SQUARE_SIZE_WIDTH_WITH_CONTENT = 62
    // space left when the content of the box is 3 lengths
    private static FLAG_CONTENT_SPACE_LEFT_WITH_CONTENT = 8

    private static FLAG_SQUARE_BASE_HALF_SIZE = Flag.FLAG_SQUARE_SIZE_BASE_WIDTH / 2

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = Flag.NAME
    }

    /**
     * Calculate the square size based on content
     * @param content
     */
    private calculateFlagSquareSize = (content: string): number => {
        if (content.length < 3) return Flag.FLAG_SQUARE_SIZE_BASE_WIDTH

        return Flag.FLAG_SQUARE_SIZE_WIDTH_WITH_CONTENT
    }

    /**
     * Calculate the content left space base on content
     * @param content
     */
    private calculateFlagContentLeftSpace = (content: string): number => {
        if (content.length === 1) return Flag.FLAG_CONTENT_SPACE_LEFT

        return Flag.FLAG_CONTENT_SPACE_LEFT_WITH_CONTENT
    }

    private getFlagSquareColor = (flagType: string | null): string => {
        if (flagType === null || flagType === FlagEnum.TASK) return Flag.FLAG_TASK_COLOR

        return Flag.FLAG_NOTE_COLOR
    }

    private drawFlagSquare = (
        x: number,
        y: number,
        base = true,
        squareSize = Flag.FLAG_SQUARE_SIZE_BASE_WIDTH,
        flagColor = Flag.FLAG_TASK_COLOR
    ) => {
        const cornerRadius = new this.paper.Size(Flag.FLAG_SQUARE_RADIUS, Flag.FLAG_SQUARE_RADIUS)

        const squareHalfSize = base ? Flag.FLAG_SQUARE_BASE_HALF_SIZE : squareSize
        const squareSizeWidth = base ? Flag.FLAG_SQUARE_SIZE_BASE_WIDTH : squareSize

        const flagSquare = new this.paper.Path.Rectangle({
            point: [x - squareHalfSize, y - squareHalfSize],
            size: [squareSizeWidth, Flag.FLAG_SQUARE_SIZE_HEIGHT],
            radius: cornerRadius,
            fillColor: flagColor,
        })

        return flagSquare
    }

    private drawFlagSquareArrow = (x: number, y: number, flagColor = Flag.FLAG_TASK_COLOR) => {
        const flagSquareArrow = new this.paper.Path.RegularPolygon({
            center: new this.paper.Point(x, y + 30), // Adjust the center position
            sides: 3, // Triangle shape for the arrow
            radius: 10, // Adjust the arrow size
            rotation: 180, // Rotate it to point downward
            fillColor: flagColor,
        })

        return flagSquareArrow
    }

    private drawFlagSquareContent = (flagSquare: paper.Path.Rectangle, content = '#') => {
        const leftSpace = this.calculateFlagContentLeftSpace(content)

        const flagPointText = new this.paper.PointText({
            point: flagSquare.bounds.leftCenter.add(new this.paper.Point(leftSpace, 10)),
            content: content,
            fontSize: 30,
            fillColor: 'white',
            fontWeight: 'bold',
            locked: true,
        })

        return flagPointText
    }

    private drawFlagOnClick = (event: paper.ToolEvent) => {
        const x = event.point.x
        const y = event.point.y

        const hit = this.getPaperScope().project.hitTest(event.point)

        const flagSquare = this.drawFlagSquare(x, y)

        const flagSquareArrow = this.drawFlagSquareArrow(flagSquare.position.x, flagSquare.position.y)

        const flagPointText = this.drawFlagSquareContent(flagSquare)

        flagPointText.bringToFront()

        const flagGroup = new this.paper.Group([flagSquare, flagSquareArrow, flagPointText])

        const documentChunk = this.getActiveDocumentChunk()

        flagGroup.data.toolName = this.name
        flagGroup.data.isTemporaryGroup = true
        flagGroup.data.opening_id = hit?.item?.data?.drawable_id || null
        flagGroup.data.document_chunk_id = documentChunk?.id || null
    }

    public getFlagPointFromCoordinates = (coords: Coordinates2D, isFlagCentered: boolean): Vector2D => {
        const adjustY = (y: number) => y - Flag.FLAG_SQUARE_SIZE_HEIGHT

        let centerPointX = 0
        let centerPointY = 0

        // center point for area or dot shapes
        if (isFlagCentered || coords.length === 1) {
            ;[centerPointX, centerPointY] = coords.reduce(([sumX, sumY], [x, y]) => [sumX + x, sumY + y], [0, 0])

            const adjustedCenterPointY = adjustY(centerPointY / coords.length)

            centerPointX = centerPointX / coords.length

            return [centerPointX, adjustedCenterPointY]
        }

        // general calculation of the point
        const middleItemNumber = Math.floor(coords.length / 2) - 1

        centerPointX = (coords[middleItemNumber][0] + coords[middleItemNumber + 1][0]) / 2
        centerPointY = adjustY((coords[middleItemNumber][1] + coords[middleItemNumber + 1][1]) / 2)

        return [centerPointX, centerPointY]
    }

    public drawFlagByCoords = (
        coords: [number, number],
        flag_id: number,
        openingId: number | null,
        order: number,
        type: string | null,
        isTemporaryGroup = false
    ) => {
        const x = coords[0]
        const y = coords[1]

        const orderText = order.toString()
        const squareSizeBaseOnContent = this.calculateFlagSquareSize(orderText)
        const flagSquareColor = this.getFlagSquareColor(type)

        const flagSquare = this.drawFlagSquare(x, y, false, squareSizeBaseOnContent, flagSquareColor)

        const flagSquareArrow = this.drawFlagSquareArrow(flagSquare.position.x, flagSquare.position.y, flagSquareColor)

        const flagPointText = this.drawFlagSquareContent(flagSquare, orderText)

        flagPointText.bringToFront()

        const flagGroup = new this.paper.Group([flagSquare, flagSquareArrow, flagPointText])

        flagGroup.data.toolName = this.name
        flagGroup.data.flag_id = flag_id
        flagGroup.data.isTemporaryGroup = isTemporaryGroup
        flagGroup.data.openingId = openingId
    }

    onMouseDown = (event: paper.ToolEvent): void => {
        if (this.isPanningClick(event)) return

        this.drawFlagOnClick(event)

        this.setState('materialFlags', { isOpenFlagForm: true })
    }
}

export default Flag
