import { BLACK, WHITE } from '../constants/colors'
import {
    DRAWABLE_COLORS_BY_LOCATION,
    // DRAWABLE_COLORS_BY_MATERIAL_FIELD,
    DRAWABLE_COLORS_BY_NAME,
    DRAWABLE_COLORS_BY_SELECTION,
    DRAWABLE_COLORS_BY_TYPE,
    DRAWABLE_TYPES,
    DRAWABLE_TYPES_COLORS,
    SELECTED_AI_SUGGESTION_COLOR,
} from '../constants/drawable-types'
import IndexableObject from '../constants/general-enums/indexableObject'
import { GeometricDrawable, GeometryGroupWithTempType } from '../../imup/slices/geometry'

export const hexToRgb = (hex, onlyColor?): string => {
    const rgbColor = hex
        .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
        .substring(1)
        .match(/.{2}/g)
        .map((x) => parseInt(x, 16))

    if (onlyColor) {
        return rgbColor
    }

    return 'rgba(' + rgbColor + ')'
}

// Hex to RGB algorithm from https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
export const hexToRgbValues = (hexString: string): { r: number; g: number; b: number } => {
    // This regex pulls out the 2 character hex values of r, g and b into individual strings which are then parsed into integers below
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexString) ?? '000000'

    return {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
    }
}

// Text color algorithm from
// https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
// Sets color to black or white depending on values of background color
export const getTooltipTextColor = (tooltipColor: string) => {
    const { r, g, b } = hexToRgbValues(tooltipColor)

    // magic numbers are optimized for accessibility when displaying light text on dark background and vice versa
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? BLACK : WHITE
}

export const extractRGBComponentsFromCSSString = (cssColorString: string): number[] => {
    const testRegex = /\d+/g
    const components = cssColorString.matchAll(testRegex)

    if (!components) return []
    const results = Array.from(components)

    return results.map((res) => parseInt(res[0]))
}

export const cardColor = (
    drawable: GeometryGroupWithTempType | GeometricDrawable,
    active?: boolean | null | undefined
): string => {
    const defaultColor = '#000000'

    if (!drawable?.color) {
        // active equal false is used to toggle background color, for border color we are using always null
        if (!drawable?.type || active === false) {
            return 'transparent'
        }

        return (
            drawableBackgroundAndBorderColor(
                drawable?.type,
                drawable?.settings?.name || '',
                drawable?.settings?.selection || '',
                drawable?.settings?.location || '',
                null,
                drawable?.settings?.material || ''
            ) || defaultColor
        )
    }

    return drawable?.color || defaultColor
}

/**
 * Get color from material field name
 * @param drawableType
 * @param drawableName
 * @param drawableSelection
 * @param drawableLocation
 * @param additional_data
 * @param drawableMaterial
 * @param ai_suggestion_id
 */
// const getColorByMaterialField = (materialFieldOption: string | undefined | null): string | null => {
//     if (materialFieldOption === undefined) return null
//
//     const existedMaterialColors = Object.values(DRAWABLE_COLORS_BY_MATERIAL_FIELD)
//     const foundMaterialName = existedMaterialColors.find(
//         (materialFieldName) => materialFieldOption?.toLocaleLowerCase() === materialFieldName.label
//     )
//
//     return foundMaterialName ? hexToRgb(DRAWABLE_COLORS_BY_MATERIAL_FIELD[foundMaterialName.keyName].color) : null
// }

export const drawableBackgroundAndBorderColor = (
    drawableType: string,
    drawableName: string,
    drawableSelection: string,
    drawableLocation: string,
    additional_data: IndexableObject | null,
    drawableMaterial?: string | null,
    ai_suggestion_id?: string
): string => {
    // const colorByMaterialField = getColorByMaterialField(drawableMaterial)

    // if (colorByMaterialField) return colorByMaterialField

    // set selected ai-suggestions red
    if (drawableName?.includes('temporary') && ai_suggestion_id) {
        return SELECTED_AI_SUGGESTION_COLOR
    }

    const existedTypesColors = Object.keys(DRAWABLE_COLORS_BY_TYPE)

    // opening location rules go first
    if (additional_data?.type && existedTypesColors.includes(additional_data?.type)) {
        return hexToRgb(DRAWABLE_COLORS_BY_TYPE[additional_data?.type].color)
    }
    // for post_wrap and rest card types
    if (existedTypesColors.includes(drawableType)) {
        return hexToRgb(DRAWABLE_COLORS_BY_TYPE[drawableType].color)
    }

    // for porch ceiling and rest cards where name exactly match
    // get all values from DRAWABLE_COLORS_BY_NAME and check if they exist in drawable settings name
    // e.g.: "pv1" match "Pv 1"
    const existedMatchNameColors = Object.values(DRAWABLE_COLORS_BY_NAME)
    const foundMatchName = existedMatchNameColors.find(
        (containText) => drawableName?.toLocaleLowerCase() === containText.label
    )

    if (foundMatchName) {
        return hexToRgb(DRAWABLE_COLORS_BY_NAME[foundMatchName.keyName].color)
    }

    // for porch ceiling and rest cards where name includes label
    // get all values from DRAWABLE_COLORS_BY_NAME and check if they exist in drawable settings name
    // e.g.: "porch ceiling" match "Front Porch Ceiling 4x8 Lp Panel"
    const existedContainNameColors = Object.values(DRAWABLE_COLORS_BY_NAME)
    const foundContainName = existedContainNameColors.find((containText) =>
        drawableName?.toLocaleLowerCase()?.includes(containText.label)
    )

    if (foundContainName) {
        return hexToRgb(DRAWABLE_COLORS_BY_NAME[foundContainName.keyName].color)
    }

    // for siding and rest cards with selection
    const existedSelectionColors = Object.values(DRAWABLE_COLORS_BY_SELECTION)
    const foundSelection = existedSelectionColors.find((selection) => drawableSelection === selection.label)
    // check by Selection should only apply when drawableType is "siding"

    if (DRAWABLE_TYPES.SIDING === drawableType && foundSelection) {
        return hexToRgb(DRAWABLE_COLORS_BY_SELECTION[foundSelection.keyName].color)
    }

    // for trim and rest cards with location which exactly match
    // e.g: "beam wrap" match "Beam Wrap"
    const existedMatchTextColors = Object.values(DRAWABLE_COLORS_BY_LOCATION)
    const foundMatchText = existedMatchTextColors.find(
        (containText) => drawableLocation?.toLocaleLowerCase() === containText.label
    )

    if (foundMatchText) {
        return hexToRgb(DRAWABLE_COLORS_BY_LOCATION[foundMatchText.keyName].color)
    }

    // for trim and rest cards with location where text contain location
    // e.g.: "top window" match "top" key
    const existedContainTextColors = Object.values(DRAWABLE_COLORS_BY_LOCATION)
    const foundContainText = existedContainTextColors.find((containText) =>
        drawableLocation?.toLocaleLowerCase()?.includes(containText.label)
    )

    if (foundContainText) {
        return hexToRgb(DRAWABLE_COLORS_BY_LOCATION[foundContainText.keyName].color)
    }

    // default color
    return DRAWABLE_TYPES_COLORS[drawableType]
}
