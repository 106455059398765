import { createAction } from '@reduxjs/toolkit'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Select, Workspace } from '../../lib/toolBoxes/2D'
import { resetNewCreatedDrawableByTool, selectDrawablesToCreateByTool, updateSelectedPaperId } from '../../slices/2D'
import { clearActiveForm } from '../../slices/forms'
import { resetSelectedToolObjectPathIds, setActiveToolObjectId } from '../../slices/tools'

export const closeToolFormAction = createAction('closeToolForm')

export function* handleCloseToolForm() {
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (!manager) return

    const [workspaceTool, selectTool]: [Workspace, Select] = yield call(manager.getTools, [Workspace.NAME, Select.NAME])

    const drawablesToCreateByToolIds = yield select(selectDrawablesToCreateByTool)

    // remove temporary tools
    yield all(
        drawablesToCreateByToolIds.map((itemId) =>
            call(workspaceTool.removeItemWithPaperId.bind(workspaceTool), itemId)
        )
    )

    yield all([
        put(resetSelectedToolObjectPathIds()),
        put(resetNewCreatedDrawableByTool()),
        put(clearActiveForm()),
        call(selectTool.exitSelectMode),
        put(setActiveToolObjectId(null)),
        put(updateSelectedPaperId(null)),
    ])
}

export function* watchForCloseToolForm() {
    yield takeEvery(closeToolFormAction.type, handleCloseToolForm)
}
