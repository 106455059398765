import { EAVE_GABLE_SOFFIT_TYPE_FOR_TG_WIDTH } from '../../../constants/form-schema/eave-gable-length'
import { otherValue } from '../../../constants/general-enums/value-other'

/**
 * Cleans up the `settings` object by nullifying or updating certain properties
 * based on specified conditions.
 *
 * @param {Object} settings - The settings object to be cleaned up.
 *
 * @returns {Object} - The cleaned-up settings object.
 */
export const eaveGableLengthFormSubmitCleanUp = (settings) => {
    if (!settings.robust_rafter_tail) {
        settings.rafter_tail_size = null
        settings.rafter_tail_length = null
        settings.rafter_tail_spacing = null
    }

    if (!settings.fascia_type) {
        settings.fascia_type = null
        settings.fascia_texture = null
        settings.fascia_width_1 = null
        settings.fascia_thickness_1 = null
    }

    if (!settings.fascia_type_2) {
        settings.fascia_type_2 = null
        settings.fascia_width_2 = null
        settings.fascia_thickness_2 = null
        settings.fascia_texture_2 = null
    }

    if (!settings.frieze_type) {
        settings.frieze_type = null
        settings.frieze_texture = null
        settings.frieze_width_1 = null
        settings.frieze_thickness_1 = null
    }

    if (!settings.frieze_type_2) {
        settings.frieze_type_2 = null
        settings.frieze_width_2 = null
        settings.frieze_thickness_2 = null
        settings.frieze_texture_2 = null
    }

    if (settings.width === otherValue) {
        settings.width = settings.width_other
    }

    settings.width_other = null

    settings.label = !settings.label && settings.label ? null : settings.label

    if (!EAVE_GABLE_SOFFIT_TYPE_FOR_TG_WIDTH.includes(settings?.soffit_type)) {
        settings.soffit_tg_width = null
    }

    return settings
}
