import '@babylonjs/core/Collisions/collisionCoordinator'
import '@babylonjs/core/Culling/ray'

import { Scene } from '@babylonjs/core/scene'
import has from 'lodash/has'
import isEqual from 'lodash/isEqual'
import isNull from 'lodash/isNull'
import { call, fork, put, select, takeLatest } from 'redux-saga/effects'

import { BabylonManager } from './../../lib/managers/BabylonManager'
import { calculate3DMeshIdsFromActiveDrawableGroup } from './calculate3DMeshIdsFromActiveDrawableGroup'
import { updateSelectedOpeningGroupAndHighlightRequiredMeshes } from './updateSelectedOpeningGroupAndHighlightRequiredMeshes'
import { toggleActiveDrawable } from '../../../actions/drawable'
import managers from '../../lib/managers'
import { Select } from '../../lib/toolBoxes/3D/tools'
import {
    selectedOpeningGroupIdSelector,
    selectMesh,
    State3D,
    updateShouldOpenMeshOpeningGroupMenu,
} from '../../slices/3D'
import { IMUPMappingsSelector } from '../../slices/common'
import { GeometryGroup } from '../../slices/geometry'
import { BabylonPredicateFn, ThreeDToTwoDRecord, TwoDSelectedItem } from '../../types'

export function selectionPredicateGenerator(id: number | null): BabylonPredicateFn {
    return (mesh) => {
        const meshGroupIds =
            mesh.metadata && mesh.metadata.ids.length ? mesh.metadata.ids.map((ids) => ids.groupId) : []

        return mesh.isEnabled() && mesh.isPickable && (id === null || meshGroupIds.includes(id))
    }
}

export function* resetMaterialForMeshesInScene() {
    const manager: BabylonManager | null = yield call(managers.get3DManager)

    if (isNull(manager)) return

    const scene: Scene = yield call(manager.getScene)

    yield scene.meshes.forEach((mesh) => {
        if (has(mesh, 'metadata.materialOptions.normalMaterial')) {
            mesh.material = mesh.metadata.materialOptions.normalMaterial
        }
    })
}

export function* selectAndHighlightRequiredMeshes(
    meshIds: string[],
    shouldUseMainHighlight: boolean,
    manager: BabylonManager
) {
    try {
        const selectTool: Select = yield call(manager.getTool, Select.NAME)
        const scene: Scene = yield call(manager.getScene)

        yield meshIds.forEach((meshId) => {
            const meshes = scene.getMeshesById(meshId)

            meshes.forEach((mesh) => {
                if (
                    mesh.metadata &&
                    mesh.metadata.materialOptions &&
                    mesh.metadata.materialOptions.highlightedMaterial &&
                    mesh.metadata.materialOptions.normalMaterial
                ) {
                    mesh.material = mesh.metadata.materialOptions.highlightedMaterial
                }
            })
        })
        yield call(selectTool.selectMeshIds, meshIds, shouldUseMainHighlight)
    } catch (e) {
        yield call(console.error, e)
    }
}

export function* calculateMeshIdsToHighlight(
    activeDrawableGroup: GeometryGroup,
    curSelectedMeshId: string,
    openingToMeshIdMappings: Record<string, string> | null
) {
    const allIds: string[] = yield call(
        calculate3DMeshIdsFromActiveDrawableGroup,
        activeDrawableGroup,
        openingToMeshIdMappings
    )

    return allIds.filter((id) => id !== curSelectedMeshId)
}

export function* toggleSceneAmbientColor(shouldDim: boolean) {
    const manager: BabylonManager | null = yield call(managers.get3DManager)

    if (isNull(manager)) return

    const scene: Scene = yield call(manager.getScene)

    const convertColor = shouldDim ? BabylonManager.SCENE_DIM_COLOR : BabylonManager.SCENE_DEFAULT_AMBIENT_COLOR

    if (!scene.ambientColor.equals(convertColor)) scene.ambientColor = convertColor
}

export function* handleSelectedMesh(action: ReturnType<typeof selectMesh>) {
    try {
        const threeDToTwoDRecord: ThreeDToTwoDRecord = yield select(IMUPMappingsSelector)

        yield call(toggleSceneAmbientColor, !isNull(action.payload.id))

        if (action.payload.id && threeDToTwoDRecord && threeDToTwoDRecord[action.payload.id]) {
            const activeOpeningGroupID: State3D['selectedOpeningGroupID'] = yield select(selectedOpeningGroupIdSelector)
            // If there is an opening group already selected then all we need to do is toggle
            // the currently selected drawable

            if (!isNull(activeOpeningGroupID)) {
                const singleRecord: TwoDSelectedItem | undefined = yield threeDToTwoDRecord[action.payload.id].find(
                    ({ groupId }) => isEqual(groupId, activeOpeningGroupID)
                )

                if (singleRecord) yield put(toggleActiveDrawable(singleRecord.openingId))
            } else {
                // If not then check if the mesh is only associated with one group
                // if it is then we can update the opening group and highlight the
                // sibling meshes; If this is not the case then we need to wait for users
                // to select the opening group they want to open before we can trigger
                // updating the selected active group id
                const idRecords = threeDToTwoDRecord[action.payload.id]

                if (idRecords.length === 1) {
                    yield fork(updateSelectedOpeningGroupAndHighlightRequiredMeshes, idRecords[0], action.payload.id)
                } else {
                    // Flag that the mesh menu needs to be opened
                    yield put(updateShouldOpenMeshOpeningGroupMenu(true))
                }
            }
        } else if (isNull(action.payload.id)) {
            yield call(resetMaterialForMeshesInScene)
        }
    } catch (error) {
        yield call(console.error, (error as any).message)
    }
}

export function* watchForSelectedMesh() {
    yield takeLatest(selectMesh.type, handleSelectedMesh)
}
