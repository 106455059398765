import isEqual from 'lodash/isEqual'
import { select, SelectEffect } from 'redux-saga/effects'

import { ActiveFloor } from '../../../models/activeFloor'
import { selectDrawableActiveFloor } from '../../slices/documents'
import { GeometryGroup } from '../../slices/geometry'
import { selectDrawableGroups } from '../2D/handleToggleDrawableGroups'

export function* getHiddenAndVisibleGroups(): Generator<
    SelectEffect | [number[], number[]],
    { hiddenDrawableGroupIds: number[]; visibleDrawableGroupIds: number[] },
    GeometryGroup[] & ActiveFloor & [number[], number[]]
> {
    const allDrawableGroups: GeometryGroup[] = yield select(selectDrawableGroups)
    const { document_chunk }: ActiveFloor = yield select(selectDrawableActiveFloor)

    const [hiddenDrawableGroupIds, visibleDrawableGroupIds]: [number[], number[]] = yield allDrawableGroups.reduce(
        (
            [hiddenDrawableGroupIds, visibleDrawableGroupIds]: [number[], number[]],
            drawableGroup: GeometryGroup
        ): [number[], number[]] =>
            isEqual(drawableGroup.isActive[document_chunk.id], false)
                ? [[...hiddenDrawableGroupIds, drawableGroup.id], visibleDrawableGroupIds]
                : [hiddenDrawableGroupIds, [...visibleDrawableGroupIds, drawableGroup.id]],
        [[], []]
    )

    return { hiddenDrawableGroupIds, visibleDrawableGroupIds }
}
