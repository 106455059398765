import { UiSchema } from '@rjsf/core'

import { calculateLFMeasurementOfMaterial } from '../imup/sagas/2D/createDrawableLocation'
import { FormProperties } from '../imup/types'
import { ActiveDrawable } from '../models/activeDrawable'
import { GeneralDrawableSettings } from '../models/activeDrawableSettings'
import { DRAWABLE_TYPES } from '../shared/constants/drawable-types'
import { handleSolidBeamCleanUp, solidBeamUIOrder, solidBeamUiSchema } from '../shared/constants/solid-beam-schema-data'

const other = 'other'

export const uiSchema: UiSchema = {
    'ui:order': [
        'location',
        'material',
        'glulam_type',
        ...solidBeamUIOrder,
        'dimension',
        'width',
        'width_other',
        'height',
        'height_other',
        'pitch_1',
        'pitch_other_1',
        'pitch_2',
        'pitch_other_2',
        'plies',
        'plies_other',
        'is_treated',
    ],
    location: {
        'ui:title': 'Location',
        'ui:placeholder': 'Select Location',
    },
    material: {
        'ui:title': 'Beam Material',
        'ui:placeholder': 'Select Beam Material',
    },
    glulam_type: {
        'ui:title': 'Glulam Type',
        'ui:placeholder': 'Select Glulam Type',
    },
    dimension: {
        'ui:title': 'Dimension',
        'ui:placeholder': 'Select Dimension',
    },
    width: {
        'ui:title': 'Width',
        'ui:placeholder': 'Select Width',
    },
    width_other: {
        'ui:title': 'Other Width',
        'ui:placeholder': 'Enter Width in inches',
    },
    height: {
        'ui:title': 'Height',
        'ui:placeholder': 'Select Height',
    },
    height_other: {
        'ui:title': 'Other Height',
        'ui:placeholder': 'Enter Height in inches',
    },
    pitch_1: {
        'ui:title': 'Pitch 1',
        'ui:placeholder': 'Select Pitch 1',
    },
    pitch_2: {
        'ui:title': 'Pitch 2',
        'ui:placeholder': 'Select Pitch 2',
    },
    plies: {
        'ui:title': 'Plies',
        'ui:placeholder': 'Select Plies',
    },
    plies_other: {
        'ui:title': 'Plies Other',
        'ui:placeholder': 'Select Other Plies',
    },
    is_treated: {
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
    ...solidBeamUiSchema,
}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }

    if (formData.pitch_1 === other) {
        newSettings.pitch_1 = formData.pitch_other_1
    }

    newSettings.pitch_other_1 = null

    if (formData.pitch_2 === other) {
        newSettings.pitch_2 = formData.pitch_other_2
    }

    newSettings.pitch_other_2 = null

    if (newSettings.height === other) {
        newSettings.height = newSettings.height_other
    }

    newSettings.height_other = null

    if (newSettings.width === other) {
        newSettings.width = newSettings.width_other
    }

    newSettings.width_other = null

    return handleSolidBeamCleanUp(formData, newSettings)
}

function prepareDrawables(input: {
    drawables: ActiveDrawable[]
    newSettings: GeneralDrawableSettings
}): ActiveDrawable[] {
    const { drawables, newSettings } = input

    return drawables.map((drawable) => {
        drawable.settings.quantity = calculateLFMeasurementOfMaterial(drawable.settings.linear_total, {
            type: DRAWABLE_TYPES.HIP_AND_VALLEY_BEAM,
            settings: newSettings,
        })

        return drawable
    })
}

const form: FormProperties = { uiSchema, onValidate, onSubmit, prepareDrawables }

export default form
