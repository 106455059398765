import { createAction } from '@reduxjs/toolkit'
import { call, select, takeEvery } from 'redux-saga/effects'

import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Overlay } from '../../lib/toolBoxes/2D'
import { selectCurrentOverlayChunkId } from '../../slices/2D'

export const toggleVisibilityOfOverlay = createAction('toggleVisibilityOfOverlay')

export function* handleToggleVisibilityOfOverlay() {
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (!manager) return

    const overlayTool: Overlay = yield call(manager.getTool, Overlay.NAME)

    const overlayChunkId: number | null = yield select(selectCurrentOverlayChunkId)

    if (!overlayChunkId) return

    yield call(overlayTool.toggleVisibilityOfOverlayById, overlayChunkId)
}

export function* watchForToggleVisibilityOfOverlay() {
    yield takeEvery(toggleVisibilityOfOverlay, handleToggleVisibilityOfOverlay)
}
