import { all, call, ForkEffect, select, StrictEffect, takeEvery } from 'redux-saga/effects'

import { updateDocumentMapping } from '../../../api/takeoff-api'
import { DocumentMapping } from '../../../models/documentMapping'
import { Project } from '../../../models/project'
import { moveChunksToBuilding, selectDocumentMappings } from '../../slices/documents'
import { selectProject } from '../2D/data-prep/prepareMarkupData'

export function* handleMoveChunksToBuilding(
    action: ReturnType<typeof moveChunksToBuilding>
): Generator<StrictEffect, void, Project & DocumentMapping[]> {
    try {
        const project = yield select(selectProject)
        const mappings = yield select(selectDocumentMappings)

        const mappingToUpdate = mappings.filter((mapping) =>
            action.payload.updatedDocumentChunks.find((dc) => dc.id === mapping.document_chunk_id)
        )

        yield all(mappingToUpdate.map((updatedMapping) => call(updateDocumentMapping, project.id, updatedMapping)))
    } catch (error) {
        yield call(console.error, (error as any).message)
    }
}

export function* watchForMoveChunksToBuilding(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(moveChunksToBuilding.type, handleMoveChunksToBuilding)
}
