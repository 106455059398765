import isNull from 'lodash/isNull'
import { all, call, select } from 'redux-saga/effects'

import { getHiddenAndVisibleGroups } from '../utils/getHiddenAndVisibleGroups'
import {
    ACTIVATE_ONLY_CLICKED_DRAWABLE_GROUP_BY_TYPE,
    activateOnlyClickedDrawableGroupByType,
    TOGGLE_DRAWABLE_GROUP,
    TOGGLE_DRAWABLE_GROUPS,
    TOGGLE_DRAWABLE_GROUPS_BY_TYPE,
    toggleDrawableGroup,
    toggleDrawableGroups,
    toggleDrawableGroupsByType,
} from '../../../actions/drawable'
import { OpeningGroup } from '../../../models/activeDrawable'
import { ActiveFloor } from '../../../models/activeFloor'
import managers from '../../lib/managers'
import { BabylonManager } from '../../lib/managers/BabylonManager'
import { Workspace } from '../../lib/toolBoxes/3D'
import { selectDrawableActiveFloor } from '../../slices/documents'

export function* hideDefaultHiddenDrawables(workspaceTool: Workspace) {
    const { hiddenDrawableGroupIds } = yield call(getHiddenAndVisibleGroups)

    yield call(workspaceTool.modifyNodeVisibility, hiddenDrawableGroupIds, false)
}

export function* handleToggleDrawableGroups3D(
    action:
        | ReturnType<typeof toggleDrawableGroup>
        | ReturnType<typeof toggleDrawableGroupsByType>
        | ReturnType<typeof toggleDrawableGroups>
        | ReturnType<typeof activateOnlyClickedDrawableGroupByType>
) {
    const manager: BabylonManager | null = yield call(managers.get3DManager)

    if (isNull(manager)) return

    const workspace: Workspace = yield call(manager.getTool, Workspace.NAME)

    const { document_chunk }: ActiveFloor = yield select(selectDrawableActiveFloor)

    switch (action.type) {
        case TOGGLE_DRAWABLE_GROUP:
            const typedActionGroup: ReturnType<typeof toggleDrawableGroup> = action as ReturnType<
                typeof toggleDrawableGroup
            >
            const ids: number[] = [typedActionGroup.payload.drawables.id]

            yield call(
                workspace.modifyNodeVisibility,
                ids,
                // Check explicitly equal to false, as undefined means that
                // the group is visible and needs to be hidden
                typedActionGroup.payload.drawables.isActive[document_chunk.id] === false
            )
            break
        case TOGGLE_DRAWABLE_GROUPS_BY_TYPE:
            const typedActionGroupByType: ReturnType<typeof toggleDrawableGroupsByType> = action as ReturnType<
                typeof toggleDrawableGroupsByType
            >
            const typeArray = typedActionGroupByType.payload.type as unknown as OpeningGroup[]
            const toggledIds = yield all(typeArray.map((group) => group.id))

            const { hiddenDrawableGroupIds, visibleDrawableGroupIds } = yield call(getHiddenAndVisibleGroups)
            const filteredVisible = yield visibleDrawableGroupIds.filter((id) => toggledIds.includes(id))
            const filteredHidden = yield hiddenDrawableGroupIds.filter((id) => toggledIds.includes(id))

            if (filteredHidden.length === toggledIds.length) {
                // We are toggling all to hide
                yield call(workspace.modifyNodeVisibility, filteredHidden, false)
            } else {
                // Filter the group ids to only trigger a visibility count
                // increase to group ids that are not visible
                const groupIdsToShow: number[] = yield call(workspace.filterOutVisibleNodeGroupIds, filteredVisible)
                // we are toggling all hidden  to visible

                yield call(workspace.modifyNodeVisibility, groupIdsToShow, true)
            }
            break
        case TOGGLE_DRAWABLE_GROUPS:
            const allResponses: { hiddenDrawableGroupIds: number[]; visibleDrawableGroupIds: number[] } = yield call(
                getHiddenAndVisibleGroups
            )

            if (allResponses.hiddenDrawableGroupIds.length === 0) {
                yield call(workspace.showAllGroups)
            } else {
                yield call(workspace.hideAllGroups)
            }

            break

        case ACTIVATE_ONLY_CLICKED_DRAWABLE_GROUP_BY_TYPE:
            /*
                When triggering hide all but one group
                hide all groups first then extract all
                group ids and increment the visibility
                of those groups
            */
            const typedActionActivateOnlyClicked: ReturnType<typeof activateOnlyClickedDrawableGroupByType> =
                action as ReturnType<typeof toggleDrawableGroupsByType>
            const groupsToShow = typedActionActivateOnlyClicked.payload.type as unknown as OpeningGroup[]
            const groupIdsToShow = yield all(groupsToShow.map((group) => group.id))

            yield call(workspace.hideAllGroups)
            yield call(workspace.modifyNodeVisibility, groupIdsToShow, true)

            break

        default:
            break
    }
}
