import { PayloadAction } from '@reduxjs/toolkit'
import { all, AllEffect, call, CallEffect } from 'redux-saga/effects'

import { removeAnimation } from './animateDrawableOnHover'
import { resetDrawableHighlightState, ResetDrawableHighlightStateType } from './resetDrawableHighlightState'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Workspace } from '../../lib/toolBoxes/2D'
import { ITool } from '../../types'

export function* handleEditingActive2D(
    action: PayloadAction<boolean>
): Generator<
    | CallEffect<PaperManager | null>
    | CallEffect<ITool>
    | CallEffect<paper.Item[]>
    | AllEffect<CallEffect<void | ResetDrawableHighlightStateType>>,
    void,
    PaperManager & Workspace & paper.PathItem[] & ITool
> {
    const paperManager = yield call(managers.get2DManager)

    if (!paperManager || !action.payload) return

    const workspaceTool: Workspace = yield call(paperManager.getTool, Workspace.NAME)

    const paths: paper.PathItem[] = yield call(workspaceTool.getAllDrawableItems)

    // Return if theres no path found
    if (!paths.length) return

    yield all(
        paths.flatMap((path) => {
            const yields: CallEffect<void | ResetDrawableHighlightStateType>[] = [call(removeAnimation, path)]

            if (path.strokeColor || path.fillColor) yields.push(call(resetDrawableHighlightState, path))
            if (path.strokeWidth) path.strokeWidth = path.data.originalStrokeWidth

            return yields
        })
    )
}
