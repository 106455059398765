import store from '../../../../stores'
import { updateRegionFromId } from '../../../sagas/2D/updateRegionFromId'
import { setAltSelectedItems, updateSelectedPaperId } from '../../../slices/2D'
import { resetRegionFormState, setActiveRegion } from '../../../slices/region'
import { IMUP2DCoordinatesToUpdate } from '../../../types'

function save2DRegion(): (coordinatesToUpdate: IMUP2DCoordinatesToUpdate) => void {
    return function saveRegion(this: paper.Path, coordinatesToUpdate) {
        store.dispatch(updateRegionFromId({ coordinatesToUpdate, regionId: this.data.region_id }))
    }
}

function select2DRegion(): () => void {
    return function selectRegion(this: paper.Path) {
        store.dispatch(setActiveRegion(this.data.region_id))
        store.dispatch(updateSelectedPaperId(this.id))
    }
}

function deselect2DRegion(): () => void {
    return function deselectRegion(this: paper.Path) {
        store.dispatch(resetRegionFormState())
        store.dispatch(updateSelectedPaperId(null))

        // reset multiple selected items
        store.dispatch(setAltSelectedItems(null))
    }
}

export default function addSelectFunctionalityToRegion(regionPath: paper.PathItem): void {
    regionPath.data.onSave = save2DRegion().bind(regionPath)
    regionPath.data.onSelect = select2DRegion().bind(regionPath)
    regionPath.data.onDeselect = deselect2DRegion().bind(regionPath)
}
