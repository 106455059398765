import { PayloadAction } from '@reduxjs/toolkit'
import { call, fork, select, StrictEffect, takeLatest } from 'redux-saga/effects'

import { TOGGLE_DRAWABLE_ON_HOVER } from '../../../actions/drawable'
import { selectActiveMode } from '../../slices/common'
import { VIEW_MODE } from '../../types'
import { handleToggleDrawableOnHover2D } from '../2D/animateDrawableOnHover'
import { handleToggleDrawableOnHover3D } from '../3D/animateDrawableOnHover'

export function* handleToggleDrawableOnHover(
    action: PayloadAction<{ drawable_id: number }>
): Generator<StrictEffect, void, VIEW_MODE> {
    try {
        const activeMode: VIEW_MODE = yield select(selectActiveMode)

        switch (activeMode) {
            case VIEW_MODE.Markup2D:
                yield fork(handleToggleDrawableOnHover2D, action)
                break
            case VIEW_MODE.Markup3D:
                yield fork(handleToggleDrawableOnHover3D, action)
                break
            default:
                break
        }
    } catch (error) {
        yield call(console.error, (error as any).message)
    }
}

export function* watchForToggleDrawableOnHover() {
    yield takeLatest(TOGGLE_DRAWABLE_ON_HOVER, handleToggleDrawableOnHover)
}
