import isNull from 'lodash/isNull'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { selectScaleFactor } from './createOpeningLocation'
import drawToolByType from './drawToolByType'
import { createToolObject } from '../../../api/projects-api'
import { convertAbsoluteDistanceToFormattedString } from '../../../components/markup/utils/helpers'
import { Coordinate } from '../../../models/activeDrawable'
import { ActiveFloor } from '../../../models/activeFloor'
import { IToolObject } from '../../../models/tool'
import { applyScaleFactorToPathLength } from '../../../utils/calculations/scaleConversion/scaleConversion'
import { defineLocationRegion } from '../../../utils/coordinates/defineLocationRegion'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Color, Label, PathTool, Workspace } from '../../lib/toolBoxes/2D'
import { selectDrawableActiveFloor } from '../../slices/documents'
import { selectProjectId } from '../../slices/project'
import { addNewToolObjects, selectLineOpacity, setMeasurementIdToCreate } from '../../slices/tools'
import { TOOL_TYPE_ENUMS } from '../../types'

export function* getMeasurementLabel(path: paper.Path) {
    const activeFloor: ActiveFloor = yield select(selectDrawableActiveFloor)
    const scaleFactor: number = yield select(selectScaleFactor)

    const dpi: number | null = activeFloor.document_chunk.dpi || null
    const xCalibrationFactor: number = activeFloor.document_chunk.calibration_factor_x || 1
    const yCalibrationFactor: number = activeFloor.document_chunk.calibration_factor_y || 1
    const pdfScale: number = activeFloor.document_chunk.pdf_scale || 1

    return convertAbsoluteDistanceToFormattedString(
        applyScaleFactorToPathLength({
            pxValue: path.length,
            scaleFactor,
            dpi,
            xCalibrationFactor,
            yCalibrationFactor,
            coordinates: path.segments.map((segment) => [segment.point.x, segment.point.y]),
            pdfScale,
        })
    )
}

export function* handleCreateMeasurements(action: ReturnType<typeof setMeasurementIdToCreate>) {
    try {
        if (isNull(action.payload)) return

        const manager: PaperManager | null = yield call(managers.get2DManager)

        if (!manager) return

        const [colorTool, pathTool, workspaceTool, labelTool] = yield call(manager.getTools, [
            Color.NAME,
            PathTool.NAME,
            Workspace.NAME,
            Label.NAME,
        ])

        const lineOpacity: number = yield select(selectLineOpacity)

        const items: paper.Item[] = yield call(
            workspaceTool.getItemsWithCriteria,
            'id',
            (id: number) => id === action.payload
        )

        const path = items[0] as paper.Path

        if (!path) return

        const projectId: number = yield select(selectProjectId)

        const activeFloor: ActiveFloor = yield select(selectDrawableActiveFloor)

        const coordinates: Coordinate[] = path.segments.map((segment) => [segment.point.x, segment.point.y])

        const { id: documentChunkId, regions } = activeFloor.document_chunk

        const regionId = yield call(defineLocationRegion, path, regions, workspaceTool)

        const measurementLabel = yield call(getMeasurementLabel, path)

        const data = {
            region_id: regionId,
            document_chunk_id: documentChunkId,
            type: TOOL_TYPE_ENUMS.MEASUREMENT,
            coordinates,
            settings: {
                label: measurementLabel,
            },
            color: path.strokeColor ? path.strokeColor.toString() : '',
        }

        const measurementData: IToolObject = yield call(createToolObject, projectId, documentChunkId, data)

        yield put(addNewToolObjects([measurementData]))

        yield call(drawToolByType, measurementData, colorTool, pathTool, workspaceTool, lineOpacity, labelTool)
    } catch (e) {
        console.error('Error on creating measurement: ', e)
    }
}

export function* watchForHandleCreateMeasurements() {
    yield takeEvery(setMeasurementIdToCreate.type, handleCreateMeasurements)
}
