import { call, put } from 'redux-saga/effects'

import { setActiveDrawableGroupFromID } from '../../../actions/drawable'
import managers from '../../lib/managers'
import { Workspace } from '../../lib/toolBoxes/2D'
import { selectedPaperItem } from '../../slices/2D'
import { setCutoutItem } from '../../slices/tools'

export function* updateSelectedPaperAndCutoutItemsFromSelectedIDs(
    action: ReturnType<typeof setActiveDrawableGroupFromID>
) {
    let selectedItemId = action.payload.selectedItemId

    if (!selectedItemId && action.payload.activeOpeningLocationId) {
        const paperManager = yield call(managers.get2DManager)
        const workspaceTool: Workspace = yield call(paperManager.getTool, Workspace.NAME)
        const item = yield call(workspaceTool.getItemWithOpeningLocationId, action.payload.activeOpeningLocationId)

        selectedItemId = item?.id
    }

    yield put(
        selectedPaperItem({
            selectedItem: selectedItemId,
            activeDrawableId: action.payload.activeDrawableId,
            openingGroupId: action.payload.openingGroupId,
            activeOpeningLocationId: action.payload.activeOpeningLocationId,
        })
    )
    yield put(setCutoutItem(selectedItemId))
}
