import { distinctUntilChanged, map, takeWhile } from 'rxjs'

import Base from '../paperTool/PaperTool'
import { State2D } from '../../../../../slices/2D'
import { initialToolsState, ToolsState } from '../../../../../slices/tools'
import { IMUPState, PaperToolConfig, VIEW_MODE } from '../../../../../types'

/**
 * Color.tool.tsx
 * Updates color state in IMUP 2D
 * Designed to be used as handler for a color picker and directly pass color to store
 */
// TODO: Implement tool further when editing functionality is created
export class Color extends Base {
    static NAME = 'COLOR'

    private selectedItem: State2D['selectedItem'] = null
    private color: ToolsState['color'] = initialToolsState.color

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = Color.NAME
        this.mediator
            .get$()
            .pipe(
                takeWhile((state: IMUPState) => state.common.activeMode === VIEW_MODE.Markup2D),
                map((state: IMUPState) => ({ selectedItem: state['2D'].selectedItem, color: state.tools.color })),
                distinctUntilChanged()
            )
            .subscribe(({ selectedItem, color }) => {
                this.selectedItem = selectedItem
                this.color = color
            })
    }

    protected handleChanges = (): void => {
        if (this.selectedItem !== null) {
            const item = this.paper.project.getItem({ id: this.selectedItem })

            item.fillColor = new this.paper.Color(this.color)
        }
    }

    createColor = (fromString: string): paper.Color => new this.paper.Color(fromString)
}

export default Color
