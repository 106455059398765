import { put, select, takeEvery } from 'redux-saga/effects'

import { Project } from '../../../models/project'
import { PROJECT_PAGE_TAB_VALUES } from '../../../shared/constants/project-settings-names'
import { availableMode, enableIMUP } from '../../slices/common'
import { setActiveTabOnProjectPage } from '../../slices/navigation'
import { VIEW_MODE } from '../../types'
import { selectProject } from '../2D/data-prep/prepareMarkupData'

export function* handleTabChange(action: ReturnType<typeof setActiveTabOnProjectPage>) {
    const project: Project | null = yield select(selectProject)

    if (!project) return

    if (project.is3D) {
        if (action.payload === PROJECT_PAGE_TAB_VALUES.HARDWARE) {
            yield put(availableMode(VIEW_MODE.Markup2DFor3D))
            yield put(enableIMUP(VIEW_MODE.Markup2DFor3D))
        } else if (action.payload === PROJECT_PAGE_TAB_VALUES.DIGITIZER) {
            yield put(availableMode(VIEW_MODE.Markup3D))
            yield put(enableIMUP(VIEW_MODE.Markup3D))
        }
    }
}

export function* watchForActiveTabChange() {
    yield takeEvery(setActiveTabOnProjectPage, handleTabChange)
}
