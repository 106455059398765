import { PointerEventTypes, PointerInfo } from '@babylonjs/core/Events/pointerEvents'
import { AbstractMesh } from '@babylonjs/core/Meshes/abstractMesh'
import { Mesh } from '@babylonjs/core/Meshes/mesh'
import { Node } from '@babylonjs/core/node'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'

import BabylonTool from '../babylonTool/BabylonTool'
import { BabylonToolConfig, MouseButtonCodes, TwoDSelectedItem } from '../../../../../types'

export class Workspace extends BabylonTool {
    static NAME = 'WORKSPACE'
    private static SELECTABLE_NODE_NAME = 'selectable'
    private static UNSELECTABLE_NODE_NAME = 'unselectable'
    private static VISIBILITY_COUNT_ATTRIBUTE_NAME = 'visCount'
    private static VISIBILITY_COUNT_MAX_ATTRIBUTE = 'visCountMax'
    private static NODE_NAME_SEPARATOR = '-'

    private nodeCache: Record<string, Node> = {}
    private floorHashes: string[] = []

    constructor(config: BabylonToolConfig) {
        super(config)
        this.name = Workspace.NAME
        this.registerActivationFunctions([this.onPointerChange])
    }

    /**
     * handle pointer events
     * @param eventData  the babylon js selection pointer event
     */
    private handlePointerEvent = (eventData: PointerInfo): void => {
        if (eventData.type === PointerEventTypes.POINTERDOWN && eventData.event.button === MouseButtonCodes.Left) {
            // Update the current mouse position on pointer down
            this.mediator.mediate('3D', { mousePosition: { x: eventData.event.pageX, y: eventData.event.pageY } })
        }
    }

    private onPointerChange = (): (() => void) => {
        this.scene.onPointerObservable.add(this.handlePointerEvent)

        return () => {}
    }

    /**
     * Create a new node to house meshes and set the correct metadata on it
     * @param name the new node name
     * @param initialVisValue the initial visibility semaphore value
     * @returns the nde node
     */
    private createNewNode = (name: string, initialVisValue: number): Node => {
        const newNode = new Node(name, this.scene)

        newNode.metadata = {
            [Workspace.VISIBILITY_COUNT_ATTRIBUTE_NAME]: initialVisValue,
            [Workspace.VISIBILITY_COUNT_MAX_ATTRIBUTE]: initialVisValue,
        }

        return newNode
    }

    /**
     * Calculate the number of unique groups that the mesh is a part of
     * @param ids the ids metadata saved on the mesh
     * @returns the number of unique groups
     */
    private calculateUniqueGroupIds = (ids: TwoDSelectedItem[]): number[] => [
        ...new Set(ids.map(({ groupId }) => groupId)),
    ]

    /**
     * Toggles the visibility of all the nodes in the scene except the unselectable and selectable nodes
     * @param shouldShow whether or not the nodes should be shown
     */
    private toggleNodesVisibility = (shouldShow: boolean): void =>
        Object.values(this.nodeCache).forEach((node) => {
            if (![Workspace.SELECTABLE_NODE_NAME, Workspace.UNSELECTABLE_NODE_NAME].includes(node.name)) {
                node.metadata[Workspace.VISIBILITY_COUNT_ATTRIBUTE_NAME] = shouldShow
                    ? node.metadata[Workspace.VISIBILITY_COUNT_MAX_ATTRIBUTE]
                    : 0
                if (node.isEnabled() !== shouldShow) {
                    node.setEnabled(shouldShow)
                }
            }
        })

    private calculateCompositeGroupIdForMesh = (mesh: Mesh, uniqueGroupIds: number[]): string =>
        mesh.metadata.ids.length === 1
            ? mesh.metadata.ids[0].groupId.toString()
            : uniqueGroupIds.join(Workspace.NODE_NAME_SEPARATOR)

    private shouldNodeChangeEnabledState = (shouldIncrement: boolean, mesh: Node): boolean =>
        (!shouldIncrement && mesh.metadata[Workspace.VISIBILITY_COUNT_ATTRIBUTE_NAME] === 0) ||
        (shouldIncrement && mesh.metadata[Workspace.VISIBILITY_COUNT_ATTRIBUTE_NAME] > 0)

    /**
     * Calculate which node to add the mesh to
     * Current tree structure of the scene
     *  Root node
     *   ├── selectable meshes
     *   │   └── group_id_1
     *   │       └── Mesh1 in group
     *   │   └── group_id_2
     *   │       └── Mesh3 in group
     *   └── unselectable meshes
     *
     * Creates and associates the node with the
     * correct parent and adds the mesh to the
     * correct parent node
     *
     * @param mesh mesh to be added to the scene
     * @param root the root mesh of the scene
     */
    public addMeshToCorrectNode = (mesh: Mesh, root: Mesh): void => {
        const nodeName = mesh.metadata.isReflectedInTwoD
            ? Workspace.SELECTABLE_NODE_NAME
            : Workspace.UNSELECTABLE_NODE_NAME

        let node: Node | undefined = this.nodeCache[nodeName]

        // Determine if the selectable/unselectable
        // nodes are created, if not create them
        if (!node) {
            const newNode = this.createNewNode(nodeName, 1)

            newNode.parent = root
            this.nodeCache[nodeName] = newNode
            node = newNode
        }

        // In the case that it is a selectable mesh
        // then determine if the group node is
        // created if not create it and set that
        // group node as the mesh's parent
        if (nodeName === Workspace.SELECTABLE_NODE_NAME) {
            const uniqueGroupIds = this.calculateUniqueGroupIds(mesh.metadata.ids)
            const groupId: string = this.calculateCompositeGroupIdForMesh(mesh, uniqueGroupIds)
            const groupNode: Node | undefined = this.nodeCache[groupId]

            if (!groupNode) {
                const newGroupNode = this.createNewNode(groupId, uniqueGroupIds.length)

                newGroupNode.parent = node
                this.nodeCache[groupId] = newGroupNode
                node = newGroupNode
            } else {
                node = groupNode
            }
        }
        if (mesh.metadata.storeyName && !this.floorHashes.includes(mesh.metadata.storeyName)) {
            this.floorHashes.push(mesh.metadata.storeyName)
        }

        mesh.parent = node
    }

    public getMeshStoryName = (wallId: string): string | null => {
        const meshes = this.scene.getMeshesByID(wallId)

        return meshes.length > 0 && meshes[0] ? meshes[0].metadata.storeyName : null
    }

    /**
     * Toggle floor(s) (shows only given floor/s)
     * @param floorHashes the floor(s) that should be displayed
     */
    public toggleVisibleFloor = (floorHashes: string[] = []): void => {
        // If no floors are selected, reset to showing all
        if (isEmpty(floorHashes)) {
            this.scene.meshes.forEach((mesh) => {
                mesh.setEnabled(true)
            })

            return
        }

        // Only show the meshes that are part of the floor selected
        this.scene.meshes.forEach((mesh) => {
            // if it is the root mesh then continue
            if (!isNull(mesh.parent)) {
                const isMeshEnabled = floorHashes.includes(mesh.metadata?.storeyName)

                mesh.setEnabled(isMeshEnabled)
            }
        })
    }

    /**
     * Get all the floor hashes associated with meshes
     * @returns array of floor hashes
     */
    public getFloorHashes = (): string[] => this.floorHashes

    /**
     * Modify the visibility semaphore on nodes that are associated with a list of
     * groups ids
     * @param ids the group ids to modify
     * @param shouldIncrement whether or not to increment the visibility semaphore
     */
    public modifyNodeVisibility = (ids: number[], shouldIncrement: boolean): void => {
        Object.keys(this.nodeCache).forEach((nodeId) => {
            if (ids.some((id) => nodeId.includes(`${id}`))) {
                // Extract the group ids that this node belongs to
                // this will be used to determine the decrement/increment
                // count based on how many of the groups of the nodes
                // group ids are being toggled
                const count = nodeId
                    .split(Workspace.NODE_NAME_SEPARATOR)
                    .filter((id) => ids.includes(parseInt(id))).length
                const includedNode = this.nodeCache[nodeId]

                if (shouldIncrement) {
                    const newCount: number = Math.min(
                        includedNode.metadata[Workspace.VISIBILITY_COUNT_ATTRIBUTE_NAME] + count,
                        includedNode.metadata[Workspace.VISIBILITY_COUNT_MAX_ATTRIBUTE]
                    )

                    includedNode.metadata[Workspace.VISIBILITY_COUNT_ATTRIBUTE_NAME] = newCount
                } else {
                    const newCount: number = Math.max(
                        includedNode.metadata[Workspace.VISIBILITY_COUNT_ATTRIBUTE_NAME] - count,
                        0
                    )

                    includedNode.metadata[Workspace.VISIBILITY_COUNT_ATTRIBUTE_NAME] = newCount
                }

                if (this.shouldNodeChangeEnabledState(shouldIncrement, includedNode)) {
                    includedNode.setEnabled(shouldIncrement)
                }
            }
        })
    }

    /**
     * Filter an array of group ids to only those who's nodes are not visible
     * @param  groupIds an array of group ids
     * @returns  filtered array of group ids who are only connected to invisible nodes
     */
    public filterOutVisibleNodeGroupIds = (groupIds: number[]): number[] => {
        return groupIds.filter((id) => {
            const res = Object.keys(this.nodeCache).reduce((prev, current) => {
                if (current.includes(`${id}`)) {
                    prev.push(this.nodeCache[current].metadata[Workspace.VISIBILITY_COUNT_ATTRIBUTE_NAME] === 0)
                }

                return prev
            }, [] as boolean[])

            return res.every((val) => val)
        })
    }

    /**
     * Delete meshes in the current scene with an optional predicate function added
     * @param {(mesh: AbstractMesh) => boolean | undefined} predicateFunction to determine whether or not to delete the mesh
     * if not passed in then all meshes are deleted
     * @returns {string[]} ids of the deleted meshes
     */
    public deleteMeshesInCurrentScene = (predicateFunction?: (mesh: AbstractMesh) => boolean): string[] =>
        this.scene.rootNodes.flatMap((root) =>
            root.getChildMeshes().reduce((deletedIds: string[], mesh: AbstractMesh): string[] => {
                if (isUndefined(predicateFunction) || predicateFunction(mesh)) {
                    deletedIds.push(mesh.id)
                    mesh.dispose(true, false)
                }

                return deletedIds
            }, [])
        )

    /**
     * Clear all meshes in the scene
     */
    public clearScene = (): void => {
        this.scene.rootNodes.forEach((node) => {
            node.getDescendants().forEach((descendant) => {
                descendant.dispose(true, false)
            })
        })
        this.nodeCache = {}
    }

    /**
     * Hide all meshes associated with all
     * drawable groups
     * @returns
     */
    public hideAllGroups = (): void => this.toggleNodesVisibility(false)

    /**
     * Show all meshes associated with
     * all drawable groups
     * @returns
     */
    public showAllGroups = (): void => this.toggleNodesVisibility(true)

    /**
     * Hide meshes based on provided predicate function
     */
    public toggleVisibilityBasedOnPredicate = (visibilityPredicate: (mesh: AbstractMesh) => boolean) => {
        this.scene.meshes.forEach((mesh) => {
            if (
                ![Workspace.SELECTABLE_NODE_NAME, Workspace.UNSELECTABLE_NODE_NAME].includes(mesh.name) &&
                !this.nodeCache[mesh.name] &&
                !isNull(mesh.parent)
            ) {
                mesh.setEnabled(visibilityPredicate(mesh))
            }
        })
    }
}

export default Workspace
