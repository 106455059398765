import { PathTool } from '../path/Path.tool'
import { PaperToolConfig } from '../../../../../types'

/**
 * Arrow tool is used to indicate custom angle of Joist Line directions
 */
export class Arrow extends PathTool {
    static NAME = 'ANGLED JOISTS'

    protected path: paper.Path | null = null
    private points: paper.Group[] = []

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = Arrow.NAME
    }

    cancel = () => {
        this.path?.remove()
        this.points.forEach((point: paper.Group) => {
            point.remove()
        })

        this.setState('2D', { joistDirectionOtherCoordinates: null })

        // reset local vars
        this.path = null
        this.points = []
    }

    public onMouseDown = (event: paper.ToolEvent): void => {
        if (this.isPanningClick(event)) return

        // if there are already 2 points, clear the path and remove direction points
        if (this.path?.segments.length === 2) {
            this.cancel()

            return
        }

        // create a new segment if segment doesn't exist
        if (!this.path) {
            this.path = new this.paper.Path()
            this.path.strokeColor = new this.paper.Color(this.measureStrokeColor)
            this.path.strokeWidth = this.measureStrokeWidth
            this.path.strokeScaling = false // maintain the same stroke width regardless of zoom; see: http://paperjs.org/reference/style/#strokescaling
            this.path.locked = true
            this.path.data.toolName = this.name
        }

        // TODO: make line as arrow
        const crossHair = this.constructCrosshairMarker(event.point)

        this.points.push(crossHair)
        crossHair.data.pathSegment = this.path.add(crossHair.position)

        if (this.path?.segments.length === 2) {
            const directionOther = `[(${this.path.segments[0].point.x.toFixed(
                3
            )},${this.path.segments[0].point.y.toFixed(3)}),(${this.path.segments[1].point.x.toFixed(
                3
            )},${this.path.segments[1].point.y.toFixed(3)})]`

            this.mediator.mediate('2D', {
                joistDirectionOtherCoordinates: directionOther,
            })
        }
    }

    /**
     * On mouse up change the cursor back to the default
     * of the tool
     */
    onMouseUp = () => {
        this.setState('common', { cursor: this.cursor, tooltip: { title: '', visible: false, color: '#000000' } })
    }

    /**
     * On mouse drag if it is right click
     * or middle click then pan
     * @param event the paper mouse event
     * @returns
     */
    onMouseDrag = (event) => {
        this.toolPanning(event)
    }
}
