import { Vector3 } from '@babylonjs/core/Maths/math.vector'
import { Mesh } from '@babylonjs/core/Meshes/mesh'
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder'

import { BabylonTool } from '../babylonTool/BabylonTool'
import { BabylonToolConfig, IVector } from '../../../../../types'

export class Line extends BabylonTool {
    public static NAME = 'LINE'
    private static readonly EDGE_SIDE_ORIENTATION = Mesh.DOUBLESIDE

    constructor(config: BabylonToolConfig) {
        super(config)
        this.name = Line.NAME
        this.registerActivationFunctions([])
    }

    /**
     * Create a tube from a 3D set of points on a line
     * @param {IVector[]} path an array of vectors that make up the path of the tube
     * @param {Partial<Mesh>} meshOptions Options to attach to the new resultant mesh
     * @returns {Mesh | null} returns null if mesh cannot be created
     */
    public create = (path: IVector[], radius: number, meshOptions?: Partial<Mesh>): Mesh => {
        const mesh = MeshBuilder.CreateTube(
            'line-tube',
            {
                path: path.map(({ x, y, z }) => new Vector3(x, y, z)),
                radius,
                sideOrientation: Line.EDGE_SIDE_ORIENTATION,
                updatable: Line.DO_NOT_FLAG_VERTEX_DATA_AS_UPDATABLE,
            },
            this.scene
        )

        meshOptions && Object.assign(mesh, meshOptions)

        mesh.freezeWorldMatrix()
        mesh.doNotSyncBoundingInfo = true

        return mesh
    }
}
